import React, { useRef } from 'react';
import styled from 'styled-components';
import FormItemWrapper from '../form-item-wrapper';
import { critical, secondary, textDefault } from 'theme';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faImage } from '@fortawesome/free-solid-svg-icons'
import { Typography } from '../typography';

const FileUpload = styled.div`
  display: flex;
  background-color: ${secondary};
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 10px;
`

const Text = styled(Typography)`
  width: 50%;
  text-align: center;
`

const FileInput = styled.input`
  display: none;
`

const Image = styled.img`
  width:100%;
  object-fit: scale-down;
  max-height: 240px;
`

const getBase64 = file => {
  return new Promise(resolve => {
    let baseURL = "";
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      baseURL = reader.result;
      resolve(baseURL);
    };
  });
};

export const FormFileUpload = ({ label, error, required, disabled, onChange, value }) => {
  const ref = useRef()

  const handleFileChange = async (e) => {
    if (!e.target.files) {
      return;
    }
    const file = await getBase64(e.target.files[0])
    onChange(file, e.target.files[0]);
  };

  const onClick = () => {
    ref.current.click();
  }

  return (
    <FormItemWrapper color={textDefault} required={required} disabled={disabled} label={label}>
      <FileInput type="file" ref={ref} onChange={handleFileChange} onClick={() => ref.current.value = null} />
      <FileUpload onClick={onClick}>
        {
          value ? <Image src={value} /> :
            <>
              <FontAwesomeIcon icon={faImage} size="2x" />
              <Text>Upload a photograph that showcases your service here.</Text>
            </>
        }
      </FileUpload>
      {!!error && <Typography color={critical}>{error}</Typography>}
    </FormItemWrapper>
  );
}