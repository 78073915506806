import React from "react"

export const globalOptions = {
  api: "https://wedstimate-23947.herokuapp.com/api/v1",
  // api: 'https://rough-smoke-23947.botics.co/api/v1',
  // brainTreeURL: `https://wedstimate-23947.herokuapp.com/braintree/checkout/`,
  brainTreeURL: `https://rough-smoke-23947.botics.co/braintree/`,
  tracking_id: 'UA-253518972-1'

}

export const GlobalOptionsContext = React.createContext(globalOptions)
