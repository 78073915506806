import {
  Button,
  FormFileUpload,
  FormInputText,
  FormItemWrapper,
  FormSelect,
  Typography,
} from 'components';
import React, { useEffect } from 'react';
import {
  breakpoints,
  containerWidth,
  primary,
  secondary,
  textDisabled,
} from 'theme';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router';
import {
  BUSINESSES,
  PRICING,
  USER_CONSIDERATIONS,
  USER_SERVICES,
  USER_STYLES,
  VENDORS_CATEGORIES,
  VENUE_AMENITIES,
} from 'utils';
import { Formik } from 'formik';
import * as Yup from 'yup';
import {
  addVendor,
  getVendorDashboard,
  unsubscription,
  updateVendor,
} from 'store/vendor';
import { Rating } from '@mui/material';

const Content = styled.div`
  display: flex;
  margin: 0 auto;
  max-width: ${containerWidth}px;
  margin-top: 130px;
  padding-bottom: 50px;
  gap: 20px;
  width: 100%;

  @media ${breakpoints.viewport_l_and_down} {
    max-width: 100%;
    padding: 0 40px;
  }

  @media ${breakpoints.viewport_xs_and_down} {
    margin-top: 160px;
  }
`;

const Banner = styled.div`
  position: absolute;
  left: 0;
  width: 100%;
  background-color: ${secondary};
  padding: 20px 0;
`;

const BannerContent = styled.div`
  max-width: ${containerWidth}px;
  margin: auto;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  display: flex;

  @media ${breakpoints.viewport_l_and_down} {
    max-width: 100%;
    padding: 0 40px;
  }
`;

const Form = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 20px;
`;

const SpacedButton = styled(Button)`
  display: flex;
`;

const FormGroup = styled.div`
  /* flex-direction: row;
  display: flex; */
  display: grid;
  grid-template-columns: 50% 50%;
  gap: 20px;

  @media ${breakpoints.viewport_s_and_down} {
    grid-template-columns: 100%;
  }
`;

const FormColumn = styled.div`
  flex-direction: column;
  display: flex;
  gap: 20px;
  width: 100%;
`;

const CancelSub = styled.div`
  margin-top: 50px;
  p {
    margin-bottom: 20px;
  }
`;

const validationSchema = Yup.object().shape({
  title: Yup.string()
    .label('Name')
    .required(),
  description: Yup.string()
    .label('Description')
    .required(),
  // consideration: Yup.array().label("Consideration").min(1).required(),
  services: Yup.array()
    .label('Services')
    .min(1)
    .required(),
  image: Yup.string()
    .label('Image')
    .required(),
  is_your_business: Yup.array()
    .label('Support Diversity')
    .min(1)
    .required(),
  // styles: Yup.array().label("Styles").min(1).required()
});

export const Preferences = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation()
  const { user } = useSelector(state => state.auth);
  const {
    addVendorApi,
    identificationResponse,
    unsubscriptionApi,
  } = useSelector(state => state.vendor);

  const preferenceType = VENDORS_CATEGORIES.find(v => v.enum == params.id);

  useEffect(() => {
    if (!addVendorApi.loading && addVendorApi.success) {
      navigate('/vendor/dashboard');
    }
  }, [addVendorApi.loading, addVendorApi.success]);

  useEffect(() => {
    if (user.vendor_identification) {
      dispatch(getVendorDashboard(user.vendor_identification));
    }
  }, [user]);

  return (
    <div style={{ width: '100%' }}>
      <Formik
        enableReinitialize={true}
        initialValues={
          identificationResponse ?? {
            title: '',
            consideration: [],
            pricing: '',
            services: [],
            styles: [],
            website_url: '',
            budget: 0,
            image: '',
            is_your_business: [],
            description: '',
            ameneties: [],
            ranking: {},
          }
        }
        validationSchema={validationSchema}
        onSubmit={data => {
          let values = { ...data };
          values.budget = Number.parseFloat(values.budget);
          if (identificationResponse?.id) {
            const { image, ...rest } = values;
            let updatedValues = values;
            if (image.includes('https://')) {
              updatedValues = rest;
            }
            dispatch(updateVendor(updatedValues));
          } else {
            dispatch(
              addVendor({
                ...values,
                category: Number.parseInt(params.id),
                vendor: user?.id,
                payment: {
                  ...location.state?.payment,
                }
              }),
            );
          }
        }}>
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
        }) => (
          <form onSubmit={handleSubmit} style={{ display: 'flex' }}>
            <Banner>
              <BannerContent>
                <div>
                  <Typography variant="h3" color={primary}>
                    Add {preferenceType?.name} description
                  </Typography>
                  <Typography variant="footNote">
                    Describe your unique service
                  </Typography>
                </div>
                <SpacedButton type="submit" pending={addVendorApi.loading}>
                  Submit
                </SpacedButton>
              </BannerContent>
            </Banner>
            <Content>
              <Form>
                <FormGroup>
                  <FormColumn>
                    <FormInputText
                      label="Company Name"
                      type="text"
                      name="title"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.title}
                      error={touched.title && errors.title}
                    />
                    <FormInputText
                      label="Description"
                      placeholder="Type your description here"
                      type="text"
                      name="description"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.description}
                      error={touched.description && errors.description}
                      multiline
                      rows={4}
                    />
                  </FormColumn>
                  <FormFileUpload
                    label="Upload an image"
                    name="image"
                    onChange={value => setFieldValue('image', value)}
                    onBlur={handleBlur}
                    value={values.image}
                    error={touched.image && errors.image}
                  />
                </FormGroup>
                <FormGroup>
                  <FormInputText
                    label="Please enter your most popular price"
                    type="text"
                    name="budget"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.budget}
                    error={touched.budget && errors.budget}
                  />
                  <FormInputText
                    label="Website URL"
                    type="text"
                    name="website_url"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.website_url}
                    error={touched.website_url && errors.website_url}
                  />
                </FormGroup>
                <Typography variant="h4" color={primary}>
                  Setup your preferences
                </Typography>
                <FormGroup>
                  {USER_CONSIDERATIONS[preferenceType.enum] && (
                    <FormSelect
                      label={`${preferenceType.name} Considerations`}
                      type="text"
                      name="consideration"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.consideration}
                      error={touched.consideration && errors.consideration}
                      options={USER_CONSIDERATIONS[preferenceType.enum].map(
                        p => ({ value: p.enum, label: p.name }),
                      )}
                    />
                  )}
                  <FormSelect
                    label={`${preferenceType.name} Pricing`}
                    multiple={false}
                    type="text"
                    name="pricing"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.pricing}
                    error={touched.pricing && errors.pricing}
                    options={PRICING.map(p => ({
                      value: p.enum,
                      label: p.name,
                    }))}
                  />
                </FormGroup>
                <FormGroup>
                  <FormSelect
                    label="Your wedding business is:"
                    type="text"
                    name="is_your_business"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.is_your_business}
                    error={touched.is_your_business && errors.is_your_business}
                    options={BUSINESSES.map(p => ({
                      value: p.enum,
                      label: p.name,
                    }))}
                  />
                  {preferenceType.enum == 1 && (
                    <FormSelect
                      label={`${preferenceType.name} Amenities`}
                      type="text"
                      name="ameneties"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.ameneties}
                      error={touched.ameneties && errors.ameneties}
                      options={VENUE_AMENITIES.map(p => ({
                        value: p.enum,
                        label: p.name,
                      }))}
                    />
                  )}
                </FormGroup>

                <FormGroup>
                  {USER_SERVICES[preferenceType.enum] && (
                    <FormSelect
                      label={`${preferenceType.name} Services`}
                      type="text"
                      name="services"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.services}
                      error={touched.services && errors.services}
                      options={USER_SERVICES[preferenceType.enum].map(p => ({
                        value: p.enum,
                        label: p.name,
                      }))}
                    />
                  )}
                  {USER_STYLES[preferenceType.enum] &&
                    USER_STYLES[preferenceType.enum]?.control === 'star' ? (
                    <FormItemWrapper
                      label={USER_STYLES[preferenceType.enum]?.label}>
                      <Typography variant="caption" color={textDisabled}>
                        {USER_STYLES[preferenceType.enum]?.subLabel}
                      </Typography>
                      {USER_STYLES[preferenceType.enum].items.map(style => (
                        <div>
                          <>
                            <Typography>{style.name}</Typography>
                            <Rating
                              defaultValue={values.ranking[style.enum] ?? 0}
                              max={10}
                              size="medium"
                              onChange={(_, value) => {
                                setFieldValue('ranking', {
                                  ...values.ranking,
                                  [style.enum]: value,
                                });
                              }}
                            />
                          </>
                        </div>
                      ))}
                    </FormItemWrapper>
                  ) : (
                    <FormSelect
                      label={`${preferenceType.name} Style`}
                      type="text"
                      name="styles"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.styles}
                      error={touched.styles && errors.styles}
                      options={USER_STYLES[preferenceType.enum].items.map(
                        p => ({ value: p.enum, label: p.name }),
                      )}
                    />
                  )}
                </FormGroup>

                <div>
                  <SpacedButton type="submit" pending={addVendorApi.loading}>
                    Submit
                  </SpacedButton>
                </div>
              </Form>
            </Content>
          </form>
        )}
      </Formik>
    </div>
  );
};
