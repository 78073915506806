import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper';
import styled from 'styled-components'

import 'swiper/css';
import "swiper/css/pagination";

import './styles.css'
import { Typography } from '@mui/material';
import { primary, textDisabled } from 'theme';

const Slide = styled.div`
  display: flex;
  height: 320px;
  border: 1px solid #EAEAEA;
  width: 100%;
`

const Image = styled.img`
  width: 40%;
  height: inherit;
  object-fit: cover;
`

const Content = styled.div`
  padding: 40px 30px;
  display: flex;
  flex-direction: column;
  gap: 40px;
  align-items: center;
  width: 60%;
`

export const Slider = ({ slides = [] }) => {
  return (
    <Swiper
      slidesPerView={3}
      spaceBetween={20}
      freeMode={true}
      scrollbar={true}
      pagination={{
        clickable: true,
      }}
      modules={[Pagination]}
    >
      {
        slides.map((s, index) => <SwiperSlide key={`slides${index}`}>
          <Slide>
            <Image src={s.image} />
            <Content>
              <div>
                <img src={require("assets/images/people1.png")} />
                <img src={require("assets/images/people2.png")} />
              </div>
              <Typography color={textDisabled}>{s.caption}</Typography>
              <Typography color={primary}>{s.names}</Typography>
            </Content>
          </Slide>
        </SwiperSlide>)
      }
    </Swiper>
  )
}
