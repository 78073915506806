import React from 'react';
import { Button as MuiButton } from '@mui/material';
import styled from 'styled-components';

import { Typography } from '../typography';
import { primary, textDisabled } from 'theme';

const Container = styled.div`
  width: 310px;
  background-color: #ffff;
  box-shadow: 0 1px 16px 0 rgba(0, 0, 0, 0.15);
  cursor: pointer;

  :hover {
    background-color: #eff8ff;
  }
`;
const Image = styled.img`
  height: 230px;
  width: 310px;
  object-fit: cover;
`;
const Content = styled.div`
  padding: 16px;
  gap: 8px;
  display: flex;
  flex-direction: column;

  p.description {
    word-break: break-word;
  }
`;

const TitleBlock = styled.div`
  display: flex;
  justify-content: space-between;

  p {
    width: 75%;
  }
`;

const AddButton = styled(MuiButton)`
  --bg-color: #093766;

  min-width: 32px;
  height: 32px;
  background-color: var(--bg-color);
  border: 1px solid #429efb;
  border-radius: 100px;

  &:hover {
    background-color: var(--bg-color);
  }

  &:hover.Mui-disabled {
    background-color: var(--bg-color);
  }
`;

const NoteText = styled(Typography)`
  font-size: 9px;
  color: ${textDisabled};
  line-height: 10px;
  margin-top: 6px;
`;

const PriceContent = styled.div`
  margin-top: 6px;
`;

export const VendorDeal = ({ deal, addDeal }) => {
  return (
    <Container key={`deal_${deal.id}`}>
      <Image src={deal.image} />
      <Content>
        <TitleBlock>
          <Typography variant="h4" color={primary}>
            {deal.title}
          </Typography>

          <AddButton>
            <svg
              width={14}
              height={14}
              viewBox="0 0 14 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <path
                d="M14 5.83333H8.16667V0H5.83333V5.83333H0V8.16667H5.83333V14H8.16667V8.16667H14V5.83333Z"
                fill="white"
              />
            </svg>
          </AddButton>
        </TitleBlock>
        <Typography variant="caption" className="description">
          {deal.description.slice(0, 100)}...
        </Typography>
        <PriceContent>
          <Typography variant="subheading4" color={primary}>
            ${deal.budget}*
          </Typography>
          <NoteText>
            *Prices may vary based on time, date, and circumstances.
          </NoteText>
        </PriceContent>
      </Content>
    </Container>
  );
};
