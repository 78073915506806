import React from 'react';
import styled, { css } from 'styled-components';
import { TextField } from '@mui/material';
import { Link } from 'react-router-dom'
import { useFormik } from 'formik';
import { breakpoints, primary } from 'theme';
import { Button, Typography } from 'components'


const StyledTextField = styled(TextField)`
  width: 42px;
  text-align: center;
  & input {
    text-align: center;
    padding: 10px 8px;
  };

  & input::placeholder {
    text-align: center;
  }
  & input:focus::placeholder {
    color: transparent;
  }
  
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input[type=number] {
    -moz-appearance: textfield;
  }
`;

const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  margin-top: -60px;
  gap:40px;

  @media ${breakpoints.viewport_s_and_down} {
    padding: 12px;
    padding-top: 40px;
    justify-content: flex-start;
    margin-top: 60px;
  }
`

const Form = styled.form`
  display: flex;
  flex-direction:row;
  gap:20px;
`

const Logo = styled.img`
  width: 72px;
  height: 64px;
`;


export const Token = () => {
  const inputRefs = [];

  const formik = useFormik({
    initialValues: { code: [...Array(4)].map((_) => '') },
    onSubmit: function (values) {

    },
  });

  const setNextFocus = (currentIndex) => {
    let nextFocus;
    const emptyOrInvalidIndexes = formik.values.code.reduce((a, e, i) => {
      if (!e || ![...Array(10)].map((_, i) => i).includes(Number(e))) {
        a.push(i);
      }
      return a;
    }, []);

    nextFocus = emptyOrInvalidIndexes.find((e) => e > currentIndex);
    if (nextFocus === undefined) {
      nextFocus = emptyOrInvalidIndexes[0];
    }
    if (nextFocus !== undefined) {
      inputRefs[nextFocus].focus();
      return;
    } else {
      inputRefs[formik.values.code.length - 1].focus();
      formik.handleSubmit();
    }
  };

  const handleChange = (e, index) => {
    formik.values.code[index] = e.nativeEvent.data;
    setNextFocus(index);
  };

  const handlePaste = (e, index) => {
    e.preventDefault();

    const pastedText = e.clipboardData.getData('Text');
    let nextIndex = index;
    let i = 0;

    while (i < pastedText.length && nextIndex < formik.values.code.length - 1) {
      nextIndex = i + index;
      formik.values.code[nextIndex] = pastedText[i];
      i += 1;
    }
    setNextFocus(nextIndex);
  };

  return (
    <FormWrapper>
      <Link to="/">
        <Logo src={require("assets/images/logo.png")} alt='' />
      </Link>
      <Typography variant='h2' color={primary}>Input Token</Typography>
      <div>
        <Typography variant='h6' style={{ textAlign: 'center', marginBottom: 10 }}>Enter 4 digit token</Typography>
        <Form>
          {formik.values.code.map((_, index) => (
            <StyledTextField
              variant='outlined'
              placeholder='X'
              type='number'
              name='code'
              value={formik.values.code[index]}
              key={index}
              inputRef={(ref) => (inputRefs[index] = ref)}
              onChange={(e) => handleChange(e, index)}
              onPaste={(e) => handlePaste(e, index)}
              onBlur={formik.handleBlur}
              error={(formik.errors.code) && formik.touched.code}
            />
          ))}
        </Form>
      </div>
    </FormWrapper>
  );
};

