import { globalOptions, httpClient } from "utils";

export function getVendorDashboardRequest(id) {
  return httpClient.get(`/vendor-dashboard/?vendor_identification=${id}`);
}

export function getVendorIdentificationByIDRequest(id) {
  return httpClient.get(`/vendor-identification/${id}/`);
}

export function addVendorIdentificationRequest(vendor) {
  return httpClient.post(`/vendor-identification/`, vendor);
}

export function addPaymentRequest(payment) {
  return httpClient.post(`/pay/`, payment);
}

export function updateVendorIdentificationRequest(vendor) {
  return httpClient.patch(`/vendor-identification/${vendor.id}/`, vendor);
}

export function getVendorDealsRequest() {
  return httpClient.get("/vendor-deals/");
}

export function vendorDealsRequest(data) {
  return httpClient.post('/vendor-deals/', data, {
    headers: {
      'content-type': 'multipart/form-data',
    },
  });
}

export function deleteVendorDealRequest(id) {
  return httpClient.delete(`/vendor-deals/${id}`);
}

export function getVendorLeadsRequest(id) {
  return httpClient.get(`/unique-visitor/?service=${id}`);
}

export function getLeadsAmountRequest() {
  return httpClient.get("/get-amount/");
}

export function getPlansRequest() {
  return httpClient.get("/pay/get-plans/");
}

export function getTokenRequest() {
  return httpClient.get("/checkout/client-token/");
}

export function sendLeadsToEmailRequest() {
  return httpClient.get("/vendor-dashboard/send-email/");
}
export function unsubscriptionRequest() {
  const brainTreeUnsubscribeURL = globalOptions.api.replace(
    'api/v1',
    'braintree/unsubscribe/',
  );

  return httpClient.request({
    method: 'GET',
    baseURL: brainTreeUnsubscribeURL,
  });
}