import { Button, FormDatePicker, FormInputText, Typography } from 'components'
import { Formik } from 'formik'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useNavigate, useSearchParams } from 'react-router-dom'
import { signup, login } from 'store/auth'
import styled from 'styled-components'
import { breakpoints, primary } from 'theme'
import * as Yup from 'yup'

// const validationSchema = 

const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 60px;
  gap:40px;

  @media ${breakpoints.viewport_s_and_down} {
    padding: 12px;
    padding-top: 40px;
  }
`

const Form = styled.form`
  display: flex;
  flex-direction:column;
  min-width: 460px;
  gap:20px;

  @media ${breakpoints.viewport_s_and_down} {
   min-width: inherit;
  }
`

const FormGroup = styled.div`
  flex-direction: row;
  display: flex;
  gap: 20px;
`

const Logo = styled.img`
  width: 72px;
  height: 64px;
`;

const SpacedButton = styled(Button)`
  flex-direction: column;
  display: flex;
`
const LinkText = styled(Typography)`
  text-align: center;
`

export const Register = () => {
  const { api } = useSelector(state => state.auth)
  const dispatch = useDispatch()
  const navigation = useNavigate()
  const [searchParams] = useSearchParams()

  return (
    <FormWrapper>
      <Link to="/">
        <Logo src={require("assets/images/logo.png")} alt='' />
      </Link>
      <Typography variant='h2' color={primary}>
        {searchParams.get('type') === "couple" ? "Create a FREE account" : "Create an account"}
      </Typography>
      <Formik
        initialValues={{ name: '', email: '', password: '', wedding_date: '', wedding_zip_code: '' }}
        validationSchema={Yup.object().shape({
          name: Yup.string().label("Name").required(),
          email: Yup.string().label("Email").email().required(),
          password: Yup.string().label("Password").required(),
          wedding_zip_code: Yup.string().label("Wedding Zip Code").required(),
          wedding_date: Yup.date().when([], {
            is: () => searchParams.get('type') === "couple",
            then: Yup.date().label("Wedding date").required(),
            otherwise: Yup.date().notRequired(),
          })
        })}
        onSubmit={(values) => {
          const { wedding_date, ...rest } = values
          dispatch(signup({
            user: {
              ...rest,
              role: searchParams.get('type'),
              wedding_date: new Date(!wedding_date ? Date.now() : wedding_date).toISOString().split("T")[0]
            },
            // callback: () => navigation('/login')
            callback: () => dispatch(login({
              username: values.email,
              password: values.password 
            }))
          }))
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          setFieldValue,
          setFieldTouched
        }) => (
          <Form onSubmit={handleSubmit}>
            <FormInputText
              label="Name"
              type="text"
              name="name"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.name}
              error={touched.name && errors.name}
            />
            <FormInputText
              label="Email"
              type="email"
              name="email"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.email}
              error={touched.email && errors.email}
            />
            <FormInputText
              label="Password"
              type="password"
              name="password"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.password}
              error={touched.password && errors.password}
            />
            <FormGroup>
              {
                searchParams.get('type') === "couple" &&
                <FormDatePicker
                  label="Wedding Date"
                  type="text"
                  name="wedding_date"
                  onChange={(e) => setFieldValue("wedding_date", e)}
                  value={values.wedding_date}
                  error={touched.wedding_date && errors.wedding_date}
                />
              }
              <FormInputText
                label="Wedding Zip Code"
                type="text"
                name="wedding_zip_code"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.wedding_zip_code}
                error={touched.wedding_zip_code && errors.wedding_zip_code}
              />
            </FormGroup>
            <SpacedButton type="submit" pending={api.loading}>
              Submit
            </SpacedButton>
            <Link to={`/login?type=${searchParams.get('type') ?? 'couple'}`}><LinkText>Login</LinkText></Link>
          </Form>
        )}
      </Formik>
    </FormWrapper>
  )
}
