import { BrowserRouter, Navigate, Route, Routes as RDRoutes } from "react-router-dom";
import { Login, Register, Token, Contact } from 'containers/auth'
import { Landing } from "containers/auth";
import { AuthLayout, MainLayout } from "layouts";
import { Vendor, Deals, Packages, Dashboard, Preferences } from "containers/couple";
import {
  CreateDeal, Dashboard as VendorDashboard, Preferences as VendorPreference, Deals as VendorDeals, VendorTypes,
  ConfirmDeal, Leads, Payment
} from "containers/vendor";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setAuthToken } from "./httpClient";
import { getDashboard } from "store/auth";
import { CircularProgress } from "@mui/material";
import styled from "styled-components";
import Policy from "containers/auth/pages/policy";
import { PublicMainLayout } from "layouts/PublicMainLayout";

const CircularProgressContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`

export const Routes = () => {

  const dispatch = useDispatch();
  const { fetchingUserApi, isAuthenticated, user } = useSelector(state => state.auth)

  useEffect(() => {
    const token = JSON.parse(localStorage.getItem("@token"))
    if (token) {
      setAuthToken(token.access)
      dispatch(getDashboard())
    }
  }, [])


  return (
    <BrowserRouter>
      {!isAuthenticated && fetchingUserApi.loading ? (
        <CircularProgressContainer>
          <CircularProgress />
        </CircularProgressContainer>
      ) : (
        <RDRoutes>
          <Route path="/" element={<Landing />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/privacy-policy" element={<Policy />} />
          <Route path="/" element={<AuthLayout />}>
            <Route path={`/login`} element={<Login />} />
            <Route path="/register" element={<Register />} />
            <Route path="/token" element={<Token />} />
          </Route>

          <Route path="/couple" element={<PublicMainLayout />}>
            <Route path="build-wedding" element={<Vendor />} />
            <Route path="build-wedding/:id" element={<Preferences />} />
          </Route>

          <Route path="/couple" element={<MainLayout />}>
            <Route path="dashboard" element={<Dashboard />} />
            <Route path="deals" element={<Deals />} />
            <Route path="packages" element={<Packages />} />
          </Route>

          <Route path="/vendor" element={<MainLayout />}>
            <Route path="dashboard" element={<VendorDashboard />} />
            <Route path="type" element={<VendorTypes />} />
            <Route path="payment/:id" element={<Payment />} />
            <Route path="preferences/:id" element={<VendorPreference />} />
            <Route path="deals" element={<VendorDeals />} />
            <Route path="leads" element={<Leads />} />
            <Route path="create-deal" element={<CreateDeal />} />
            <Route path="confirm-deal" element={<ConfirmDeal />} />
          </Route>

          <Route path="*" element={<Navigate to="/vendor/dashboard" />} />
        </RDRoutes>
      )}
    </BrowserRouter>
  );
}