import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import * as api from 'api/auth'
import { toast } from 'react-toastify';
import { getServerError, setAuthToken } from "utils";

export const getDeals = createAsyncThunk(
  "auth/getDeals",
  async () => {
    try {
      const response = await api.getDealsRequest()
      return response.data
    } catch (err) {
      throw new Error(err)
    }
  }
)

export const search = createAsyncThunk(
  "auth/search",
  async (query) => {
    try {
      const response = await api.searchRequest(query)
      return response.data?.vendors || []
    } catch (err) {
      throw new Error(err)
    }
  }
)

export const signup = createAsyncThunk(
  "auth/signup",
  async ({ user, callback }) => {
    try {
      const response = await api.signUpRequest(user)
      toast("User is registered successfully", { type: 'success' })
      callback()
      return response.data
    } catch (err) {
      let errorMsg = getServerError(err.response?.data) || JSON.stringify(err.response || err);
      toast(errorMsg, { type: 'error' })
      throw new Error(err)
    }
  }
)

export const login = createAsyncThunk(
  "auth/login",
  async (credentials) => {
    try {
      const response = await api.loginRequest(credentials)
      const { user, ...rest } = response.data
      setAuthToken(response.data.access)
      localStorage.setItem("@token", JSON.stringify(rest))
      return user
    } catch (err) {
      let errorMsg = getServerError(err.response?.data) || JSON.stringify(err.response || err);
      toast(errorMsg, { type: 'error' })
      throw new Error(err)
    }
  }
)

export const getDashboard = createAsyncThunk(
  "auth/dashboard",
  async () => {
    try {
      const response = await api.getDashboardRequest()
      return response.data
    } catch (err) {
      let errorMsg = getServerError(err.response?.data) || JSON.stringify(err.response || err);
      toast(errorMsg, { type: 'error' })
      throw new Error(err)
    }
  }
)

export const deleteDashboardItem = createAsyncThunk(
  "auth/delete-dashboard-item",
  async (payload, { dispatch }) => {
    try {
      await api.deleteDashboardItemApi(payload)
      dispatch(getDashboard())
    } catch (err) {
      let errorMsg = getServerError(err.response?.data) || JSON.stringify(err.response || err);
      toast(errorMsg, { type: 'error' })
      throw new Error(err)
    }
  }
)

export const updateUser = createAsyncThunk(
  "auth/update-user",
  async ({ user, callback }) => {
    try {
      const response = await api.updateUserRequest(user)
      callback()
      return response.data
    } catch (err) {
      let errorMsg = getServerError(err.response?.data) || JSON.stringify(err.response || err);
      toast(errorMsg, { type: 'error' })
      throw new Error(err)
    }
  }
)

export const initialState = {
  api: { loading: false, error: null },
  getDealsApi: { loading: false, error: null },
  isAuthenticated: false,
  deals: [],
  user: {
    role: 'couple'
  },
  fetchingUserApi: { loading: false, error: null },
  vendors: [],
  deleteDashboardItemApi: { loading: false, error: null },
  deleteDashboardItemApiSuccess: false,
  updateUserApi: { loading: false, error: null },
}

export const authSlice = createSlice({
  name: "auth",
  initialState: initialState,
  reducers: {
    logOut: state => {
      state.isAuthenticated = false;
      state.user = {
        role: 'couple'
      }
    },
    updateVendorCategory: (state, action) => {
      state.user = {
        ...state.user,
        category: action.payload
      }
    },
    updateVendorIdentification: (state, action) => {
      state.user = {
        ...state.user,
        vendor_identification: action.payload.vId,
        subscription: action.payload.subscription
      }
    }
  },
  extraReducers: {
    [getDeals.pending]: state => {
      state.getDealsApi = { loading: true, error: null }
    },
    [getDeals.fulfilled]: (state, action) => {
      state.getDealsApi.loading = false
      state.deals = action.payload
    },
    [getDeals.rejected]: (state, action) => {
      state.getDealsApi = { loading: false, error: action.payload }
    },
    [search.pending]: (state, action) => {
      state.getDealsApi.loading = true
    },
    [search.fulfilled]: (state, action) => {
      state.getDealsApi.loading = false
      state.deals = action.payload
    },
    [search.rejected]: (state, action) => {
      state.getDealsApi.loading = false
    },
    [signup.pending]: state => {
      state.api = { loading: true, error: null }
    },
    [signup.fulfilled]: (state, action) => {
      state.api.loading = false
    },
    [signup.rejected]: (state, action) => {
      state.api = { loading: false, error: action.payload }
    },

    [login.pending]: state => {
      state.api = { loading: true, error: null }
    },
    [login.fulfilled]: (state, action) => {
      state.api.loading = false
      state.isAuthenticated = true
      state.user = action.payload
    },
    [login.rejected]: (state, action) => {
      state.api = { loading: false, error: action.payload }
    },

    [getDashboard.pending]: state => {
      state.fetchingUserApi = { loading: true, error: null }
    },
    [getDashboard.fulfilled]: (state, action) => {
      state.fetchingUserApi.loading = false
      state.isAuthenticated = true
      state.user = action.payload.user
      state.vendors = action.payload.vendors
    },
    [getDashboard.rejected]: (state, action) => {
      state.fetchingUserApi = { loading: false, error: action.payload }
    },

    [deleteDashboardItem.pending]: state => {
      state.deleteDashboardItemApi = { loading: true, error: null }
      state.deleteDashboardItemApiSuccess = false
    },
    [deleteDashboardItem.fulfilled]: (state, action) => {
      state.deleteDashboardItemApi.loading = false
      state.deleteDashboardItemApiSuccess = true

    },
    [deleteDashboardItem.rejected]: (state, action) => {
      state.deleteDashboardItemApi = { loading: false, error: action.payload }
      state.deleteDashboardItemApiSuccess = false
    },

    [updateUser.pending]: state => {
      state.updateUserApi = { loading: true, error: null }
    },
    [updateUser.fulfilled]: (state, action) => {
      state.updateUserApi.loading = false
      state.user = action.payload
    },
    [updateUser.rejected]: (state, action) => {
      state.updateUserApi = { loading: false, error: action.payload }
    },
  }
})

export const {
  logOut,
  updateVendorCategory,
  updateVendorIdentification,
} = authSlice.actions;
