import { Typography } from 'components'
import React from 'react'
import { useSelector } from 'react-redux'
import { Navigate, useOutlet } from 'react-router'
import { useSearchParams } from 'react-router-dom'
import styled from 'styled-components'
import { breakpoints, primary, secondary } from 'theme'

const Container = styled.div`
  display: flex;
  height: 100%;
`

const SideBanner = styled.div`
  background-color: #023866D9;
  background-image: url(${require('assets/images/side-banner.png')});
  width:50%;
  height: inherit;
  position: absolute;
  background-size: cover;
  background-position: center;
  background-blend-mode: multiply;
  z-index: -10;
  height: 100%;

  @media ${breakpoints.viewport_m_and_down} {
   display: none;
  }
`
const SideBannerContent = styled.div`
  z-index: 100;
  display: flex;
  height: 100%;
  width: 50%;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  @media ${breakpoints.viewport_m_and_down} {
   display: none;
  }
`

const SideBannerTitleContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap:24px;
  padding-right: 12px;
  width: 50%;
  height: 70%;
  margin-bottom: 40px;
`

const Logo = styled.img`
  width: 70px;
  height: 50px;
`;

const Content = styled.div`
  width: 50%;
  overflow-y: auto;

  @media ${breakpoints.viewport_m_and_down} {
   width: 100%;
  }
`

const List = styled.ul`
  color: #fff;
  margin-top: -8px;
`

export const AuthLayout = () => {

  const outlet = useOutlet();
  const { isAuthenticated, user } = useSelector(state => state.auth)
  const [searchParams] = useSearchParams()

  if (isAuthenticated && user?.role) {
    return <Navigate to={`/${user.role}/dashboard`} />;
  }

  return (
    <>
      <SideBanner />
      <Container>
        <SideBannerContent>
          <Logo src={require("assets/images/logo-alt.png")} alt='' />
          <SideBannerTitleContent>
            {
              searchParams.get('type') === "vendor" ?
                <>
                  <Typography variant='h3' color="#ffff">Get more leads with Wedstimate!</Typography>
                  <Typography color="#ffff">
                    Wedstimate matches couples with wedding professionals who meet their criteria and budget.
                    We send you the leads that have already expressed interest in your style and price point.
                  </Typography>
                  <Typography color="#ffff">It's that simple!</Typography>
                  <List>
                    <li><Typography color="#ffff">Vendors are guaranteed 5 leads per month</Typography></li>
                    <li><Typography color="#ffff">Vendors match with couples based on preference</Typography></li>
                    <li><Typography color="#ffff">Vendors get featured across our media channels</Typography></li>
                    <li><Typography color="#ffff">Vendors can post deals directly to the app</Typography></li>
                    <li><Typography color="#ffff">Vendors can access all of our features for a fraction of the cost of other wedding directories</Typography></li>
                  </List>
                </> :
                <>
                  <Typography variant='h2' color="#ffff" style={{ marginBottom: 20 }}>Welcome to Wedstimate</Typography>
                  <Typography color="#ffff">Let us help you find the wedding professionals that make your dreams come true!</Typography>
                </>
            }
          </SideBannerTitleContent>
        </SideBannerContent>
        <Content>
          {outlet}
        </Content>
      </Container>
    </>
  )
}
