import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate } from 'react-router';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { primary } from 'theme';
import { getVendorDashboard, resetAddVendors } from 'store/vendor';
import { Button, Typography, VendorServices } from 'components';
import { VENDORS_CATEGORIES } from 'utils';


const Container = styled.div`
  width: 100%;
`;

const StatsWidget = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 30px;

  .p-15 {
    padding: 15px;
  }

  .mb-8 {
    margin-bottom: 8px;
  }

  .mb-16 {
    margin-bottom: 16px;
  }

  .mb-20 {
    margin-bottom: 20px;
  }

  .w-63 {
    width: 63%;
  }

  .w-100 {
    width: 100%;
  }

  .text-center {
    text-align: center;
  }

  .text-right {
    text-align: right;
  }
`;

const VendorStats = styled.div`
  margin-top: 40px;
  width: 92%;
`;

const Box = styled.div`
  width: 33%;
  ${({ showBorder }) => showBorder && `border-right: 1px dotted;`}
`;

const BoxWrapper = styled.div`
  display: flex;
  flex-direction: row;
  ${({ transparent }) => !transparent && `background-color: #E8F6FF;`}
`;

const BoxWrapperContent = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  margin-bottom: 40px;
`;

const VendorStatsButton = styled(Button)`
  width: 100%;
  text-transform: capitalize;
`;

export const Dashboard = () => {
  const { user } = useSelector((state) => state.auth);
  const { addVendorApi, leads, identificationResponse } = useSelector(
    (state) => state.vendor,
  );

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (user?.vendor_identification) {
      dispatch(getVendorDashboard(user.vendor_identification));
    } else {
      // create a service first.
    }
  }, [user?.vendor_identification]);

  useEffect(() => {
    if (addVendorApi.success) {
      dispatch(resetAddVendors())
    }
  }, [addVendorApi.success])

  if (!user.category) {
    return <Navigate to={`/vendor/type`} />;
  }

  return (
    <>
      <Container>
        <StatsWidget>
          <BoxWrapper transparent>
            <div>
              <Typography variant="caption">VENDOR</Typography>
              <Typography variant="h3">
                {user.category
                  ? `Total ${VENDORS_CATEGORIES[user?.category - 1].name
                  }\nDashboard`
                  : 'Dashboard'}
              </Typography>

              <VendorStats>
                <Typography
                  className="text-center"
                  variant="h1"
                  color={primary}>
                  {leads.unique_visitors}
                </Typography>
                <Typography className="mb-20 text-center">
                  Unique Visitors
                </Typography>

                <VendorStatsButton className="mb-16" onClick={() => navigate("/vendor/leads")}>
                  View Leads
                </VendorStatsButton>
                <VendorStatsButton variant="secondary" className="mb-8" onClick={() => navigate("/vendor/create-deal")}>
                  Add Deals
                </VendorStatsButton>

                <Typography variant="caption" className="text-center">
                  Generate even more leads by adding <br /> a deal to your
                  listing.
                </Typography>
              </VendorStats>
            </div>
          </BoxWrapper>

          <div className="w-63 p-15">
            <BoxWrapperContent>
              <BoxWrapper className="w-100" transparent>
                <Box showBorder>
                  <Typography>Leads</Typography>
                  <Typography variant="h3" color={primary}>
                    {leads && leads.percentage_90?.leads
                      ? leads.percentage_90.leads
                      : '0'}
                  </Typography>
                  <Typography>90% match</Typography>
                </Box>
                <Box showBorder className="text-center">
                  <Typography>Leads</Typography>
                  <Typography variant="h3" color={primary}>
                    {leads && leads.percentage_80?.leads
                      ? leads.percentage_80.leads
                      : '0'}
                  </Typography>
                  <Typography>80% match</Typography>
                </Box>
                <Box className="text-right">
                  <Typography>Leads</Typography>
                  <Typography variant="h3" color={primary}>
                    {leads && leads.percentage_50?.leads
                      ? leads.percentage_50.leads
                      : '0'}
                  </Typography>
                  <Typography>50% match</Typography>
                </Box>
              </BoxWrapper>
            </BoxWrapperContent>

            <Typography variant="h3">Your Services</Typography>

            <VendorServices />
          </div>
        </StatsWidget>
      </Container>
    </>
  );
};
