import {
  Button,
  FormInputText,
  Header,
  Typography,
  Vendor,
  Ceremony,
  FormSelect,
} from 'components';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import {
  breakpoints,
  containerWidth,
  primary,
  secondary,
  textDisabled,
} from 'theme';
import { useDispatch, useSelector } from 'react-redux';
import { getDeals } from 'store/auth';
import { VENDORS_CATEGORIES } from 'utils';
import { useNavigate } from 'react-router';

const Banner = styled.div`
  display: flex;
  height: 420px;
  background-image: linear-gradient(
      75.31deg,
      #000000 0.89%,
      rgba(0, 0, 0, 0) 64.24%
    ),
    url(${require('assets/images/banner-bg.png')});
  gap: 40px;
  padding: 40px;
  background-size: cover;
  background-position: bottom;

  @media ${breakpoints.viewport_m_and_down} {
    height: 410px;
  }

  @media ${breakpoints.viewport_s_and_down} {
    height: auto;
    padding-bottom: 90px;
  }
`;
const BannerContent = styled.div`
  display: flex;
  margin: 0 auto;
  max-width: ${containerWidth}px;
`;

const TitleContent = styled.div`
  display: flex;
  flex-direction: column;
  padding-right: 16px;
  gap: 36px;
  width: ${containerWidth}px;
`;
const SearchContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin: auto;
  max-width: ${containerWidth}px;
  margin-top: -60px;
  padding: 0 24px;
`;

const SearchContent = styled.div`
  display: flex;
  flex-direction: row;
  background-color: #ffff;
  padding: 24px;
  align-items: flex-end;
  gap: 60px;
  width: 100%;
  box-shadow: 0 1px 16px 0 rgba(0, 0, 0, 0.15);

  @media ${breakpoints.viewport_s_and_down} {
    flex-direction: column;
    gap: 20px;
  }
`;
const CustomButton = styled(Button)`
  height: 60px;
  button {
    padding-left: 50px;
    padding-right: 50px;
  }

  @media ${breakpoints.viewport_s_and_down} {
    height: initial;
  }
`;
const InputGroup = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
  width: 100%;
`;
const WeddingVendorsContent = styled.div`
  margin-top: 120px;
  height: 320px;
  box-shadow: 0 1px 16px 0 rgba(0, 0, 0, 0.15);
`;
const SideBanner = styled.div`
  background-color: #023866d9;
  background-image: url(${require('assets/images/side-banner.png')});
  width: 50%;
  height: inherit;
  position: absolute;
  background-size: cover;
  background-position: center 40%;
  background-blend-mode: multiply;

  @media ${breakpoints.viewport_m_and_down} {
    display: none;
  }
`;
const SideBannerContent = styled.div`
  z-index: 100;
  margin: auto;
  max-width: ${containerWidth}px;
  display: flex;
  height: 320px;
`;

const SideBannerTitleContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 36px;
  padding-right: 60px;
  z-index: 10;
  margin-left: -20px;

  @media ${breakpoints.viewport_m_and_down} {
    display: none;
  }
`;

const SpacedVendor = styled(Vendor)`
  height: 320px;
  box-shadow: 0 1px 16px 0 rgba(0, 0, 0, 0.15);
`;

const VendorList = styled.div`
  width: 65%;
  display: flex;
  align-items: center;

  @media ${breakpoints.viewport_m_and_down} {
    width: 100%;
    padding: 0 20px;
  }
`;
const ScrollList = styled.div`
  width: -moz-available;
  width: -webkit-fill-available;
  width: fill-available;
  display: flex;
  align-items: center;
  gap: 20px;
  overflow-x: auto;
  overflow-y: hidden;
  position: absolute;
  -ms-overflow-style: none;
  scrollbar-width: none;

  ::-webkit-scrollbar {
    display: none;
  }
`;
const PaginationButtonGroup = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 8px;
  margin-bottom: 40px;
`;

const AboutImage = styled.img`
  width: 45%;
  height: fit-content;

  @media ${breakpoints.viewport_m_and_down} {
    display: none;
  }
`;

const FeatureContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 180px;
  gap: 60px;
`;

const FeatureContent = styled.div`
  display: flex;
  flex-direction: column;
  gap:30px;
  margin-top: -80px;
  ${({ left }) => (left ? `padding-left: 160px;` : `padding-right: 160px;`)}

  @media ${breakpoints.viewport_m_and_down} {
   padding: 0 20px;
  }
`;

const Item = styled.li`
  list-style-type: none;
  display: flex;
  align-items: flex-start;
  gap: 20px;
  margin-bottom: 24px;
`;

const Icon = styled.img`
  margin-top: 8px;
`;

const FeatureButton = styled(Button)`
  padding: 20px 50px;
  margin-left: 40px;
  margin-top: -30px;
`;

const MobileIcon = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
  margin-left: 40px;
  margin-top: -40px;
`;

const WeddingCeremony = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  flex-direction: column;
  margin-top: 120px;
`;

const WeddingCeremonyTextContent = styled.div`
  width: 60%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
  text-align: center;

  @media ${breakpoints.viewport_m_and_down} {
    width: 100%;
    padding: 0 20px;
    text-align: left;
  }
`;

const CeremonyContent = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 40px;
  width: 1200px;
  justify-content: space-between;

  @media ${breakpoints.viewport_m_and_down} {
    width: 100%;
    padding: 0 20px;
    flex-wrap: wrap;
    gap: 40px 0;
    justify-content: space-evenly;
  }
`;

const BottomContent = styled.div`
  width: 1200px;
  display: flex;
  margin: auto;
  flex-direction: column;

  @media ${breakpoints.viewport_m_and_down} {
    width: 90%;
    padding: 0 20px;
  }
`;

const EntertainmentContent = styled.div`
  display: flex;
  margin-top: 120px;
  grid-gap: 60px;
  align-items: center;
  margin-bottom: 50px;

  @media ${breakpoints.viewport_m_and_down} {
    flex-direction: column;
    align-items: baseline;
  }
`;

const EntertainmentTextContent = styled.div`
  width: 45%;
  border-right: 1px solid;
  padding-right: 20px;

  @media ${breakpoints.viewport_m_and_down} {
    width: 100%;
    border-right: none;
  }
`;
const EntertainmentSubTextContent = styled.div`
  width: 45%;

  @media ${breakpoints.viewport_m_and_down} {
    width: 100%;
  }
`;

const SliderContent = styled.div`
  margin-top: 110px;
  text-align: center;
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 60px;
`;

const MobileAppIcons = styled.img`
  height: 40px;
  cursor: pointer;
`;

const MobileAppIconsLink = styled.a`
  text-decoration: none;
`;

const Footer = styled.div`
  background-color: ${primary};
  padding: 50px 30px;
`;

const Link = styled.a`
  color: ${secondary};
`;

// const slides = [
//   {
//     image: require("assets/images/slide1.png"),
//     caption: 'Thank you for your great service',
//     names: 'Krish & Cristi'
//   },
//   {
//     image: require("assets/images/slide2.png"),
//     caption: "We can't forget our wedding moment, thank you for your services",
//     names: 'Ahmed & Cecilia'
//   },
//   {
//     image: require("assets/images/slide3.png"),
//     caption: 'Thank you for your great service',
//     names: 'Krish & Cristi'
//   },
//   {
//     image: require("assets/images/slide1.png"),
//     caption: 'Thank you for your great service',
//     names: 'Krish & Cristi'
//   }
// ]

export const Landing = () => {
  const [vendorCategory, setVendorCategory] = useState(1);
  const [weddingZipCode, setWeddingZipCode] = useState('');
  const navigation = useNavigate();

  const dispatch = useDispatch();
  const { deals, getDealsApi } = useSelector(s => s.auth);

  useEffect(() => {
    dispatch(getDeals());
  }, []);

  const onSearch = () => {
    // let query = `?category=${vendorCategory}&wedding_zip_code=${weddingZipCode}`
    // dispatch(search(query))
    navigation(
      `couple/build-wedding/${vendorCategory}?wedding_zip_code=${weddingZipCode}`,
    );
  };

  return (
    <>
      <div style={{ backgroundColor: '#ffff', paddingBottom: 60 }}>
        <Header />
        <Banner>
          <BannerContent>
            <TitleContent>
              <Typography variant="h1" color="#ffff">
                Plan your wedding
                <br />
                the easy way with
                <br />
                Wedstimate
              </Typography>
              <Typography color="#ffff">
                Let us help you find the wedding professionals that
                <br /> make your dreams come true!
              </Typography>
            </TitleContent>
            {/* <div>
            <Image src={require("assets /images/banner-bg.png")} />
          </div> */}
          </BannerContent>
        </Banner>
        <SearchContainer>
          <SearchContent>
            <InputGroup>
              <FormSelect
                label="Vendor Categories"
                placeholder="Select from dropdown"
                options={VENDORS_CATEGORIES.map(v => ({
                  value: v.enum,
                  label: v.name,
                }))}
                multiple={false}
                value={vendorCategory}
                onChange={e => setVendorCategory(e.target.value)}
              />
              <FormInputText
                label="Location/Zip code"
                placeholder="Enter Here"
                value={weddingZipCode}
                onChange={e => setWeddingZipCode(e.target.value)}
              />
            </InputGroup>
            <CustomButton onClick={onSearch} pending={getDealsApi.loading}>
              Search
            </CustomButton>
          </SearchContent>
        </SearchContainer>
        <WeddingVendorsContent>
          <SideBanner />
          <SideBannerContent>
            <SideBannerTitleContent>
              <Typography variant="h2" color="#ffff">
                Popular Wedding
                <br />
                Deals
              </Typography>
              <Typography color="#ffff">
                Let us help you find the wedding professionals
                <br />
                that make your dreams come true!
              </Typography>
            </SideBannerTitleContent>
            <VendorList>
              {/* <PaginationButtonGroup>
              <IconButton>
                <FontAwesomeIcon icon={faChevronLeft} color={primary} size='lg' />
              </IconButton>
              <IconButton>
                <FontAwesomeIcon icon={faChevronRight} color={primary} size='lg' />
              </IconButton>
            </PaginationButtonGroup> */}
              <ScrollList>
                {deals.map(d => (
                  <SpacedVendor deal={d} />
                ))}
              </ScrollList>
            </VendorList>
          </SideBannerContent>
        </WeddingVendorsContent>

        <FeatureContainer>
          <AboutImage src={require('assets/images/about-us.png')} />
          <FeatureContent>
            <Typography variant="subheading4" color={primary}>
              Mission
            </Typography>
            <Typography variant="h2" color={primary}>
              Submit a Wedding Vision Board for a Chance to Win $10K Towards Your
              Dream Wedding!
            </Typography>
            <Typography variant="subheading5" color={textDisabled}>
              Are you ready to turn your wedding dreams into reality?
              Wedstimate.com is thrilled to announce the launch of our exciting
              vision board contest! We believe that every bride deserves the
              wedding of her dreams, and we want to help make that happen.
              That's why we are offering an incredible prize of $10,000 for the
              winner to use towards their special day.
            </Typography>

            <Typography variant="subheading5" color={textDisabled}>
              Entering the contest is simple! Just submit a picture of your
              vision board to{' '}
              <a href="mailto:info@wedstimate.com">info@wedstimate.com</a> along
              with your name, contact details, and a brief description of your
              wedding vision. You can use images, magazine clippings, or create
              a digital collage to illustrate the elements that inspire you. Be
              sure to also fill out our questionnaire about the details of your
              wedding at:
              <a href="https://www.wedstimate.blog/about-2">
                https://www.wedstimate.blog/about-2
              </a>
            </Typography>

            <Typography variant="subheading5" color={textDisabled}>
              Our judges will review all the entries and select the bride whose
              vision board captures the essence of their dream wedding in the
              most remarkable way. The winning bride will receive the grand
              prize of $10,000 to bring her vision to life and create memories
              that will last a lifetime.
            </Typography>

            <ul>
              <Item>
                {/* <Icon src={require('assets/images/check.png')} />
                <Typography variant="subheading5" color={textDisabled}>
                  Download our app or create a free profile on our website to
                  find the best wedding vendors in your area!
                </Typography> */}
              </Item>
            </ul>
            <MobileIcon>
              <MobileAppIconsLink
                href="https://play.google.com/store/apps/details?id=com.rough_smoke_23947"
                target="_blank">
                <MobileAppIcons
                  src={require('assets/images/google-play-icon.png')}
                />
              </MobileAppIconsLink>
              <MobileAppIconsLink
                href="https://apps.apple.com/us/app/wedstimate-wedding-planner/id1578772552"
                target="_blank">
                <MobileAppIcons
                  src={require('assets/images/apple-play-icon.png')}
                />
              </MobileAppIconsLink>
            </MobileIcon>
            <div />
          </FeatureContent>
        </FeatureContainer>

        <FeatureContainer>
          <FeatureContent left>
            <Typography variant="subheading4" color={primary}>
              Vendors
            </Typography>
            <Typography variant="h2" color={primary}>
              Calling All Wedding Vendors
            </Typography>
            <Typography variant="subheading5" color={textDisabled}>
              If you pride yourself on delivering exceptional service, exquisite
              craftsmanship, and unforgettable moments, we want to hear from
              you.
            </Typography>
            <Typography variant="subheading5" color={textDisabled}>
              Complete our questionnaire to see if you qualify to be included in
              the Wedstimate.com directory, where engaged couples turn to find
              the best of the best in the industry.
            </Typography>
            <Typography variant="subheading5" color={textDisabled}>
              As a member of Wedstimate.com, you'll gain access to a targeted
              audience actively seeking extraordinary wedding vendors. Increase
              your visibility, showcase your talent, and connect with couples
              who appreciate your exceptional expertise.
            </Typography>
            <Typography variant="subheading5" color={textDisabled}>
              See if you qualify here:{' '}
              <a href="https://www.wedstimate.blog/vendors">
                https://www.wedstimate.blog/vendors
              </a>
            </Typography>
            <ul>
              <Item>
                {/* <Icon src={require('assets/images/check.png')} />
                <Typography variant="subheading5" color={textDisabled}>
                  Download our app and create a profile on our website to
                  feature your wedding business on Wedstimate!
                </Typography> */}
              </Item>
            </ul>
            <MobileIcon>
              <MobileAppIconsLink
                href="https://play.google.com/store/apps/details?id=com.rough_smoke_23947"
                target="_blank">
                <MobileAppIcons
                  src={require('assets/images/google-play-icon.png')}
                />
              </MobileAppIconsLink>
              <MobileAppIconsLink
                href="https://apps.apple.com/us/app/wedstimate-wedding-planner/id1578772552"
                target="_blank">
                <MobileAppIcons
                  src={require('assets/images/apple-play-icon.png')}
                />
              </MobileAppIconsLink>
            </MobileIcon>
          </FeatureContent>
          <AboutImage src={require('assets/images/wedding-vendors.png')} />
        </FeatureContainer>

        <BottomContent>
          <WeddingCeremony>
            <WeddingCeremonyTextContent>
              <Typography variant="h2" color={primary}>
                Check out some of our favorite vendors
              </Typography>
              <Typography variant="subheading5" color={textDisabled}>
                Find and book the best wedding vendors using Wedstimate.com.
                Here are just a few of our favorites.
              </Typography>
            </WeddingCeremonyTextContent>
            <CeremonyContent>
              <Ceremony
                image={require('assets/images/ceremony1.png')}
                eventName="Ybarra Events"
                url="https://www.ybarraevents.com/"
              />
              <Ceremony
                image={require('assets/images/ceremony2.png')}
                eventName="Rassasy Cakes"
                url="https://www.rassasy.com"
              />
              <Ceremony
                image={require('assets/images/ceremony3.jpeg')}
                eventName="Total DJ"
                url="https://www.total-dj.com"
              />
              <Ceremony
                image={require('assets/images/ceremony4.png')}
                eventName="Villa Chanticleer"
                url="https://www.villachanticleer.com"
              />
            </CeremonyContent>
          </WeddingCeremony>

          <EntertainmentContent>
            <EntertainmentTextContent>
              <Typography variant="h2" color={primary}>
                Boost Your Visibility
              </Typography>
            </EntertainmentTextContent>
            <EntertainmentSubTextContent>
              <Typography variant="subheading5" color={textDisabled}>
                Vendors that register with Wedstimate.com will be featured on
                our blog and the feature will be shared on Facebook, Instagram,
                and Twitter. This will help increase your visibility and boost
                your website rankings.
              </Typography>
            </EntertainmentSubTextContent>
          </EntertainmentContent>
          <img src={require('assets/images/entertainment.png')} />
        </BottomContent>

        {/* <SliderContent>
        <Typography variant='h2' color={primary}>Those who are married</Typography>
        <Slider slides={slides} />
      </SliderContent> */}
      </div>
      <Footer>
        <Typography color="#ffff">
          Copyright © 2021 Wedstimate, LLC. All rights reserved.
        </Typography>
        <Typography color="#ffff">
          <Link href="tel:+1-415-656-5535">415.656.5535</Link> |{' '}
          <Link href="privacy-policy/">Privacy Policy</Link>
        </Typography>
      </Footer>
    </>
  );
};
