import { Typography } from '../typography';
import React from 'react';
import styled from 'styled-components';
import { critical, textDisabled, textDefault } from 'theme';

const Label = styled.div`
  display: flex;
  ${({ theme }) => `margin-bottom: ${theme.spacing(0.5)}px;`};
`;

const SpacedTypography = styled(Typography)`
  ${({ theme }) => `margin-left: ${theme.spacing(0.5)}px;`};
`;

export const FormLabel = ({ name, required, disabled, className, variant = 'label', color = textDefault }) => (
  <Label className={className}>
    <Typography variant={variant} color={disabled ? textDisabled : color}>
      {name}
    </Typography>
    {required ? (
      <SpacedTypography variant={variant} color={critical}>
        *
      </SpacedTypography>
    ) : null}
  </Label>
);

export default FormLabel;
