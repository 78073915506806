import { Package, Typography } from 'components'
import React, { useEffect } from 'react'
import { primary } from 'theme'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import { addItemToDashboard, getPackages } from 'store/couple'

const Content = styled.div`
  margin-top: 50px;
  display:flex;
  flex-direction: row;
  gap:40px;
  flex-wrap: wrap;
`

export const Packages = () => {

  const dispatch = useDispatch()
  const { packages } = useSelector(state => state.couple)

  useEffect(() => {
    dispatch(getPackages())
  }, [])

  const addPackage = (id) => dispatch(addItemToDashboard({ package: id }))


  return (
    <div>
      <Typography variant='h3' color={primary}>Choose packages</Typography>
      <Typography>Select from the varieties of packages</Typography>
      <Content>
        {packages.map(p => <Package p={p} addPackage={addPackage} />)}
      </Content>
    </div>
  )
}
