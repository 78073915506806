import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';

import { breakpoints } from 'theme';
import {
  Button,
  FormDatePicker,
  FormFileUpload,
  FormInputText,
  Loader,
  Typography,
} from 'components';
import { deleteVendorDeal, getVendorDeals } from 'store/vendor';
import { VendorCurrentDeal } from 'components/deal/VendorCurrentDeal';

const initialValues = {
  title: '',
  budget: '',
  expiry: '',
  description: '',
  image: '',
  imageFile: {},
};

const validationSchema = Yup.object().shape({
  title: Yup.string().label('Deal Name').required(),
  budget: Yup.number().positive().label('Special Deal Pricing').required(),
  expiry: Yup.date().label('Deal Expiration').required(),
  description: Yup.string().label('Description').required(),
  image: Yup.string().label('Image').required(),
});

const Container = styled.div`
  width: 100%;
`;

const Content = styled.div`
  margin-top: 30px;
  display: flex;
  flex-direction: row;
  column-gap: 20px;
  row-gap: 30px;
  flex-wrap: wrap;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 20px;
`;

const Header = styled.div`
  margin-bottom: 25px;
`;

const CurrentDealWrapper = styled.div`
  margin-top: 50px;
`;

const SubmitButton = styled(Button)`
  width: 250px;
`;

const FormGroup = styled.div`
  display: grid;
  grid-template-columns: 50% 50%;
  gap: 20px;

  @media ${breakpoints.viewport_s_and_down} {
    grid-template-columns: 100%;
  }
`;

const FormColumn = styled.div`
  flex-direction: column;
  display: flex;
  gap: 20px;
  width: 100%;
`;

export const CreateDeal = () => {
  const {
    vendorDeals,
    getVendorDealsApi,
    addVendorDealApi,
    deleteVendorDealApi,
  } = useSelector((state) => state.vendor);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const fetchVendorDeals = () => {
    dispatch(getVendorDeals());
  };

  const onDelete = (id) => {
    dispatch(deleteVendorDeal({ id, callback: fetchVendorDeals }));
  };

  useEffect(() => {
    if (vendorDeals.length === 0) {
      fetchVendorDeals();
    }
  }, []);

  return (
    <Container>
      <Header>
        <Typography variant="caption">Vendor</Typography>
        <Typography variant="h3">Create your deal</Typography>
      </Header>

      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          navigate('/vendor/confirm-deal', {
            state: {
              data: values,
            },
          });
        }}>
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
        }) => (
          <Form onSubmit={handleSubmit}>
            <FormGroup>
              <FormColumn>
                <FormInputText
                  required
                  label="Deal Name"
                  type="text"
                  name="title"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.title}
                  error={touched.title && errors.title}
                />
                <FormInputText
                  multiline
                  required
                  label="Description"
                  type="text"
                  name="description"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.description}
                  error={touched.description && errors.description}
                />
              </FormColumn>

              <FormFileUpload
                label="Upload an image"
                name="image"
                onChange={(value, imageFile) => {
                  setFieldValue('image', value);
                  setFieldValue('imageFile', imageFile);
                }}
                onBlur={handleBlur}
                value={values.image}
                error={touched.image && errors.image}
              />
            </FormGroup>
            <FormGroup>
              <FormInputText
                required
                label="Special Deal Pricing"
                type="number"
                name="budget"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.budget}
                error={touched.budget && errors.budget}
              />
              <FormDatePicker
                required
                label="Deal Expiration"
                type="text"
                name="expiry"
                onChange={(e) => setFieldValue('expiry', e)}
                value={values.expiry}
                error={touched.expiry && errors.expiry}
              />
            </FormGroup>

            <SubmitButton type="submit" pending={addVendorDealApi.loading}>
              Submit
            </SubmitButton>
          </Form>
        )}
      </Formik>

      <CurrentDealWrapper>
        <Typography variant="h3">Current Deals</Typography>

        {getVendorDealsApi.loading && vendorDeals.length === 0 ? (
          <Loader />
        ) : (
          <Content>
            {vendorDeals.map((deal) => (
              <VendorCurrentDeal
                key={deal.id}
                deal={deal}
                deleteDeal={onDelete}
                pending={deleteVendorDealApi[deal?.id]?.loading || false}
              />
            ))}
          </Content>
        )}
      </CurrentDealWrapper>
    </Container>
  );
};
