import * as React from 'react';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import styled from 'styled-components'
import { Button, NameToken } from 'components';
import { MenuItem, Menu as MuiMenu } from '@mui/material';
import { breakpoints, containerWidth } from 'theme';
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from 'react-redux';

const AppBar = styled.div`
  top: 0;
  left: 0;
  right: 0;
  min-height: 80px;
  box-sizing: border-box;
  z-index: 1201;
  background-color: #ffff;
`;

const FlexToolbar = styled(Toolbar)`
  display: flex;
  align-items: center;
  box-sizing: border-box;
  margin:auto;
  max-width: ${containerWidth}px;
  min-height: 80px;
  justify-content:space-between;
  padding:0;

  @media ${breakpoints.viewport_l_and_down} {
    max-width: 100%;
    padding:0 40px
  }
`;

const Logo = styled.img`
  width: 64px;
  height: 57px;
`;

const ButtonGroup = styled.div`
  display: flex;
  flex-direction:row;
  gap:8px;
`
const RightContent = styled.div`
  display: flex;
  flex-direction:row;
`
const Menu = styled.div`
  display: flex;
  flex-direction:row;
  padding-right: 200px;

  @media ${breakpoints.viewport_s_and_down} {
    display: none;
  }
`

export const Header = () => {
  const navigate = useNavigate()
  const { isAuthenticated, user } = useSelector(state => state.auth)

  return (
    <Box>
      <AppBar>
        <FlexToolbar>
          <Link to="/">
            <Logo src={require("assets/images/logo.png")} alt='' />
          </Link>
          <RightContent>
            <Menu>
              <MenuItem onClick={() => navigate(`/${user.role}/build-wedding`)}>Categories</MenuItem>
              <MenuItem onClick={() => window.location.replace('https://wedstimate.wixsite.com/wedstimate-blog')}>
                Blog
              </MenuItem>
              <MenuItem onClick={() => navigate(`/contact`)}>Contact</MenuItem>
            </Menu>
            {
              !isAuthenticated ?
                <ButtonGroup>
                  <Button variant='secondary' onClick={() => navigate('/register?type=couple')}>Couples</Button>
                  <Button variant='primary' onClick={() => navigate('/register?type=vendor')}>Vendor</Button>
                </ButtonGroup> :
                <NameToken user={user} />
            }
          </RightContent>
        </FlexToolbar>
      </AppBar>
    </Box>
  );
}