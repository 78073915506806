import React from 'react';
import styled from 'styled-components';
import FormItemWrapper from '../form-item-wrapper';
import { Box, Chip, MenuItem, OutlinedInput, Select, Input } from '@mui/material';
import { critical, textDefault } from 'theme';
import { Typography } from '../typography';

const StyledSelect = styled(Select)`
  width: 100%;
  border-radius:0;

  > div.MuiOutlinedInput {
    padding: ${({ theme }) => `${theme.spacing(0.5)} 0`};
  }
  > div.MuiInputBase-root {
    border-radius:0;
  }

  .MuiInputBase-input {
    padding: 12px 14px;
    font-size: 14px;
  }

  .MuiOutlinedInput-root {
    border-radius:0;
  }
`;

export const FormSelect = ({ label, type = 'text', error, required, disabled, multiple = true, options = [], value = [], ...restProps }) => (
  <FormItemWrapper color={textDefault} required={required} disabled={disabled} label={label}>
    <StyledSelect
      labelId="multiple-chip-label"
      id="multiple-chip"
      multiple={multiple}
      input={<OutlinedInput />}
      renderValue={(selected) => (
        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
          {multiple ? selected.map(value => (
            <Chip key={value} label={options.find(o => o.value === value)?.label} />
          )) :
            options.find(o => o.value === value)?.label
          }
        </Box>
      )}
      value={value}
      {...restProps}
      inputProps={{ variant: 'outlined' }}
    >
      {options.map(({ label, value }) => (
        <MenuItem
          key={label}
          value={value}
        >
          {label}
        </MenuItem>
      ))}
    </StyledSelect>
    {!!error && <Typography color={critical}>{error}</Typography>}
  </FormItemWrapper>
);