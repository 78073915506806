export const VENDOR_STYLES = {
  1: [
    [{ enum: 1, name: 'Barn/Ranch' }, { enum: 3, name: 'Garden' }],
    [{ enum: 4, name: 'Church' }, { enum: 6, name: 'Private Estate' }],
    [{ enum: 7, name: 'Hotel' }, { enum: 9, name: 'Rooftop/Loft' }],
    [{ enum: 10, name: 'Waterfront' }, { enum: 12, name: 'Historic' }],
    [{ enum: 13, name: 'Museum' }, { enum: 15, name: 'Mansion' }],
    [{ enum: 16, name: 'Country Club' }, { enum: 18, name: 'Nature' }],
    [{ enum: 2, name: 'Beach' }, { enum: 8, name: 'Vineyard' }],
    [{ enum: 17, name: 'All Inclusive' }, { enum: 11, name: 'Banquet Hall' }],
    [{ enum: 5, name: 'Restaurant' }, { enum: 14, name: 'Boat' }],
  ],
  2: [
    [{ enum: 47, name: 'Film noir' }, { enum: 48, name: 'Natural' }],
    [{ enum: 49, name: 'Photojournalistic' }, { enum: 50, name: 'Vintage' }],
    [{ enum: 51, name: 'Rustic' }, { enum: 52, name: 'Glamorous' }],
    [{ enum: 53, name: 'Modern' }, { enum: 54, name: 'Bohemian' }],
    [{ enum: 55, name: 'Classic' }, { enum: 56, name: 'Vintage' }],
    [{ enum: 57, name: 'Whimsical' }, { enum: 58, name: 'Romantic' }],
    [{ enum: 59, name: 'Botanical' }, { enum: 60, name: 'Industrial' }],
  ],
  3: [
    [{ enum: 30, name: 'Barbeque' }, { enum: 39, name: 'Asian' }],
    [{ enum: 31, name: 'German' }, { enum: 40, name: 'French' }],
    [{ enum: 32, name: 'Italian' }, { enum: 41, name: 'Indian' }],
    [{ enum: 33, name: 'Mediterranean' }, { enum: 42, name: 'Middle Eastern' }],
    [{ enum: 34, name: 'American' }, { enum: 43, name: 'Seafood' }],
    [{ enum: 35, name: 'Caribbean' }, { enum: 44, name: 'Southern' }],
    [{ enum: 36, name: 'Greek' }, { enum: 45, name: 'Spanish' }],
    [{ enum: 37, name: 'Latin' }, { enum: 46, name: 'Halal' }],
    [{ enum: 38, name: 'Mexican' }, { enum: '', name: '' }],
  ],
  5: [
    { enum: 19, name: 'Country' },
    { enum: 20, name: 'Top 40' },
    { enum: 21, name: 'Electronic' },
    { enum: 22, name: 'Hip Hop' },
    { enum: 23, name: 'Indie' },
    { enum: 24, name: 'International' },
    { enum: 25, name: 'Oldies' },
    { enum: 26, name: 'R&B/Soul' },
    { enum: 27, name: 'Neo Soul' },
    { enum: 28, name: 'Rock' },
    { enum: 29, name: 'Jazz' },
  ],
  6: [
    [
      { enum: 61, name: 'Natural' },
      { enum: 62, name: 'Clean' },
      { enum: 63, name: 'Unexpected' },
    ],
    [
      { enum: 64, name: 'Organic' },
      { enum: 65, name: 'Quality' },
      { enum: 66, name: 'Elegant' },
    ],
    [
      { enum: 67, name: 'Romantic' },
      { enum: 68, name: 'Creative' },
      { enum: 69, name: 'Modern' },
    ],
    [
      { enum: 70, name: 'Lush' },
      { enum: 71, name: 'Custom' },
      { enum: '', name: '' },
    ],
  ],
  7: [
    [
      { enum: 72, name: 'Pre-wedding cut' },
      { enum: 73, name: 'Pre-wedding color' },
      { enum: 74, name: 'Trial/Engagement hair + makeup' },
    ],
    [
      { enum: 75, name: 'Bachelorette party glam' },
      { enum: 76, name: 'Rehearsal/event hair + makeupBridal hair' },
      { enum: 77, name: 'Individual lashes' },
    ],
    [
      { enum: 78, name: 'Clip in extensions' },
      { enum: 79, name: 'Second look hair + makeup' },
      { enum: 80, name: 'Touch-up before ceremony or reception' },
    ],
    [
      {
        enum: 81,
        name:
          'All day beauty team (making sure you, your look and your dress look perfect all day)',
      },
      { enum: '', name: '' },
      { enum: '', name: '' },
    ],
  ],
  9: [
    [
      { enum: 90, name: 'Custom' },
      { enum: 91, name: 'Tiered' },
      { enum: 92, name: 'Fresh Baked' },
    ],
    [
      { enum: 93, name: 'Standard cakes' },
      { enum: 94, name: 'Cupcakes' },
      { enum: 95, name: 'Individual desserts' },
    ],
    [
      { enum: 96, name: 'Cookies and Bars' },
      { enum: 97, name: 'Pastries' },
      { enum: 98, name: 'Scones' },
    ],
    [
      { enum: 99, name: 'Ice Cream' },
      { enum: 100, name: 'Fresh Fruit' },
      { enum: 101, name: 'Gluten-Free' },
    ],
    [
      { enum: 102, name: 'Nut-Free' },
      { enum: 103, name: 'Vegan' },
      { enum: 104, name: 'Eggless' },
    ],
  ],
  10: [
    [
      { enum: 105, name: 'Styling Consultations' },
      { enum: 106, name: 'Designer Brands' },
      { enum: 107, name: 'Tuxedos' },
    ],
    [
      { enum: 108, name: 'Bridal Gowns' },
      { enum: 109, name: 'Bridesmaids gowns/dresses' },
      { enum: 110, name: 'Shoes' },
    ],
    [
      { enum: 111, name: 'Ties' },
      { enum: 112, name: 'Accessories' },
      { enum: '', name: '' },
    ],
  ],
  11: [
    [
      { enum: 113, name: 'Film noir' },
      { enum: 114, name: 'Photojournalistic' },
      { enum: 115, name: 'Glamorous' },
    ],
    [
      { enum: 116, name: 'Modern' },
      { enum: 117, name: 'Cinematic' },
      { enum: 118, name: 'Romantic' },
    ],
  ],
  12: [
    [
      { enum: 157, name: '7 Passenger SUVs' },
      { enum: 158, name: '9-15 Passenger Limos' },
      { enum: 159, name: '1-18 Passenger Limo Buses' },
    ],
    [
      { enum: 160, name: '26 passenger Buses' },
      { enum: 161, name: 'Limousines' },
      { enum: 162, name: 'Luxury Cars' },
    ],
    [
      { enum: 163, name: 'Exotic Cars' },
      { enum: 164, name: 'Hourly rates' },
      { enum: 165, name: 'Day rates' },
    ],
  ],
  13: [
    [
      { enum: 119, name: 'Setup / Break Down included' },
      {
        enum: 120,
        name: 'Professional Full Time Booth Attendant for entire event',
      },
      { enum: 121, name: 'Unlimited Double Pictures { enum: b/w or color)' },
    ],
    [
      { enum: 122, name: 'Customized Prints' },
      { enum: 123, name: 'Customized Welcome Screen' },
      {
        enum: 124,
        name:
          'Customized Booth Graphics highlighting the event on external LCD monitor',
      },
    ],
    [
      { enum: 125, name: 'A Signature Memory Guest Book' },
      { enum: 126, name: 'CD of all images from event' },
      { enum: 127, name: 'Online hosting' },
    ],
    [
      { enum: 128, name: 'Props' },
      { enum: 129, name: 'Unlimited photos' },
      { enum: '', name: '' },
    ],
  ],
  14: [
    [
      { enum: 130, name: 'Live Vocals' },
      { enum: 131, name: 'String ensembles' },
      { enum: 132, name: 'Novel Instruments, Accordian, Bag Pipes, etc' },
    ],
    [
      { enum: 133, name: 'Brass ensembles' },
      { enum: 134, name: 'Rock ensembles' },
      { enum: 135, name: 'Acoustic guitars ensembles' },
    ],
    [
      { enum: 136, name: 'Keyboards' },
      { enum: 137, name: 'Cover Bands' },
      { enum: 138, name: 'Soloists' },
    ],
    [
      { enum: 139, name: 'Ensembles' },
      { enum: 140, name: 'Harps' },
      { enum: 141, name: 'Folk' },
    ],
    [
      { enum: 142, name: 'Jazz/Swing' },
      { enum: 143, name: 'Dixie' },
      { enum: 144, name: 'Popular' },
    ],
    [
      { enum: 145, name: 'Gospel' },
      { enum: '', name: '' },
      { enum: '', name: '' },
    ],
  ],
  15: [
    [
      { enum: 146, name: 'Invitation Design' },
      { enum: 147, name: 'Calligraphy' },
      { enum: 148, name: 'Embossing' },
    ],
    [
      { enum: 149, name: 'Engraving' },
      { enum: 150, name: 'Letterpress' },
      { enum: 151, name: 'Printing' },
    ],
    [
      { enum: 152, name: 'Custom samples' },
      { enum: 153, name: 'Chalk' },
      { enum: 154, name: 'On-site Artwork' },
    ],
    [
      { enum: 155, name: 'Seating charts' },
      { enum: 156, name: 'Guest Books' },
      { enum: '', name: '' },
    ],
  ],
};

export const VENDOR_SERVICE = {
  1: [
    [{ enum: 1, name: 'Bar Service' }, { enum: '', name: 'Event Rentals' }],
    [{ enum: 5, name: 'Pet Friendly' }, { enum: 2, name: 'Clean-up' }],
    [{ enum: '', name: 'Service Staff' }, { enum: 3, name: 'WIFI' }],
    [{ enum: '', name: 'Desserts' }, { enum: '', name: 'Rental & Equipment' }],
    [
      { enum: 4, name: 'Day of Coordination' },
      { enum: 4, name: 'Allow Outside Vendors' },
    ],
  ],

  3: [
    [
      { enum: 6, name: 'Breakfast/Brunch' },
      { enum: '', name: 'Cocktail Reception' },
    ],
    [{ enum: 7, name: 'Hor d’Oeuvres' }, { enum: 11, name: 'Stations' }],
    [{ enum: 8, name: 'Buffet' }, { enum: 12, name: 'Custom Menu' }],
    [{ enum: 9, name: 'Plated' }, { enum: 13, name: 'Tastings' }],
    [{ enum: '', name: 'Cake Cutting' }, { enum: 14, name: 'Bar Services' }],
    [{ enum: 10, name: 'Servers' }, { enum: '', name: '' }],
  ],
  4: [
    [
      { enum: 15, name: 'Day of Coordination' },
      { enum: 16, name: 'Full Wedding Planning' },
    ],
    [
      { enum: 17, name: 'Partial Planning' },
      { enum: 18, name: 'Consultation' },
    ],
    [
      { enum: 19, name: 'Event Design' },
      { enum: 20, name: 'Time-Line Management' },
    ],
  ],
  5: [
    [
      { enum: 4, name: 'Multiple Set-ups' },
      { enum: 5, name: 'Microphones' },
      { enum: 6, name: 'MC Services' },
    ],
    [
      { enum: 7, name: 'Caremony' },
      { enum: 8, name: 'Reception' },
      { enum: 9, name: 'Portraits' },
    ],
  ],
  6: [
    { enum: 21, name: 'Full Event Design' },
    { enum: 22, name: 'Day-of-Event Set-up' },
    { enum: 23, name: 'Custom Design' },
    { enum: 24, name: 'Floral Preservation' },
    { enum: 25, name: 'Consultations' },
    { enum: 26, name: 'Samples' },
    { enum: 27, name: 'Processing & Storage' },
    { enum: 28, name: 'Delivery & Set-up' },
    { enum: 29, name: 'Clean-up' },
  ],
  8: [
    { enum: 30, name: 'Commitment-free estimates' },
    { enum: 31, name: 'Delivery and Pickup' },
    {
      enum: 32,
      name: 'Setup and Breakdown of furniture and Larger equipment',
    },
    { enum: 33, name: 'Free site inspections' },
    { enum: 34, name: 'CAD event layout design' },
    { enum: 35, name: 'Permits' },
  ],
};

export const VENDOR_CONSIDERATIONS = {
  1: [
    [
      { enum: 1, name: 'Indoor' },
      { enum: 2, name: 'Outdoor' },
      { enum: 3, name: 'Shaded Outdoor' },
    ],
  ],
  2: [
    [
      { enum: 4, name: 'Additional Hours/# of Hours' },
      { enum: 5, name: 'Boudoir Shoot' },
    ],
    [
      { enum: 6, name: 'Destination/Travel' },
      { enum: 7, name: 'Engagement Shoot' },
    ],
    [{ enum: 8, name: 'Second Shooter' }, { enum: 9, name: 'Portraits' }],
  ],
  3: [
    [{ enum: 10, name: 'Gluten-free' }, { enum: 15, name: 'Vegetarian' }],
    [{ enum: 11, name: 'Lactose-free' }, { enum: 16, name: 'Kosher' }],
    [{ enum: 12, name: 'Nut-Free' }, { enum: '', name: 'Sugar-Free' }],
    [{ enum: 13, name: 'Organic' }],
    [{ enum: 14, name: 'Vegan' }],
  ],
  6: [
    [
      { enum: 17, name: 'Ceremony Arrangements' },
      { enum: 18, name: 'Reception Centerpieces' },
      { enum: 19, name: 'Bouquets' },
    ],
    [
      { enum: 20, name: 'Corsages/Boutonnieres' },
      { enum: '', name: '' },
      { enum: '', name: '' },
    ],
  ],
  8: [
    [
      { enum: 21, name: 'Tents' },
      { enum: 22, name: 'Bar' },
      { enum: 23, name: 'China and Chargers' },
    ],
    [
      { enum: 24, name: 'Flatware' },
      { enum: 25, name: 'Glassware' },
      { enum: 26, name: 'Lighting' },
    ],
    [
      { enum: 27, name: 'Dancefloors/Staging' },
      { enum: 28, name: 'Linens' },
      { enum: 29, name: 'Heating' },
    ],
    [
      { enum: 30, name: 'Lounge Furniture' },
      { enum: 31, name: 'Seating' },
      { enum: 32, name: 'Tables' },
    ],
    [
      { enum: 33, name: 'Wedding Décor' },
      { enum: 34, name: 'Outdoor Equipment' },
      { enum: 35, name: 'Vintage Furniture & Accessories' },
    ],
    [
      { enum: 36, name: 'Modular Building Blocks' },
      { enum: 37, name: 'Projection Screens' },
      { enum: '', name: '' },
    ],
  ],
  11: [
    [
      { enum: 38, name: 'Additional Hours/# of Hours' },
      { enum: 39, name: 'Live Streaming Services' },
      { enum: 40, name: 'Consultation' },
    ],
    [
      { enum: 41, name: 'Creative Camera offering Glider, Slider, Gimbal & *Drone Coverage' },
      { enum: 42, name: 'Cinematographers with High-Quality HD Cameras' },
      // { enum: 43, name: 'Creative Camera offering Glider, Slider, Gimbal & *Drone Coverage' },
    ],
    [
      {
        enum: 44,
        name:
          'Enhanced Audio System to ensure the capture of high-quality audio',
      },
      { enum: 45, name: 'Highlight Feature Film approx. 20 minutes in length' },
      { enum: 46, name: 'Digital HD Quality Delivery' },
    ],
  ],
};

// Vendor Mapping for displaying data on Dashboard's signle service detail

export const VENDOR_MAPPING_STYLES = {
  1: [
    { enum: 1, name: 'Barn/Ranch' },
    { enum: 2, name: 'Beach' },
    { enum: 3, name: 'Garden' },
    { enum: 4, name: 'Church' },
    { enum: 5, name: 'Restaurant' },
    { enum: 6, name: 'Private Estate' },
    { enum: 7, name: 'Hotel' },
    { enum: 8, name: 'Vineyard' },
    { enum: 9, name: 'Rooftop/Loft' },
    { enum: 10, name: 'Waterfront' },
    { enum: 11, name: 'Banquet Hall' },
    { enum: 12, name: 'Historic' },
    { enum: 13, name: 'Museum' },
    { enum: 14, name: 'Boat' },
    { enum: 15, name: 'Mansion' },
    { enum: 16, name: 'Country Club' },
    { enum: 17, name: 'All Inclusive' },
    { enum: 18, name: 'Nature' },
  ],
  2: [
    { enum: 47, name: 'Film noir' },
    { enum: 48, name: 'Natural' },
    { enum: 49, name: 'Photojournalistic' },
    { enum: 50, name: 'Vintage' },
    { enum: 51, name: 'Rustic' },
    { enum: 52, name: 'Glamorous' },
    { enum: 53, name: 'Modern' },
    { enum: 54, name: 'Bohemian' },
    { enum: 55, name: 'Classic' },
    { enum: 56, name: 'Vintage' },
    { enum: 57, name: 'Whimsical' },
    { enum: 58, name: 'Romantic' },
    { enum: 59, name: 'Botanical' },
    { enum: 60, name: 'Industrial' },
  ],
  3: [
    { enum: 30, name: 'Barbeque' },
    { enum: 31, name: 'German' },
    { enum: 32, name: 'Italian' },
    { enum: 33, name: 'Mediterranean' },
    { enum: 34, name: 'American' },
    { enum: 35, name: 'Caribbean' },
    { enum: 36, name: 'Greek' },
    { enum: 37, name: 'Latin' },
    { enum: 38, name: 'Mexican' },
    { enum: 39, name: 'Asian' },
    { enum: 40, name: 'French' },
    { enum: 41, name: 'Indian' },
    { enum: 42, name: 'Middle Eastern' },
    { enum: 43, name: 'Seafood' },
    { enum: 44, name: 'Southern' },
    { enum: 45, name: 'Spanish' },
    { enum: 46, name: 'Halal' },
  ],
  5: [
    { enum: 19, name: 'Country' },
    { enum: 20, name: 'Top 40' },
    { enum: 21, name: 'Electronic' },
    { enum: 22, name: 'Hip Hop' },
    { enum: 23, name: 'Indie' },
    { enum: 24, name: 'International' },
    { enum: 25, name: 'Oldies' },
    { enum: 26, name: 'R&B/Soul' },
    { enum: 27, name: 'Neo Soul' },
    { enum: 28, name: 'Rock' },
    { enum: 29, name: 'Jazz' },
  ],
  6: [
    { enum: 61, name: 'Natural' },
    { enum: 65, name: 'Quality' },
    { enum: 68, name: 'Creative' },
    { enum: 62, name: 'Clean' },
    { enum: 66, name: 'Elegant' },
    { enum: 69, name: 'Modern' },
    { enum: 63, name: 'Unexpected' },
    { enum: 67, name: 'Romantic' },
    { enum: 70, name: 'Lush' },
    { enum: 64, name: 'Organic' },
    { enum: 170, name: 'Timeless' },
    { enum: 71, name: 'Custom' },
  ],
  7: [
    { enum: 72, name: 'Pre-wedding cut' },
    { enum: 73, name: 'Pre-wedding color' },
    { enum: 74, name: 'Trial/Engagement hair + makeup' },
    { enum: 75, name: 'Bachelorette party glam' },
    { enum: 76, name: 'Rehearsal/event hair + makeupBridal hair' },
    { enum: 77, name: 'Individual lashes' },
    { enum: 78, name: 'Clip in extensions' },
    { enum: 79, name: 'Second look hair + makeup' },
    { enum: 80, name: 'Touch-up before ceremony or reception' },
    {
      enum: 81,
      name: 'All day beauty team ',
      subText:
        '(making sure you, your look and your dress look perfect all day)',
    },
    {
      enum: 82,
      name: 'Trim touch up',
    },
    { enum: 83, name: 'Groom Hairstyle' },
  ],
  9: [
    { enum: 90, name: 'Custom' },
    { enum: 97, name: 'Donut' },
    { enum: 91, name: 'Tiered' },
    { enum: 99, name: 'Ice Cream' },
    { enum: 92, name: 'Fresh Baked' },
    { enum: 100, name: 'Fresh Fruit' },
    { enum: 93, name: 'Standard cakes' },
    { enum: 101, name: 'Gluten-Free' },
    { enum: 94, name: 'Cupcakes' },
    { enum: 102, name: 'Nut-Free' },
    { enum: 95, name: 'Individual desserts' },
    { enum: 103, name: 'Vegan' },
    { enum: 96, name: 'Cookies and Bars' },
    { enum: 104, name: 'Eggless' },
    { enum: 98, name: 'Scones' },
    { enum: 169, name: 'Other Pastries/Miscellaneous/Candy' },
  ],
  10: [
    { enum: 105, name: 'Styling Consultations' },
    { enum: 106, name: 'Designer Brands' },
    { enum: 107, name: 'Tuxedos' },
    { enum: 108, name: 'Bridal Gowns' },
    { enum: 109, name: 'Bridesmaids gowns/dresses' },
    { enum: 110, name: 'Shoes' },
    { enum: 111, name: 'Ties' },
    { enum: 112, name: 'Accessories' },
    { enum: '', name: '' },
  ],
  11: [
    { enum: 113, name: 'Film noir' },
    { enum: 118, name: 'Romantic' },
    { enum: 115, name: 'Glamorous' },
    { enum: 168, name: 'Lifestyle' },
    { enum: 116, name: 'Modern' },
    { enum: 166, name: 'Classic' },
    { enum: 117, name: 'Cinematic' },
    { enum: 114, name: 'Photojournalistic/Documentarian' },
  ],
  12: [
    { enum: 162, name: 'Luxury Cars' },
    { enum: 166, name: 'Trolly' },
    { enum: 163, name: 'Classic & Exotic Cars' },
    { enum: 164, name: 'Valet Parking' },
    { enum: 165, name: 'Horse & Carriage' },
    { enum: 161, name: 'Limousines & Sedan Services' },
  ],
  13: [
    { enum: 119, name: 'Setup / Break Down included' },
    {
      enum: 120,
      name: 'Professional Full Time Booth Attendant for entire event',
    },
    { enum: 121, name: 'Unlimited Double Pictures { enum: b/w or color)' },
    { enum: 122, name: 'Customized Prints' },
    { enum: 123, name: 'Customized Welcome Screen' },
    {
      enum: 124,
      name:
        'Customized Booth Graphics highlighting the event on external LCD monitor',
    },
    { enum: 125, name: 'A Signature Memory Guest Book' },
    { enum: 126, name: 'CD of all images from event' },
    { enum: 127, name: 'Online hosting' },
    { enum: 128, name: 'Props' },
    { enum: 129, name: 'Unlimited photos' },
    { enum: '', name: '' },
  ],
  14: [
    { enum: 130, name: 'Live Vocals' },
    { enum: 139, name: 'Full Ensembles' },
    { enum: 131, name: 'String ensembles' },
    { enum: 140, name: 'Harps' },
    { enum: 133, name: 'Brass ensembles' },
    { enum: 141, name: 'Folk' },
    { enum: 134, name: 'Rock ensembles' },
    { enum: 142, name: 'Jazz/Swing' },
    { enum: 143, name: 'Dixieland' },
    { enum: 135, name: 'Acoustic guitars ensembles' },
    { enum: 137, name: 'Cover Bands' },
    { enum: 144, name: 'Popular' },
    { enum: 138, name: 'Soloists' },
    { enum: 145, name: 'Gospel' },
    {
      enum: 132,
      name: 'Novel Instruments ',
    },
  ],
  15: [
    { enum: 146, name: 'Invitation Design' },
    { enum: 147, name: 'Calligraphy' },
    { enum: 148, name: 'Embossing' },
    { enum: 149, name: 'Engraving' },
    { enum: 150, name: 'Letterpress' },
    { enum: 151, name: 'Printing' },
    { enum: 152, name: 'Custom samples' },
    { enum: 153, name: 'Chalk' },
    { enum: 154, name: 'On-site Artwork' },
    { enum: 155, name: 'Seating charts' },
    { enum: 156, name: 'Guest Books' },
    { enum: '', name: '' },
  ],
};

export const VENDOR_MAPPING_CONSIDERATIONS = {
  1: [
    { enum: 1, name: 'Indoor' },
    { enum: 2, name: 'Outdoor' },
    { enum: 3, name: 'Shaded Outdoor' },
  ],
  2: [
    { enum: 4, name: 'Additional Hours/# of Hours' },
    { enum: 5, name: 'Boudoir Shoot' },
    { enum: 6, name: 'Destination/Travel' },
    { enum: 7, name: 'Engagement Shoot' },
    { enum: 8, name: 'Second Shooter' },
    { enum: 9, name: 'Portraits' },
  ],
  3: [
    { enum: 10, name: 'Gluten-free' },
    { enum: 15, name: 'Vegetarian' },
    { enum: 11, name: 'Lactose-free' },
    { enum: 16, name: 'Kosher' },
    { enum: 12, name: 'Nut-Free' },
    { enum: 47, name: 'Sugar-Free' },
    { enum: 13, name: 'Organic' },
    { enum: 14, name: 'Vegan' },
  ],
  4: [
    { enum: 48, name: 'Elopements Planning' },
    { enum: 49, name: 'Virtual Planning' },
    { enum: 50, name: 'Proposal Planning' },
    { enum: 51, name: 'A La Carte Planning' },
  ],
  6: [
    { enum: 17, name: 'Ceremony Arrangements' },
    { enum: 18, name: 'Reception Centerpieces' },
    { enum: 19, name: 'Bouquets' },
    { enum: 20, name: 'Corsages/Boutonnieres' },
  ],
  7: [
    { enum: 85, name: 'Mother of the bride/groom hair' },
    { enum: 86, name: 'Mother of the bride/groom makeup' },
    { enum: 87, name: 'Bridesmaid hair' },
    { enum: 88, name: 'Bridesmaid makeup' },
    { enum: 89, name: 'Flower girl hair + lip gloss' },
  ],
  8: [
    { enum: 21, name: 'Tents' },
    { enum: 22, name: 'Bar' },
    { enum: 23, name: 'China and Chargers' },
    { enum: 24, name: 'Flatware' },
    { enum: 25, name: 'Glassware' },
    { enum: 26, name: 'Lighting' },
    { enum: 27, name: 'Dancefloors/Staging' },
    { enum: 28, name: 'Linens' },
    { enum: 29, name: 'Heating' },
    { enum: 30, name: 'Lounge Furniture' },
    { enum: 31, name: 'Seating' },
    { enum: 32, name: 'Tables' },
    { enum: 33, name: 'Wedding Décor' },
    { enum: 34, name: 'Outdoor Equipment' },
    { enum: 35, name: 'Vintage Furniture & Accessories' },
    { enum: 36, name: 'Modular Building Blocks' },
    { enum: 37, name: 'Projection Screens' },
    { enum: '', name: '' },
  ],
  11: [
    { enum: 38, name: 'Additional Hours/# of Hours' },
    { enum: 39, name: 'Live Streaming Services' },
    { enum: 40, name: 'Consultation' },
    { enum: 41, name: 'Creative Camera offering Glider, Slider, Gimbal & *Drone Coverage', },
    { enum: 42, name: 'Cinematographers with High-Quality HD Cameras' },
    // { enum: 43, name: 'Creative Camera offering Glider, Slider, Gimbal & *Drone Coverage', },
    { enum: 44, name: 'Enhanced Audio System to ensure the capture of high-quality audio', },
    { enum: 45, name: 'Highlight Feature Film approx. 20 minutes in length' },
    { enum: 46, name: 'Digital HD Quality Delivery' },
  ],
};

export const VENDOR_MAPPING_SERVICE = {
  1: [
    { enum: 1, name: 'Bar Service' },
    { enum: 43, name: 'Event Rentals' },
    { enum: 5, name: 'Pet Friendly' },
    { enum: 2, name: 'Clean-up' },
    { enum: 40, name: 'Service Staff' },
    { enum: 3, name: 'WIFI' },
    { enum: 41, name: 'Desserts' },
    { enum: 42, name: 'Rental & Equipment' },
    { enum: 4, name: 'Day of Coordination' },
    { enum: 44, name: 'Allow Outside Vendors' },
  ],

  2: [
    { enum: 211, name: 'Additional Hours' },
    { enum: 212, name: 'Wedding Albums' },
    { enum: 213, name: 'Digital Files' },
    { enum: 214, name: 'Social Media Sharing' },
    { enum: 215, name: 'Printed Proof' },
    { enum: 216, name: 'Destination/Travel' },
    { enum: 217, name: 'Drone' },
    { enum: 218, name: 'Engagement Shoot' },
    { enum: 220, name: 'Wedding Albums' },
    { enum: 221, name: 'Printed Enlargements' },
    { enum: 222, name: 'Second Shooter' },
  ],

  3: [
    { enum: 6, name: 'Breakfast/Brunch' },
    { enum: 7, name: 'Hor d’Oeuvres' },
    { enum: 8, name: 'Buffet' },
    { enum: 9, name: 'Plated' },
    { enum: 10, name: 'Servers' },
    { enum: 11, name: 'Stations' },
    { enum: 12, name: 'Custom Menu' },
    { enum: 13, name: 'Tastings' },
    { enum: 14, name: 'Bar Services' },
    { enum: 45, name: 'Cake Cutting' },
    { enum: 14, name: 'Bar Services' },
  ],
  4: [
    { enum: 15, name: 'Day of Coordination' },
    { enum: 16, name: 'Full Wedding Planning' },
    { enum: 17, name: 'Month of Coordination' },
    { enum: 18, name: 'Partial Planning' },
  ],
  5: [
    { enum: 171, name: 'Multiple Set-ups' },
    { enum: 172, name: 'Microphones' },
    { enum: 173, name: 'MC Services' },
    { enum: 174, name: 'Caremony' },
    { enum: 175, name: 'Reception' },
    { enum: 176, name: 'Portraits' },
  ],
  6: [
    { enum: 21, name: 'Full Event Design' },
    { enum: 22, name: 'Day-of-Event Set-up' },
    { enum: 23, name: 'Custom Design' },
    { enum: 24, name: 'Floral Preservation' },
    { enum: 25, name: 'Consultations' },
    { enum: 26, name: 'Samples' },
    { enum: 27, name: 'Processing & Storage' },
    { enum: 28, name: 'Delivery & Set-up' },
    { enum: 29, name: 'Clean-up' },
  ],
  8: [
    { enum: 30, name: 'Commitment-free estimates' },
    { enum: 31, name: 'Delivery and Pickup' },
    {
      enum: 32,
      name: 'Setup and Breakdown of furniture and Larger equipment',
    },
    { enum: 33, name: 'Free site inspections' },
    { enum: 34, name: 'CAD event layout design' },
    { enum: 35, name: 'Permits' },
  ],
  12: [
    { enum: 157, name: '7 Passenger SUVs' },
    { enum: 159, name: '18 Passenger Limo Buses' },
    { enum: 158, name: '15 Passenger Limos' },
    { enum: 160, name: '26 passenger Buses' },
  ],
};
