import React from 'react';
import styled from 'styled-components';
import FormItemWrapper from '../form-item-wrapper';
import { TextField } from '@mui/material';
import { critical, textDefault } from 'theme';
import { Typography } from '../typography';

const StyledTextField = styled(TextField)`
  width: 100%;

  > div.MuiOutlinedInput-multiline {
    padding: ${({ theme }) => `${theme.spacing(0.5)} 0`};
  }
  > div.MuiInputBase-root {
    border-radius:0;
  }

  .MuiInputBase-input {
    padding: 12px 14px;
    font-size: 14px;
  }
`;

export const FormInputText = ({ label, type = 'text', error, required, disabled, ...restProps }) => (
  <FormItemWrapper color={textDefault} required={required} disabled={disabled} label={label}>
    <StyledTextField variant='outlined' type={type} {...restProps} disabled={disabled} />
    {!!error && <Typography color={critical}>{error}</Typography>}
  </FormItemWrapper>
);