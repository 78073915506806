import { Header } from 'components';
import React from 'react';

export const Policy = () => {
  return (
    <>
      <Header />
      <iframe src={require("../../../assets/files/policy.pdf")} style={{ width: '100vw', height: '90vh' }} />
    </>
  );
};

export default Policy;
