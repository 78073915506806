import { CircularProgress, Button as MuiButton } from '@mui/material';
import React from 'react';
import styled, { css } from 'styled-components';
import { critical, primary } from 'theme';

const buttonVariants = {
  primary: {
    border: `1px solid ${primary}`,
    color: 'white',
    backgroundColor: primary,
    backgroundColorHover: primary,
  },
  secondary: {
    border: `1px solid  ${primary}`,
    color: primary,
    backgroundColor: 'white',
    backgroundColorHover: 'white',
  },
  danger: {
    border: `1px solid  ${critical}`,
    color: 'white',
    backgroundColor: critical,
    backgroundColorHover: critical,
  },
};

const ProgressSpinner = styled(CircularProgress)`
  ${({ variant }) =>
    variant &&
    buttonVariants[variant] &&
    css`
      color: ${buttonVariants[variant].color};
    `}
`;

const StyledButton = styled(MuiButton)`
  /* width: 100%; */
  min-width: 120px;
  padding: 8px 24px;
  border-radius: 0;
  ${({ button }) =>
    button &&
    buttonVariants[button] &&
    css`
      border: ${buttonVariants[button].border};
      color: ${buttonVariants[button].color};
      background-color: ${buttonVariants[button].backgroundColor};
      &:hover {
        background-color: ${buttonVariants[button].backgroundColorHover};
      }
      &:hover.Mui-disabled {
        background-color: ${buttonVariants[button].backgroundColor};
      }
      &:disabled {
        color: ${buttonVariants[button].color};
        opacity: 0.5;
      }
    `}

  flex-direction: row;
  align-items: center;
  display: flex;
`;

const ButtonContent = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
`;

export const Button = ({
  children,
  onClick,
  className,
  type,
  variant = 'primary',
  pending,
  disabled,
}) => (
  <StyledButton
    className={className}
    button={variant}
    type={type}
    onClick={onClick}
    disabled={pending || disabled}>
    <ButtonContent>
      {pending && <ProgressSpinner variant={variant} size={18} />}
      {children}
    </ButtonContent>
  </StyledButton>
);
