import { Typography } from 'components/typography'
import React from 'react'
import styled from 'styled-components'
import { primary, textDisabled } from 'theme'

const colors = ['#274860', '#A03535', '#ADB600', '#377519', '#EA6D00']

const Container = styled.div`
display: flex;
flex-direction: column;
padding: 10px 0;
`
const ProgressBar = styled.div`
  display: flex;
  width: 100%;
  gap: 3px;
`

const ProgressItem = styled.div`
  background-color: ${({ color }) => color};;
  height: 60px;
`

const LabelContent = styled.div`
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

const ProgressItemContent = styled.div`
  width: ${({ value }) => `${value}%`};
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`

const ProgressItemLabelContent = styled.div`
  display: inline-flex;
  transform:rotate(-90deg);
  width: 20px;
  flex-direction: column;
  width: 80px;
  margin-bottom: 25px;
  margin-left: -10px;
  overflow: hidden;
`

export const ProgressEstimation = ({ estimations = [] }) => {
  const total = estimations.reduce((a, b) => a + b.value, 0)
  return (
    <Container>
      <ProgressBar>
        {
          estimations.map((e, k) => <ProgressItemContent
            value={(e.value / total) * 100}
            key={`estimation_${k}`}
          >
            {
              ((e.value / total) * 100) > 1 &&
              <ProgressItemLabelContent>
                <Typography color={textDisabled}>{e.name}</Typography>
                <Typography color={textDisabled}>${e.value.toLocaleString()}</Typography>
              </ProgressItemLabelContent>
            }
            <ProgressItem
              color={colors[k]}
            />
          </ProgressItemContent>)
        }
      </ProgressBar>
      <LabelContent>
        <Typography variant='h3' color={primary}>$0.00</Typography>
        <Typography variant='h3' color={primary}>${total}.00</Typography>
      </LabelContent>
    </Container>
  )
}
