import { Header, Typography } from 'components'
import React from 'react'
import styled from 'styled-components'
import { containerWidth, primary, secondary } from 'theme'
import { faFacebook, faInstagram, faTwitter } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const Container = styled.div`
  display: flex;
  margin:auto;
  max-width: ${containerWidth}px;
  margin-top:60px;
  flex-direction: column;
  min-height: calc(100% - 380px);
  margin-bottom: 100px;
`

const Content = styled.div`
  display: flex;
  width: 100%;
`

const Footer = styled.div`
  background-color: ${primary};
  padding: 50px 30px;
`

const Link = styled.a`
  color: ${secondary};
`

// const Features = styled.div`
//   display: flex;
//   flex-direction: column;

//   :first-child {
//     margin-top: 20px;
//   }
// `

const SocialMedias = styled.div`
  gap: 20px;
  display: flex;
  margin-bottom: 15px;
`

const SocialMediaLink = styled.a`
  
`

// const MobileAppIcons = styled.img`
//   height: 40px;
//   cursor: pointer;
// `

// const MobileAppIconsLink = styled.a`
//   text-decoration: none;
// `

// const MobileIcon = styled.div`
//   display: flex;
//   flex-direction: row;
//   gap:20px;
//   margin-left: 0px;
//   margin-top: 10px;
// `

const Image = styled.img`
  width: 70%;
`

const InfoTextContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-left: 20px;
  margin-top: 20px;
`

export const Contact = () => {
  return (
    <>
      <Header />
      <Container>
        <SocialMedias>
          <SocialMediaLink href="https://www.facebook.com/Wedstimate" target="_blank"><FontAwesomeIcon icon={faFacebook} size="2x" color='#000' /></SocialMediaLink>
          <SocialMediaLink href="https://www.instagram.com/wedstimate/" target="_blank" ><FontAwesomeIcon icon={faInstagram} size="2x" color='#000' /></SocialMediaLink>
          <SocialMediaLink href="https://twitter.com/wedstimate" target="_blank"><FontAwesomeIcon icon={faTwitter} size="2x" color='#000' /></SocialMediaLink>
        </SocialMedias>
        <Content>
          <Image src={require("assets/images/contact-us.jpeg")} />
          <InfoTextContent>
            <Typography variant='h3' color={primary}>Contact us</Typography>

            <div>
              <Typography variant='h4' color={primary}>Location</Typography>
              <Typography color={primary}>1389 Jefferson Street Unit D112 <br /> Oakland, CA 94612</Typography>
            </div>

            <div>
              <Typography variant='h4' color={primary}>Call Us</Typography>
              <Typography color={primary}>(415) 656-5535</Typography>
            </div>

            <div>
              <Typography variant='h4' color={primary}>Email Us</Typography>
              <Typography color={primary}>info@wedstimate.com</Typography>
            </div>

          </InfoTextContent>
        </Content>
        {/* <div>
          <Typography>Contact us</Typography>
        </div> */}
        {/* <Typography variant='h3' color={primary}>Wedstimate. Connecting Vendors and Couples to create amazing events.</Typography>
        <div>
          <Features>
            <Typography variant='h5'>Vendors</Typography>
            <ul>
              <li><Typography>Receive 5 high-quality leads per month. Guaranteed</Typography></li>
              <li><Typography>Criteria Matching. Match with couples based on their preferences.</Typography></li>
              <li><Typography>Get featured across our social media channels.</Typography></li>
              <li><Typography>Post deals to differentiate your service and generate more matches</Typography></li>
              <li><Typography>Access all of these features for a fraction of the price of other wedding directories</Typography></li>
            </ul>
          </Features>
          <Features>
            <Typography variant='h5'>Couple</Typography>
            <ul>
              <li><Typography>Get access to the best local vendors</Typography></li>
              <li><Typography>Get access to special pricing and packages</Typography></li>
              <li><Typography>Get tailored lists of wedding professionals especially for you</Typography></li>
              <li><Typography>View your estimated wedding cost on your dashboard</Typography></li>
            </ul>
          </Features>
          <Typography variant='strong'>Download the app and use the service for FREE! (Forever!)</Typography>
          <MobileIcon>
            <MobileAppIconsLink href="https://play.google.com/store/apps/details?id=com.rough_smoke_23947" target="_blank">
              <MobileAppIcons src={require("assets/images/google-play-icon.png")} />
            </MobileAppIconsLink>
            <MobileAppIconsLink href="https://apps.apple.com/us/app/wedstimate-wedding-planner/id1578772552" target="_blank">
              <MobileAppIcons src={require("assets/images/apple-play-icon.png")} />
            </MobileAppIconsLink>
          </MobileIcon>
        </div> */}
      </Container>
      <Footer>
        <Typography color="#ffff">Copyright © 2021 Wedstimate, LLC. All rights reserved.</Typography>
        <Typography color="#ffff"><Link href="tel:+1-415-656-5535">415.656.5535</Link> | <Link href='/privacy-policy/'>Privacy Policy</Link></Typography>
      </Footer>
    </>
  )
}

export default Contact