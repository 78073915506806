import { Button } from '../button'
import styled from 'styled-components'

const ButtonComponent = styled(Button)`
  > button {
    min-width: inherit;
    padding: 12px;
  }
`

export const IconButton = (props) => {
  return <ButtonComponent {...props} variant='secondary' />
} 