import { Button, FormInputText, Typography } from 'components'
import { Formik } from 'formik'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useNavigate, useSearchParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { login } from 'store/auth'
import styled from 'styled-components'
import { breakpoints, primary } from 'theme'
import * as Yup from 'yup'

const validationSchema = Yup.object().shape({
  username: Yup.string().label("Email").email().required(),
  password: Yup.string().label("Password").required(),
});

const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  margin-top: -60px;
  gap:40px;

  @media ${breakpoints.viewport_s_and_down} {
    padding: 12px;
    padding-top: 40px;
    justify-content: flex-start;
    margin-top: 60px;
  }
`

const Form = styled.form`
  display: flex;
  flex-direction:column;
  min-width: 460px;
  gap:20px;

  @media ${breakpoints.viewport_xs_and_down} {
   min-width: 100%;
  }
`

const Logo = styled.img`
  width: 72px;
  height: 64px;
`;

const SpacedButton = styled(Button)`
  flex-direction: column;
  display: flex;
`

const LinkText = styled(Typography)`
  text-align: center;
`

export const Login = () => {
  const { api, isAuthenticated, user } = useSelector(state => state.auth)
  const dispatch = useDispatch()
  const navigation = useNavigate()
  const [searchParams] = useSearchParams()

  useEffect(() => {
    if (isAuthenticated && user?.role) {
      navigation(`/${user.role}/dashboard`);
    }
  }, [isAuthenticated])

  useEffect(() => {
    if (searchParams.get("unauthorized") == "0") {

    }
  }, [])

  return (
    <FormWrapper>
      <Link to="/">
        <Logo src={require("assets/images/logo.png")} alt='' />
      </Link>
      <Typography variant='h2' color={primary}>Sign in</Typography>
      <Formik
        initialValues={{ username: '', password: '' }}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          dispatch(login(values))
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
        }) => (
          <Form onSubmit={handleSubmit}>
            <FormInputText
              label="Email"
              type="email"
              name="username"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.email}
              error={touched.username && errors.username}
            />
            <FormInputText
              label="Password"
              type="password"
              name="password"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.password}
              error={touched.password && errors.password}
            />
            <SpacedButton type="submit" pending={api.loading}>
              Submit
            </SpacedButton>
            <Link to={`/register?type=${searchParams.get('type') ?? 'couple'}`}><LinkText>Register</LinkText></Link>
          </Form>
        )}
      </Formik>
    </FormWrapper>
  )
}
