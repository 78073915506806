import { textDefault, textDisabled } from "./color";

export const typography = {
  fontFamily: 'Product Sans',
  h1: {
    fontFamily: 'Product Sans',
    color: textDefault,
    fontSize: '44px',
    lineHeight: '50px',
    fontWeight: 700,
  },
  h2: {
    fontFamily: 'Product Sans',
    color: textDefault,
    fontSize: '38px',
    lineHeight: '46px',
    fontWeight: 700,
  },
  h3: {
    fontFamily: 'Product Sans',
    color: textDefault,
    fontSize: '24px',
    lineHeight: '38px',
    fontWeight: 600,
  },
  h4: {
    fontFamily: 'Product Sans',
    fontSize: '19px',
    color: textDefault,
    lineHeight: '31px',
    fontWeight: 700,
  },
  h5: {
    color: textDefault,
    fontFamily: 'Product Sans',
    fontSize: '15px',
    lineHeight: '25px',
    fontWeight: 700,
  },
  h6: {
    color: textDefault,
    fontFamily: 'Product Sans',
    fontSize: '12px',
    lineHeight: '21px',
    fontWeight: 700,
  },
  subheading1: {
    fontFamily: 'Product Sans',
    color: textDefault,
    fontSize: '36px',
    lineHeight: '44px',
    fontWeight: 600,
  },
  subheading2: {
    fontFamily: 'Product Sans',
    color: textDefault,
    fontSize: '31px',
    lineHeight: '48px',
    fontWeight: 600,
  },
  subheading3: {
    fontFamily: 'Product Sans',
    color: textDefault,
    fontSize: '24px',
    lineHeight: '38px',
    fontWeight: 600,
  },
  subheading4: {
    fontFamily: 'Product Sans',
    color: textDefault,
    fontSize: '18px',
    lineHeight: '24px',
    fontWeight: 500,
  },
  subheading5: {
    fontFamily: 'Product Sans',
    color: textDefault,
    fontSize: '16px',
    lineHeight: '24px',
    fontWeight: 400,
  },
  paragraph: {
    color: textDefault,
    fontFamily: 'Product Sans',
    fontSize: '14px',
    lineHeight: '20px',
    fontWeight: 400,
  },
  strong: {
    color: textDefault,
    fontFamily: 'Product Sans',
    fontSize: '14px',
    lineHeight: '20px',
    fontWeight: 700,
  },
  label: {
    color: textDefault,
    fontFamily: 'Product Sans',
    fontSize: '14px',
    lineHeight: '20px',
    fontWeight: 500,
  },
  footNote: {
    color: textDisabled,
    fontFamily: 'Product Sans',
    fontSize: '14px',
    lineHeight: '20px',
    fontWeight: 400,
  },
  caption: {
    color: textDefault,
    fontFamily: 'Product Sans',
    fontSize: '12px',
    lineHeight: '16px',
    fontWeight: 400,
  },
  link: {
    color: textDefault,
    fontFamily: 'Product Sans',
    fontSize: '14px',
    lineHeight: '20px',
    fontWeight: 600,
  },
};

export default typography;
