import { httpClient } from "utils";

export function getVendorTypesApi() {
  return httpClient.get(`/vendor-identification/count/`)
}

export function addItemToDashboardApi(item) {
  return httpClient.post(`/dashboard/`, item)
}

export function getPackagesApi() {
  return httpClient.get(`/packages/`)
}

export function getVendorIdentificationsApi(categoryId, query) {
  return httpClient.get(`/vendor-identification/?category=${categoryId}${query}`)
}