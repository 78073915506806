import { Checkbox, CircularProgress, FormControlLabel, Rating } from '@mui/material'
import { Deal, Modal, Preference, Typography } from 'components'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router'
import { useSearchParams } from 'react-router-dom'
import { getDeals } from 'store/auth'
import { addItemToDashboard, getVendorIdentifications } from 'store/couple'
import styled from 'styled-components'
import { breakpoints, containerWidth, primary, secondary, textDisabled } from 'theme'
import { BUSINESSES, PRICING, USER_CONSIDERATIONS, USER_SERVICES, USER_STYLES, VENDORS_CATEGORIES, VENUE_AMENITIES } from 'utils'

const TitleContent = styled.div`
  display: flex;
  box-sizing: border-box;
  margin:auto;
  max-width: ${containerWidth}px;
  min-height: 80px;
  padding:0;
  gap:20px;
  align-items: center;

  @media ${breakpoints.viewport_l_and_down} {
    max-width: 100%;
    padding:0 40px
  }
`

const Image = styled.img`
  width:55px;
  height:40px;
`

const ImageWrapper = styled.div`
  width: 65px;
  height: 65px;
  background-color: ${secondary};
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
`

const SideNavBar = styled.div`
  background-color: #ffff;
  max-width: 215px;
  width: 215px;
  padding: 20px;
`

const MenuContent = styled.div`
  padding:0 5px;
  padding-bottom: 10px;
  margin-top:10px;
  margin-bottom: 20px;
  border-bottom: 1px solid #d6d6d6;
  max-height: 200px;
  overflow-y: auto;
`

const FilteredContent = styled.div`
  width: 100%;
  display:flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap:12px;
`

const Content = styled.div`
  display: flex;
  margin: 0 auto;
  max-width: ${containerWidth}px;
  margin-top: 30px;
  padding-bottom: 50px;
  gap:20px;
`

const ControlLabel = styled(FormControlLabel)`
  color: ${textDisabled};
  .MuiFormControlLabel-label {
    font-size: 14px;
  }
  .MuiCheckbox-root{
    padding:6px;
  }
`

const CircularProgressContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`

const ModalImage = styled.img`
  width: 520px;
  height: 320px;
  margin-bottom:10px;
  object-fit: cover;
`

const FormGroup = styled.div`
  display: flex;
  flex-direction: row;
  gap:10px;
`

const Form = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
`

export const Preferences = () => {

  const dispatch = useDispatch();
  const { vendorIdentifications, vendorIdentificationsApi } = useSelector(s => s.couple)
  const params = useParams()
  const [searchParams] = useSearchParams()

  const [considerationsChecked, setConsiderationsChecked] = useState({})
  const [servicesChecked, setServicesChecked] = useState({})
  const [pricingChecked, setPricingChecked] = useState({})
  const [stylesChecked, setStylesChecked] = useState({})
  const [is_your_businessChecked, setIs_your_businessChecked] = useState({})
  const [amenetiesChecked, setAmenetiesChecked] = useState({})
  const [open, setOpen] = React.useState(false);
  const [selectedVendor, selectVendor] = useState({})
  const [rankings, setRankings] = useState({})

  useEffect(() => {
    dispatch(getDeals())
    const newRankings = {}
    USER_STYLES[5].items.forEach(s => {
      newRankings[s.enum] = 0
    })
    setRankings(newRankings)

  }, [])


  useEffect(() => {
    let query = ""
    if (Object.keys(considerationsChecked).length > 0) {
      let consideration = "&consideration="
      Object.keys(considerationsChecked).map(key => {
        if (considerationsChecked[key]) {
          consideration += key + ","
        }
      })
      consideration = consideration.slice(0, -1)
      query += consideration
    }

    if (Object.keys(servicesChecked).length > 0) {
      let services = "&services="
      Object.keys(servicesChecked).map(key => {
        if (servicesChecked[key]) {
          services += key + ","
        }
      })
      services = services.slice(0, -1)
      query += services
    }

    if (Object.keys(pricingChecked).length > 0) {
      let pricing = "&pricing="
      Object.keys(pricingChecked).map(key => {
        if (pricingChecked[key]) {
          pricing += key + ","
        }
      })
      pricing = pricing.slice(0, -1)
      query += pricing
    }

    if (Object.keys(stylesChecked).length > 0) {
      let styles = "&styles="
      Object.keys(stylesChecked).map(key => {
        if (stylesChecked[key]) {
          styles += key + ","
        }
      })
      styles = styles.slice(0, -1)
      query += styles
    }

    if (Object.keys(is_your_businessChecked).length > 0) {
      let is_your_business = "&is_your_business="
      Object.keys(is_your_businessChecked).map(key => {
        if (is_your_businessChecked[key]) {
          is_your_business += key + ","
        }
      })
      is_your_business = is_your_business.slice(0, -1)
      query += is_your_business
    }

    if (Object.keys(amenetiesChecked).length > 0) {
      let ameneties = "&ameneties="
      Object.keys(amenetiesChecked).map(key => {
        if (amenetiesChecked[key]) {
          ameneties += key + ","
        }
      })
      ameneties = ameneties.slice(0, -1)
      query += ameneties
    }

    if (params.id == 5) {
      query += `&ranking=${JSON.stringify(rankings)}`
    }

    dispatch(getVendorIdentifications({
      category: params.id,
      query
    }))

  }, [considerationsChecked, servicesChecked, pricingChecked, stylesChecked, is_your_businessChecked, amenetiesChecked, rankings])

  const preferenceType = VENDORS_CATEGORIES.find(v => v.enum == params.id)

  const onConsiderationsChecked = (e) => {
    setConsiderationsChecked({
      ...considerationsChecked,
      [e.target.name]: e.target.checked
    })
  }

  const onServicesChecked = (e) => {
    setServicesChecked({
      ...servicesChecked,
      [e.target.name]: e.target.checked
    })
  }

  const onPricingChecked = (e) => {
    setPricingChecked({
      ...pricingChecked,
      [e.target.name]: e.target.checked
    })
  }

  const onStylesChecked = (e) => {
    setStylesChecked({
      ...stylesChecked,
      [e.target.name]: e.target.checked
    })
  }

  const onIs_your_businessChecked = (e) => {
    setIs_your_businessChecked({
      ...is_your_businessChecked,
      [e.target.name]: e.target.checked
    })
  }

  const onAmenetiesChecked = (e) => {
    setAmenetiesChecked({
      ...amenetiesChecked,
      [e.target.name]: e.target.checked
    })
  }

  const handleOpen = (vendor) => {
    selectVendor(vendor)
    setOpen(true)
  };

  const addDeal = (id) => dispatch(addItemToDashboard({ vendor_id: id }))

  const handleRating = (key, value) => {
    setRankings({
      ...rankings,
      [key]: value
    })
  }


  return (
    <div style={{ width: "100%" }}>
      <TitleContent>
        <ImageWrapper>
          <Image src={require(`assets/images/vendor_types/${preferenceType?.image}.png`)} />
        </ImageWrapper>
        <div>
          <Typography variant='h2' color={primary}>{preferenceType?.name}</Typography>
          <Typography variant='footNote'>Set your preferences here</Typography>
        </div>
      </TitleContent>
      <Content>
        <SideNavBar>
          <Typography variant='strong'>Pricing</Typography>
          <MenuContent>
            {PRICING.map(pricing => <div>
              <ControlLabel control={<Checkbox />} label={pricing.name} onChange={onPricingChecked} name={pricing.enum} />
            </div>)}
          </MenuContent>

          {
            USER_CONSIDERATIONS[preferenceType.enum] && <>
              <Typography variant='strong'>{preferenceType.name} Considerations</Typography>
              <MenuContent>
                {USER_CONSIDERATIONS[preferenceType.enum].map(style => <div>
                  <ControlLabel control={<Checkbox />} label={style.name} onChange={onConsiderationsChecked} name={style.enum} />
                </div>)}
              </MenuContent>
            </>
          }

          {
            USER_STYLES[preferenceType.enum] && <>
              <Typography variant='strong'>{USER_STYLES[preferenceType.enum].label}</Typography>
              <Typography variant='caption' color={textDisabled}>{USER_STYLES[preferenceType.enum].subLabel}</Typography>
              <MenuContent>
                {USER_STYLES[preferenceType.enum].items.map(style => <div>
                  <ControlLabel control={style.control !== "star" ? <Checkbox /> : <div style={{ padding: 6 }} />} label={style.name} onChange={onStylesChecked} name={style.enum} />
                  {
                    style.control === "star" &&
                    <Rating name="customized-10" defaultValue={0} max={10} size="small" onChange={(_, value) => handleRating(style.enum, value)} />
                  }
                </div>)}
              </MenuContent>
            </>
          }

          {
            USER_SERVICES[preferenceType.enum] && <>
              <Typography variant='strong'>{preferenceType.name} Services</Typography>
              <MenuContent>
                {USER_SERVICES[preferenceType.enum].map(service => <div>
                  <ControlLabel control={<Checkbox />} label={service.name} onChange={onServicesChecked} name={service.enum} />
                </div>)}
              </MenuContent>
            </>
          }

          {
            preferenceType.enum == 1 && <>
              <Typography variant='strong'>Venue Amenities</Typography>
              <MenuContent>
                {VENUE_AMENITIES.map(amenities => <div>
                  <ControlLabel control={<Checkbox />} label={amenities.name} onChange={onAmenetiesChecked} name={amenities.enum} />
                </div>)}
              </MenuContent>
            </>
          }

          {
            <>
              <Typography variant='strong'>Support Diversity</Typography>
              <MenuContent>
                {BUSINESSES.map(support => <div>
                  <ControlLabel control={<Checkbox />} label={support.name} onChange={onIs_your_businessChecked} name={support.enum} />
                </div>)}
              </MenuContent>
            </>
          }
        </SideNavBar>
        {
          vendorIdentificationsApi.loading ? <CircularProgressContainer> <CircularProgress />
          </CircularProgressContainer> :
            <FilteredContent>
              {vendorIdentifications.length === 0 && <Typography variant='footNote'>Your search did not match any Vendors ☹️</Typography>}
              {
                vendorIdentifications.map(deal => <div>
                  <Preference deal={deal} onClick={handleOpen} addDeal={addDeal} />
                </div>)
              }
            </FilteredContent>
        }
      </Content>

      <Modal
        open={open}
        setModalOpen={setOpen}
      >
        <ModalImage src={selectedVendor.image} />
        <Typography variant='h5'>{selectedVendor.title}</Typography>
        <Typography variant='footNote'>{selectedVendor.description}</Typography>
        <Form>
          <div>
            <FormGroup>
              <Typography variant='footNote'>Name: </Typography>
              <Typography variant='footNote'>{selectedVendor.vendor_name}</Typography>
            </FormGroup>
            <FormGroup>
              <Typography variant='footNote'>Email: </Typography>
              <a href={`mailto:${selectedVendor.vendor_email}`}>
                <Typography variant='footNote'>{selectedVendor.vendor_email}</Typography>
              </a>
            </FormGroup>
            <FormGroup>
              <Typography variant='footNote'>Website: </Typography>
              <a href={selectedVendor.website_url} target="_blank">
                <Typography variant='footNote'>{selectedVendor.website_url}</Typography>
              </a>
            </FormGroup>
          </div>
          {/* <div>
            <Button variant='danger' pending={deleteDashboardItemApi.loading} onClick={() => deleteVendor(selectedVendor.id)}>
              Remove
            </Button>
          </div> */}
        </Form>
      </Modal>

    </div>
  )
}