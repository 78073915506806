import { Typography } from '../typography'
import React from 'react'
import styled from 'styled-components'
import { Button } from '../button'
import { primary } from 'theme'
import { VENDORS_CATEGORIES } from 'utils'

const Container = styled.div`
  width: 260px;
  background-color: ${primary};
  box-shadow: 0 1px 16px 0 rgba(0, 0, 0, 0.15);
  padding: 16px;
  padding-bottom:30px;
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: center;
`
const Content = styled.div`
  margin-top: 35px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
`

const TitleContent = styled.div`
  background-color: #0058A3;
  padding: 10px 20px;
  text-align: center;
  position: absolute;
  top: -20px;
  width: 140px;
  border-radius: 30px;
`

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
  height: 100%;
  align-items:flex-end ;
`
const NoteText = styled(Typography)`
  font-size: 10px;
  color: #ffff;
  opacity: 0.5;
  line-height: 10px;
`
const ContentItem = styled.div`
  margin: 12px 0;
  width: 100%;
`
const CenterContent = styled.div`
  width: 160px;
  text-align: center;
`

export const Package = ({ p, addPackage }) => {
  return (
    <Container key={`package_${p.id}`}>
      <TitleContent>
        <Typography variant='strong' color="#ffff">{p.package_type}</Typography>
      </TitleContent>
      <Content>
        <CenterContent>
          <NoteText>{p.description}</NoteText>
        </CenterContent>
        {
          p.package_vendor.map(vendor => <ContentItem key={`package_vendor_${vendor.id}`}>
            <Typography variant='h5' color="#ffff">{vendor.vendor_identification_title}</Typography>
            <NoteText variant='caption' color="#ffff">{VENDORS_CATEGORIES.find(v => v.enum === vendor.vendor_type).name}</NoteText>
          </ContentItem>)
        }
        <CenterContent>
          <Typography variant='h3' color="#ffff">${p.budget}</Typography>
          <NoteText>*Prices may vary based on time, date, and circumstances.</NoteText>
        </CenterContent>
        <ButtonWrapper>
          <Button variant='secondary' onClick={() => addPackage(p.id)}>
            add to dashboard
          </Button>
        </ButtonWrapper>
      </Content>
    </Container>
  )
}
