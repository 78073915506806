import { Typography, Button } from 'components'
import React from 'react'
import styled from 'styled-components'
import { primary, textDisabled } from 'theme'
import { VENDORS_CATEGORIES } from 'utils'

const Box = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width:200px;
  padding: 20px 10px;
  border: 1px solid ${textDisabled};
`
const Image = styled.img`
  width:90px;
  height: 90px;
  border-radius: 45px;
  margin-bottom: 10px;
`

const SpacedButton = styled(Button)`
  margin-top: 10px;
`

export const SelectedVendor = ({ vendor, openModal }) => {
  return (
    <Box>
      <Image src={vendor.image} />
      <Typography variant='strong' color={primary}>{vendor.title}</Typography>
      <Typography variant='caption' color={textDisabled}>{VENDORS_CATEGORIES.find(v => v.enum === vendor.category)?.name}</Typography>
      <Typography variant='caption' color={textDisabled}>${vendor?.budget}</Typography>
      <SpacedButton variant='secondary' onClick={() => openModal(vendor)}>See Details</SpacedButton>
    </Box>
  )
}
