import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import * as api from 'api/couple'
import { toast } from 'react-toastify';
import { getServerError } from "utils";
import { getDashboard } from "./auth";

export const getVendorTypes = createAsyncThunk(
  "couple/vendorTypes",
  async () => {
    try {
      const response = await api.getVendorTypesApi()
      return response.data
    } catch (err) {
      let errorMsg = getServerError(err.response?.data) || JSON.stringify(err.response || err);
      toast(errorMsg, { type: 'error' })
      throw new Error(err)
    }
  }
)

export const addItemToDashboard = createAsyncThunk(
  "couple/add-item-to-dashboard",
  async (payload, { dispatch }) => {
    try {
      const response = await api.addItemToDashboardApi(payload)
      dispatch(getDashboard())
      toast(response.data.response, { type: 'success' })
      return response.data
    } catch (err) {
      let errorMsg = getServerError(err.response?.data) || JSON.stringify(err.response || err);
      toast(errorMsg, { type: 'error' })
      throw new Error(err)
    }
  }
)

export const getPackages = createAsyncThunk(
  "couple/get-packages",
  async () => {
    try {
      const response = await api.getPackagesApi()
      return response.data
    } catch (err) {
      let errorMsg = getServerError(err.response?.data) || JSON.stringify(err.response || err);
      toast(errorMsg, { type: 'error' })
      throw new Error(err)
    }
  }
)

export const getVendorIdentifications = createAsyncThunk(
  "couple/getVendorIdentifications",
  async ({ category, query }) => {
    try {
      const response = await api.getVendorIdentificationsApi(category, query)
      return response.data
    } catch (err) {
      throw new Error(err)
    }
  }
)

export const initialState = {
  vendorTypesApi: { loading: false, error: null },
  vendorTypes: {},
  packages: [],
  packagesApi: { loading: false, error: null },
  vendorIdentifications: [],
  vendorIdentificationsApi: { loading: false, error: null },
}

export const coupleSlice = createSlice({
  name: "couple",
  initialState: initialState,
  reducers: {},
  extraReducers: {
    [getVendorTypes.pending]: state => {
      state.vendorTypesApi = { loading: true, error: null }
    },
    [getVendorTypes.fulfilled]: (state, action) => {
      state.vendorTypesApi.loading = false
      state.vendorTypes = action.payload
    },
    [getVendorTypes.rejected]: (state, action) => {
      state.vendorTypesApi = { loading: false, error: action.payload }
    },

    [getPackages.pending]: state => {
      state.packagesApi = { loading: true, error: null }
    },
    [getPackages.fulfilled]: (state, action) => {
      state.packagesApi.loading = false
      state.packages = action.payload
    },
    [getPackages.rejected]: (state, action) => {
      state.packagesApi = { loading: false, error: action.payload }
    },

    [getVendorIdentifications.pending]: state => {
      state.vendorIdentificationsApi = { loading: true, error: null }
    },
    [getVendorIdentifications.fulfilled]: (state, action) => {
      state.vendorIdentificationsApi.loading = false
      state.vendorIdentifications = action.payload
    },
    [getVendorIdentifications.rejected]: (state, action) => {
      state.vendorIdentificationsApi = { loading: false, error: action.payload }
    },
  }
})
