import React from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { Typography } from 'components/typography';
import {
  BUSINESSES,
  getMappingVendorConsiderations,
  getMappingVendorServices,
  getMappingVendorStyles,
  VENDORS_CATEGORIES,
} from 'utils';

const Services = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  column-gap: 15px;
  row-gap: 22px;
  margin-top: 20px;
`;

const ServiceBox = styled.div`
  width: ${({ cols }) => `calc(${Math.floor(100 / +cols)}% - 8px)`};

  .header-wrap {
    background-color: #e8f6ff;
    padding: 2px 8px;
    margin-bottom: 12px;
  }
`;

const ChipWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  flex-wrap: wrap;
  padding: 0px;
  gap: 8px;
`;

const Chip = styled(Typography)`
  background: #f7f7f7;
  padding: 6px 10px;
`;

export const VendorServices = ({ cols = 2 }) => {
  const { user } = useSelector((state) => state.auth);

  const { identificationResponse } = useSelector((state) => state.vendor);

  return (
    <Services>
      {/* Pricing */}
      <ServiceBox cols={cols}>
        <div className="header-wrap">
          <Typography variant="subheading4">
            {user.category
              ? `${VENDORS_CATEGORIES[user?.category - 1].name} Pricing`
              : 'Pricing'}
          </Typography>
        </div>

        <ChipWrapper>
          {identificationResponse?.pricing === 101 && (
            <Chip variant="caption">Bargain</Chip>
          )}

          {identificationResponse?.pricing === 102 && (
            <Chip variant="caption">Value</Chip>
          )}

          {identificationResponse?.pricing === 103 && (
            <Chip variant="caption">Average</Chip>
          )}

          {identificationResponse?.pricing === 104 && (
            <Chip variant="caption">Luxury</Chip>
          )}
        </ChipWrapper>
      </ServiceBox>

      {/* Services */}
      {identificationResponse && identificationResponse.services.length > 0 && (
      <ServiceBox cols={cols}>
          <div className="header-wrap">
            <Typography variant="subheading4">
              {user.category
                ? `${VENDORS_CATEGORIES[user?.category - 1].name}\nServices`
                : 'Services'}
            </Typography>
          </div>
          <ChipWrapper>
            {getMappingVendorServices(identificationResponse.category).map(
              (item, i) => {
                return identificationResponse.services.includes(item.enum) ? (
                  <Chip key={i} variant="caption">
                    {item.name}
                  </Chip>
                ) : null;
              },
            )}
          </ChipWrapper>
        </ServiceBox>
      )}

      {/* Considerations */}
      {identificationResponse &&
        identificationResponse.consideration.length > 0 && (
                <ServiceBox cols={cols}>
            <div className="header-wrap">
              <Typography variant="subheading4">
                {user.category
                  ? `${
                      VENDORS_CATEGORIES[user?.category - 1].name
                    } Considerations`
                  : 'Considerations'}
              </Typography>
            </div>
            <ChipWrapper>
              {getMappingVendorConsiderations(
                identificationResponse.category,
              ).map((item, i) => {
                return identificationResponse.consideration.includes(
                  item.enum,
                ) ? (
                  <Chip key={i} variant="caption">
                    {item.name}
                  </Chip>
                ) : null;
              })}
            </ChipWrapper>
          </ServiceBox>
        )}

      {/* Style */}
      {identificationResponse && identificationResponse.styles.length > 0 && (
              <ServiceBox cols={cols}>
          <div className="header-wrap">
            <Typography variant="subheading4">
              {user.category
                ? `${VENDORS_CATEGORIES[user?.category - 1].name} Style`
                : 'Style'}
            </Typography>
          </div>
          <ChipWrapper>
            {getMappingVendorStyles(identificationResponse.category).map(
              (item, i) => {
                return identificationResponse.styles.includes(item.enum) ? (
                  <Chip key={i} variant="caption">
                    {item.name}
                  </Chip>
                ) : null;
              },
            )}
          </ChipWrapper>
        </ServiceBox>
      )}

      {/* Is your business */}
      {identificationResponse &&
        identificationResponse.is_your_business.length > 0 && (
                <ServiceBox cols={cols}>
            <div className="header-wrap">
              <Typography variant="subheading4">Is your business</Typography>
            </div>
            <ChipWrapper>
              {BUSINESSES.map((item, i) => {
                return identificationResponse.is_your_business.includes(
                  item.enum,
                ) ? (
                  <Chip key={i} variant="caption">
                    {item.name}
                  </Chip>
                ) : null;
              })}
            </ChipWrapper>
          </ServiceBox>
        )}
    </Services>
  );
};
