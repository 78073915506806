import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

import { Button, Loader, Typography } from 'components';
import { primary, secondary } from 'theme';
import { getLeadsAmount, getVendorLeads, sendLeadsToEmail } from 'store/vendor';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 30px;
`;

const UniqueLeads = styled.div`
  width: 28%;
`;

const LeadsList = styled.div`
  width: 64%;
`;

const ScrollContainer = styled.div`
  max-height: 350px;
  overflow-y: scroll;
`;

const VendorStats = styled.div`
  margin-top: 20px;
  height: 185px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${primary};

  .mb-12 {
    margin-bottom: 12px;
  }
`;

const LeadItem = styled.div`
  width: 100%;

  div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 34px;

    &:last-of-type {
      background-color: ${secondary};
      padding: 0 10px;
    }
  }
`;

const SendLeadsButton = styled(Button)`
  display: flex;
  justify-content: flex-end;

  &&:not(button) {
    margin-top: 20px;
  }
`;

const UpgradePlanBanner = styled.div`
  display: flex;
  background-color: #f5f5f5;
  padding: 25px 35px;
  margin-top: 35px;

  div {
    width: 50%;

    :nth-child(1) {
      border-right: 1px dashed #979797;
      padding-right: 30px;
    }

    :nth-child(2) {
      padding-left: 30px;
    }
  }
`;

export const Leads = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { user } = useSelector(state => state.auth);
  const {
    leads,
    leadsAmount,
    vendorLeads,
    getVendorLeadsApi,
    sendLeadsToEmailApi,
  } = useSelector(state => state.vendor);

  const itemId = user?.vendor_identification;

  const [data, setData] = useState(false);

  const sendLeadsEmail = () => {
    dispatch(sendLeadsToEmail());
  };

  useEffect(() => {
    if (!itemId) {
      toast('Please create a service first', { type: 'error' });
      navigate('/vendor/dashboard');
      return;
    }
    dispatch(getVendorLeads(itemId));
    dispatch(getLeadsAmount());
  }, [dispatch, itemId, navigate]);

  useEffect(() => {
    if (vendorLeads.length > 0) {
      const data = vendorLeads.filter(item => item.is_purchased === true);
      setData(data);
    }
  }, [vendorLeads]);

  return (
    <Container>
      <UniqueLeads>
        <Typography variant="caption">VENDOR</Typography>
        <Typography variant="h3" color={primary}>
          Your leads
        </Typography>

        <VendorStats>
          <Typography className="mb-12" variant="h1" color="#fff">
            {leads.unique_visitors}
          </Typography>
          <Typography color="#fff">Unique Visitors</Typography>
        </VendorStats>
      </UniqueLeads>

      <LeadsList>
        {getVendorLeadsApi.loading && vendorLeads.length === 0 ? (
          <Loader />
        ) : (
          <>
            <ScrollContainer>
              {data &&
                data.map((detail, i) => (
                  <LeadItem key={detail.id}>
                    <div>
                      <Typography>{detail?.name}</Typography>
                      <Typography variant="caption">
                        {new Date(detail.wedding_date)
                          .toDateString()
                          .replace(/^\S+\s/, '')}
                      </Typography>
                    </div>
                    <div>
                      <Typography
                        as="a"
                        title={`Send mail to ${detail?.email}`}
                        href={`mailto:${detail?.email}`}
                        variant="caption">
                        {detail?.email}
                      </Typography>
                      <Typography variant="caption">
                        {detail?.zip_code}
                      </Typography>
                    </div>
                  </LeadItem>
                ))}
            </ScrollContainer>

            {/* <SendLeadsButton
              type="button"
              onClick={sendLeadsEmail}
              pending={sendLeadsToEmailApi.loading}>
              Send Leads to Email
            </SendLeadsButton> */}

            {user.subscription === 'basic' && (
              <UpgradePlanBanner>
                <div>
                  <Typography variant="h5" color="#124670">
                    {`Purchase All Leads $${leadsAmount?.leadAmount?.amount ??
                      0}`}
                  </Typography>
                  <Typography variant="caption">
                    For the rest of the month view all of your leads and create
                    deals to drive couples to your profile.
                  </Typography>
                </div>

                <div>
                  <Typography variant="h5" color="#124670">
                    {`Upgrade your plan $${
                      leadsAmount.packageAmount
                        ? leadsAmount?.packageAmount[1]?.price
                        : 0
                    }`}
                  </Typography>
                  <Typography variant="caption">
                    Upgrade to the Platinum plan to view all leads all the time.
                  </Typography>
                </div>
              </UpgradePlanBanner>
            )}
          </>
        )}
      </LeadsList>
    </Container>
  );
};
