import axios from "axios"
import { globalOptions } from "./globalOptions";

const SERVICE_URL = globalOptions.api // your app back-end url

export const httpClient = axios.create({
  baseURL: SERVICE_URL,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  }
})

export const braintreeHttpClient = axios.create({
  baseURL: globalOptions.brainTreeURL,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  }
})

export const setAuthToken = (token) => {
  httpClient.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  // braintreeHttpClient.defaults.headers.common["Authorization"] = `Bearer ${token}`;

}

export default httpClient;