import { configureStore, createReducer, combineReducers } from "@reduxjs/toolkit"
import { authSlice, initialState as authState } from './auth'
import { coupleSlice, initialState as coupleState } from './couple'
import { vendorSlice, initialState as vendorState } from './vendor'


export const getStore = (globalState) => {
  const appReducer = createReducer(globalState, _ => {
    return globalState
  })

  const reducer = combineReducers({
    app: appReducer,
    auth: authSlice.reducer,
    couple: coupleSlice.reducer,
    vendor: vendorSlice.reducer
  })

  const rootReducer = (rootState, action) => {
    if (action.type === 'auth/logOut') {
      if (rootState) {
        rootState = {
          ...authState,
          couple: {
            ...coupleState,
            vendorIdentifications: rootState.couple.vendorIdentifications
          },
          ...vendorState
        }
      }
    }
    return reducer(rootState, action);
  };

  return configureStore({
    reducer: rootReducer,
    middleware: getDefaultMiddleware => getDefaultMiddleware()
  })
}