import { httpClient } from "utils";

export function getDealsRequest() {
  return httpClient.get(`/deals/`)
}

export function searchRequest(query) {
  return httpClient.get(`/search/${query}`)
}

export function signUpRequest(user) {
  return httpClient.post(`/signup/`, user)
}

export function loginRequest(user) {
  return httpClient.post(`/token/login/`, user)
}

export function getDashboardRequest() {
  return httpClient.get(`/dashboard/`)
}

export function deleteDashboardItemApi(id) {
  return httpClient.delete(`/dashboard/${id}/`)
}

export function updateUserRequest(user) {
  return httpClient.post(`/user-update/`, user)
}