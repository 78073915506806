import { Typography, VendorType } from 'components'
import React, { useEffect } from 'react'
import { primary } from 'theme'
import styled from 'styled-components'
import { VENDORS_CATEGORIES } from 'utils'
import { useDispatch, useSelector } from 'react-redux'
import { getVendorTypes } from 'store/couple'
import { useNavigate } from 'react-router'

const Content = styled.div`
  margin-top: 30px;
  display:flex;
  flex-direction: row;
  gap:30px;
  flex-wrap: wrap;
`

export const Vendor = () => {
  const { vendorTypes } = useSelector(state => state.couple)
  const dispatch = useDispatch();
  const navigation = useNavigate()

  useEffect(() => {
    dispatch(getVendorTypes())
  }, [])

  return (
    <div>
      <Typography variant='h3' color={primary}>Vendor Types</Typography>
      <Typography>Choose the vendor types you need for your wedding</Typography>
      <Content>
        {
          VENDORS_CATEGORIES.map(vendor => <VendorType
            vendor={{ ...vendor, count: vendorTypes[vendor.enum] }}
            onClick={() => navigation(`/couple/build-wedding/${vendor.enum}`)}
          />)
        }
      </Content>

    </div>
  )
}
