import React from 'react';
import { Navigate, useLocation, useNavigate } from 'react-router';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';

import { primary, textDisabled } from 'theme';
import { Button, Typography } from 'components';
import { addVendorDeal } from 'store/vendor';

const Container = styled.div`
  width: 100%;

  .font-400 {
    font-weight: 400;
  }
`;

const Row = styled.div`
  width: 100%;
  display: flex;
  gap: 20px;
  margin-top: 30px;
  min-height: 310px;
`;

const Details = styled.div`
  width: 40%;
  padding-top: 8px;
  padding-bottom: 15px;
  display: flex;
  flex-direction: column;
`;

const ImageBox = styled.div`
  width: 60%;
  overflow: hidden;

  img {
    width: 100%;
    max-height: 350px;
    object-fit: cover;
    object-position: center;
  }
`;

const NoteText = styled(Typography)`
  color: ${textDisabled};
  line-height: 10px;
  margin-top: 10px;
`;

const PriceContent = styled.div`
  margin-top: 30px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  margin-top: 20px;

  > div,
  button {
    width: 70%;
  }
`;

export const ConfirmDeal = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { user } = useSelector((state) => state.auth);
  const { addVendorDealApi } = useSelector((state) => state.vendor);

  const { data } = location?.state;

  const handleSubmit = () => {
    const formData = new FormData();
    
    const expiryDate = new Date(data.expiry.$d);

    formData.append('title', data.title);
    formData.append('budget', data.budget);
    formData.append(
      'expiry',
      `${expiryDate.getFullYear()}-${
        expiryDate.getMonth() + 1
      }-${expiryDate.getDate()} ${expiryDate.getHours()}:${expiryDate.getMinutes()}`,
    );
    formData.append('description', data.description);
    formData.append('image', data.imageFile);
    formData.append('vendor_identification', user.vendor_identification);

      dispatch(
        addVendorDeal({
          data: formData,
          callback: () => {
            navigate('/vendor/deals');
          },
        }),
      );
  };

  if (!location?.state) {
    return <Navigate to="/vendor/dashboard" />;
  }

  return (
    <Container>
      <Typography variant="caption">DEAL</Typography>
      <Typography variant="h3">{data?.title}</Typography>

      <Row>
        <ImageBox>
          <img height="310px" alt="deal image" src={data?.image} />
        </ImageBox>
        <Details>
          <Typography variant="h2">{data?.title}</Typography>

          <Typography variant="paragraph" className="description">
            {data.description}
          </Typography>

          <PriceContent>
            <Typography
              variant="subheading3"
              className="font-400"
              color={primary}>
              ${data.budget}*
            </Typography>

            <NoteText variant="caption">
              *Prices may vary based on time, date, and circumstances.
            </NoteText>
          </PriceContent>

          <ButtonWrapper>
            <Button pending={addVendorDealApi.loading} onClick={handleSubmit}>
              Confirm Deal
            </Button>
          </ButtonWrapper>
        </Details>
      </Row>
    </Container>
  );
};
