import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import * as api from 'api/vendor';
import { toast } from 'react-toastify';
import { getServerError } from 'utils';
import { updateVendorCategory, updateVendorIdentification } from './auth';

export const getVendorDashboard = createAsyncThunk(
  'vendor/vendor-dashboard',
  async id => {
    try {
      const [response, identificationResponse] = await Promise.all([
        api.getVendorDashboardRequest(id),
        api.getVendorIdentificationByIDRequest(id),
      ]);

      return [response.data, identificationResponse.data];
    } catch (err) {
      let errorMsg =
        getServerError(err.response?.data) ||
        JSON.stringify(err.response || err);
      toast(errorMsg, { type: 'error' });
      throw new Error(err);
    }
  },
);

export const addVendor = createAsyncThunk(
  'vendor/add-vendor',
  async (vendor, thunk) => {
    const { payment, ...rest } = vendor;

    try {
      const response = {};
      const { data } = await api.addVendorIdentificationRequest(rest);
      await api.addPaymentRequest(payment);
      thunk.dispatch(getVendorDashboard(data.id));
      thunk.dispatch(
        updateVendorIdentification({
          vId: data.id,
          subscription: JSON.parse(localStorage.getItem("ccr-plan")),
        }),
      );
      thunk.dispatch(updateVendorCategory(rest.category));

      localStorage.removeItem("ccr-plan");
      return response.data;
    } catch (err) {
      let errorMsg =
        getServerError(err.response?.data) ||
        JSON.stringify(err.response || err);
      toast(errorMsg, { type: 'error' });
      throw new Error(err);
    }
  },
);

export const updateVendor = createAsyncThunk(
  'vendor/update-vendor',
  async vendor => {
    try {
      const response = await api.updateVendorIdentificationRequest(vendor);
      toast('Successfully updated the details', { type: 'success' });
      return response.data;
    } catch (err) {
      let errorMsg =
        getServerError(err.response?.data) ||
        JSON.stringify(err.response || err);
      toast(errorMsg, { type: 'error' });
      throw new Error(err);
    }
  },
);

export const getVendorDeals = createAsyncThunk(
  'vendor/getVendorDeals',
  async () => {
    try {
      const response = await api.getVendorDealsRequest();

      return response.data;
    } catch (err) {
      let errorMsg =
        getServerError(err.response?.data) ||
        JSON.stringify(err.response || err);
      toast(errorMsg, { type: 'error' });
      throw new Error(err);
    }
  },
);

export const addVendorDeal = createAsyncThunk(
  'vendor/addVendorDeal',
  async ({ data, callback }) => {
    try {
      const response = await api.vendorDealsRequest(data);

      callback();
      toast('Your deal has been added successfully', { type: 'success' });
      return response.data;
    } catch (err) {
      let errorMsg =
        getServerError(err.response?.data) ||
        JSON.stringify(err.response || err);
      toast(errorMsg, { type: 'error' });
      throw new Error(err);
    }
  },
);

export const deleteVendorDeal = createAsyncThunk(
  'vendor/deleteVendorDeal',
  async ({ id, callback }) => {
    try {
      const response = await api.deleteVendorDealRequest(id);

      callback();
      toast('Your deal has been deleted successfully', {
        type: 'success',
      });

      return response.data;
    } catch (err) {
      let errorMsg =
        getServerError(err.response?.data) ||
        JSON.stringify(err.response || err);
      toast(errorMsg, { type: 'error' });
      throw new Error(err);
    }
  },
);

export const getLeadsAmount = createAsyncThunk(
  'vendor/getLeadsAmount',
  async () => {
    try {
      const [response, packageAmounts, tokenResponse] = await Promise.all([
        api.getLeadsAmountRequest(),
        api.getPlansRequest(),
        api.getTokenRequest(),
      ]);

      const res = {
        leadAmount: response.data,
        token: tokenResponse.data.braintree_client_token,
        packageAmount: packageAmounts.data,
      };
      return res;
    } catch (err) {
      let errorMsg =
        getServerError(err.response?.data) ||
        JSON.stringify(err.response || err);
      toast(errorMsg, { type: 'error' });
      throw new Error(err);
    }
  },
);

export const getVendorLeads = createAsyncThunk(
  'vendor/getVendorLeads',
  async id => {
    try {
      const response = await api.getVendorLeadsRequest(id);

      return response.data;
    } catch (err) {
      let errorMsg =
        getServerError(err.response?.data) ||
        JSON.stringify(err.response || err);
      toast(errorMsg, { type: 'error' });
      throw new Error(err);
    }
  },
);

export const sendLeadsToEmail = createAsyncThunk(
  'vendor/sendLeadsToEmail',
  async () => {
    try {
      const response = await api.sendLeadsToEmailRequest();

      toast('Leads successfully emailed to your email.', {
        type: 'success',
      });
      return response.data;
    } catch (err) {
      let errorMsg =
        getServerError(err.response?.data) ||
        JSON.stringify(err.response || err);
      toast(errorMsg || 'Unable to send Email, please try again.', {
        type: 'error',
      });
      throw new Error(err);
    }
  },
);

export const getPlans = createAsyncThunk('vendor/getPlans', async () => {
  try {
    const [response, token] = await Promise.all([
      api.getPlansRequest(),
      api.getTokenRequest(),
    ]);
    return {
      plans: response.data,
      token: token.data,
    };
  } catch (err) {
    throw new Error(err);
  }
});

export const unsubscription = createAsyncThunk(
  'vendor/unsubscription',
  async () => {
    try {
      const response = await api.unsubscriptionRequest();

      toast(
        response.data.response ||
          'Subscription has been canceled successfully.',
        {
          type: 'success',
        },
      );
      return response.data;
    } catch (err) {
      let errorMsg =
        getServerError(err.response?.data) ||
        JSON.stringify(err.response || err);
      toast(errorMsg, { type: 'error' });
      throw new Error(err);
    }
  },
);

export const initialState = {
  leads: {},
  leadsAmount: {},
  vendorDeals: [],
  vendorLeads: [],
  getVendorDashboardApi: { loading: false, error: null },
  addVendorApi: { loading: false, error: null, success: false },
  identificationResponse: null,
  getVendorDealsApi: { loading: false, error: null },
  addVendorDealApi: { loading: false, error: null },
  deleteVendorDealApi: {},
  getVendorLeadsApi: { loading: false, error: null },
  sendLeadsToEmailApi: { loading: false, error: null },
  plans: [],
  plansApi: { loading: false, error: null },
  unsubscriptionApi: { loading: false, error: null },
};

export const vendorSlice = createSlice({
  name: 'vendor',
  initialState: initialState,
  reducers: {
    resetAddVendors: state => {
      state.addVendorApi.success = false;
    },
  },
  extraReducers: {
    [getVendorDashboard.pending]: state => {
      state.getVendorDashboardApi = { loading: true, error: null };
    },
    [getVendorDashboard.fulfilled]: (state, action) => {
      state.getVendorDashboardApi.loading = false;
      state.leads = action.payload[0];
      state.identificationResponse = action.payload[1];
    },
    [getVendorDashboard.rejected]: (state, action) => {
      state.getVendorDashboardApi = { loading: false, error: action.payload };
    },
    [addVendor.pending]: state => {
      state.addVendorApi = { loading: true, error: null, success: false };
    },
    [addVendor.fulfilled]: (state, action) => {
      state.addVendorApi.loading = false;
      state.addVendorApi.success = true;
      state.identificationResponse = action.payload;
    },
    [addVendor.rejected]: (state, action) => {
      state.addVendorApi = {
        loading: false,
        error: action.payload,
        success: false,
      };
    },
    [updateVendor.pending]: state => {
      state.addVendorApi = { loading: true, error: null, success: false };
    },
    [updateVendor.fulfilled]: (state, action) => {
      state.addVendorApi.loading = false;
      state.identificationResponse = action.payload;
    },
    [updateVendor.rejected]: (state, action) => {
      state.addVendorApi = {
        loading: false,
        error: action.payload,
        success: false,
      };
    },

    [getVendorDeals.pending]: state => {
      state.getVendorDealsApi = { loading: true, error: null };
    },
    [getVendorDeals.fulfilled]: (state, action) => {
      state.getVendorDealsApi.loading = false;
      state.vendorDeals = action.payload;
    },
    [getVendorDeals.rejected]: (state, action) => {
      state.getVendorDealsApi = {
        loading: false,
        error: action.payload,
      };
    },

    [addVendorDeal.pending]: state => {
      state.addVendorDealApi = { loading: true, error: null };
    },
    [addVendorDeal.fulfilled]: state => {
      state.addVendorDealApi.loading = false;
    },
    [addVendorDeal.rejected]: (state, action) => {
      state.addVendorDealApi = {
        loading: false,
        error: action.payload,
      };
    },

    [deleteVendorDeal.pending]: (state, { meta: { arg } }) => {
      state.deleteVendorDealApi[arg.id] = { loading: true, error: null };
    },
    [deleteVendorDeal.fulfilled]: (state, { meta: { arg } }) => {
      state.deleteVendorDealApi[arg.id].loading = false;
    },
    [deleteVendorDeal.rejected]: (state, action) => {
      state.deleteVendorDealApi = {
        loading: false,
        error: action.payload,
      };
    },

    [getLeadsAmount.pending]: state => {
      state.getLeadsAmountApi = { loading: true, error: null };
    },
    [getLeadsAmount.fulfilled]: (state, action) => {
      state.getLeadsAmountApi.loading = false;
      state.leadsAmount = action.payload;
    },
    [getLeadsAmount.rejected]: (state, action) => {
      state.getLeadsAmountApi = {
        loading: false,
        error: action.payload,
      };
    },
    [unsubscription.pending]: state => {
      state.unsubscriptionApi = { loading: true, error: null };
    },
    [unsubscription.fulfilled]: (state, action) => {
      state.unsubscriptionApi.loading = false;
    },
    [unsubscription.rejected]: (state, action) => {
      state.unsubscriptionApi = {
        loading: false,
        error: action.payload,
      };
    },
    [getVendorLeads.pending]: state => {
      state.getVendorLeadsApi = { loading: true, error: null };
    },
    [getVendorLeads.fulfilled]: (state, action) => {
      state.getVendorLeadsApi.loading = false;
      state.vendorLeads = action.payload;
    },
    [getVendorLeads.rejected]: (state, action) => {
      state.getVendorLeadsApi = {
        loading: false,
        error: action.payload,
      };
    },

    [sendLeadsToEmail.pending]: state => {
      state.sendLeadsToEmailApi = { loading: true, error: null };
    },
    [sendLeadsToEmail.fulfilled]: (state, action) => {
      state.sendLeadsToEmailApi.loading = false;
    },
    [sendLeadsToEmail.rejected]: (state, action) => {
      state.sendLeadsToEmailApi = {
        loading: false,
        error: action.payload,
      };
    },

    [getPlans.pending]: state => {
      state.plansApi = { loading: true, error: null };
      state.token = null;
    },
    [getPlans.fulfilled]: (state, action) => {
      state.plansApi.loading = false;
      state.plans = action.payload.plans;
      state.token = action.payload.token.braintree_client_token;
    },
    [getPlans.rejected]: (state, action) => {
      state.plansApi = {
        loading: false,
        error: action.payload,
      };
    },
  },
});

export const { resetAddVendors } = vendorSlice.actions;
