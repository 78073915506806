import { jsBreakpoints } from './jsBreakpoints';

export const breakpoints = {
  viewport_xs_and_down: `(max-width: ${jsBreakpoints.XSAndDown.maxWidth}px)`,
  viewport_s_and_down: `(max-width: ${jsBreakpoints.SAndDown.maxWidth}px)`,
  viewport_m_and_down: `(max-width: ${jsBreakpoints.MAndDown.maxWidth}px)`,
  viewport_l_and_down: `(max-width: ${jsBreakpoints.LAndDown.maxWidth}px)`,
  viewport_xl_and_down: `(max-width: ${jsBreakpoints.XLAndDown.maxWidth}px)`,

  viewport_s_only: `(min-width: ${jsBreakpoints.SOnly.minWidth}px) and (max-width: ${jsBreakpoints.SOnly.maxWidth}px)`,
  viewport_m_only: `(min-width: ${jsBreakpoints.MOnly.minWidth}px) and (max-width: ${jsBreakpoints.MOnly.maxWidth}px)`,
  viewport_l_only: `(min-width: ${jsBreakpoints.LOnly.minWidth}px) and (max-width: ${jsBreakpoints.LOnly.maxWidth}px)`,

  viewport_xs_and_up: `(min-width: ${jsBreakpoints.XSAndUp.minWidth}px)`,
  viewport_s_and_up: `(min-width: ${jsBreakpoints.SAndUp.minWidth}px)`,
  viewport_m_and_up: `(min-width: ${jsBreakpoints.MAndUp.minWidth}px)`,
  viewport_l_and_up: `(min-width: ${jsBreakpoints.LAndUp.minWidth}px)`,
  viewport_xl_and_up: `(min-width: ${jsBreakpoints.XLAndUp.minWidth}px)`,
  viewport_xxl_and_up: `(min-width: ${jsBreakpoints.XXLAndUp.minWidth}px)`,
};