import React from 'react';
import styled from 'styled-components';

import { Typography } from '../typography';
import { primary, textDisabled } from 'theme';
import { Button } from 'components/button';

const Container = styled.div`
  width: 315px;
  background-color: #ffff;
  cursor: pointer;
  border: 1px solid #023866;
  transition: 0.3s ease;

  :hover {
    background-color: #f5f5f5;
    border: 1px solid #f5f5f5;
  }
`;

const Content = styled.div`
  padding: 16px;
  gap: 8px;
  display: flex;
  flex-direction: column;

  p.description {
    word-break: break-word;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 12px;

  > div,
  button {
    width: 100%;
  }
`;

const NoteText = styled(Typography)`
  font-size: 9px;
  color: ${textDisabled};
  line-height: 10px;
  margin-top: 6px;
`;

const PriceContent = styled.div`
  margin-top: 6px;
`;

export const VendorCurrentDeal = ({ deal, deleteDeal, pending }) => {
  return (
    <Container key={`deal_${deal.id}`}>
      <Content>
        <Typography variant="h5" color={primary}>
          {deal.title}
        </Typography>

        <Typography variant="caption" className="description">
          {deal.description.slice(0, 100)}...
        </Typography>
        <PriceContent>
          <Typography variant="subheading4" color={primary}>
            ${deal.budget}*
          </Typography>
          <NoteText>
            *Prices may vary based on time, date, and circumstances.
          </NoteText>
        </PriceContent>
        <ButtonWrapper>
          <Button pending={pending} variant="secondary" onClick={() => deleteDeal(deal.id)}>
            Delete
          </Button>
        </ButtonWrapper>
      </Content>
    </Container>
  );
};
