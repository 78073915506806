import { Typography } from '../typography'
import React from 'react'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faStar } from '@fortawesome/free-solid-svg-icons'
import { Button } from '../button'
import { primary } from 'theme'
import { useNavigate } from 'react-router'

const Container = styled.div`
  width: 220px;
  background-color: #ffff;
`
const Image = styled.img`
  height: 170px;
  width: 220px;
  object-fit: cover;
`
const Content = styled.div`
  padding: 16px;
  gap: 8px;
  display: flex;
  flex-direction: column;
  margin-top: -10px;
`
const CaptionContent = styled.div`
  display: flex;
  flex-direction: column;
`
const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 10px;
`

export const Vendor = ({ deal, className }) => {
  const navigate = useNavigate()
  return (
    <Container className={className}>
      <Image src={deal.image} />
      <Content>
        <Typography variant='strong'>{deal.vendor_name}</Typography>
        <CaptionContent>
          {/* <FontAwesomeIcon icon={faStar} color={primary} size='lg' /> */}
          {/* <Typography>4.9</Typography> */}
          <Typography>{deal.title?.slice(0, 30)}</Typography>
          {/* <Typography variant='footNote'>${deal.budget}</Typography> */}
        </CaptionContent>
        <ButtonWrapper>
          <Button variant='secondary' onClick={() => navigate("/couple/deals")}>
            View Deals
          </Button>
        </ButtonWrapper>
      </Content>
    </Container>
  )
}
