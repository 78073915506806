import React from 'react';
import styled from 'styled-components';
import typography from 'theme/typography';

const StyledText = styled.p`
  margin: 0;
  ${({ variant }) => `font-size: ${typography[variant].fontSize};`}
  ${({ variant }) => `font-weight: ${typography[variant].fontWeight};`}
  ${({ color, variant }) => `color: ${color || typography[variant].color};`}
  ${({ display }) => `display: ${display};`}
  ${({ variant }) => `line-height: ${typography[variant].lineHeight};`}
  ${({ variant }) => `font-family: ${typography[variant].fontFamily};`}
  text-transform: ${({ upperCase }) => upperCase && 'uppercase'};
`;


export const Typography = ({ variant = 'paragraph', color, display = 'block', ...props }) => (
  <StyledText variant={variant} display={display} color={color} {...props} />
);