import React from 'react'
import styled from 'styled-components'
import { Modal as MuiModal } from '@mui/material'
import { Typography } from 'components'

const CustomModal = styled(MuiModal)`
  display: flex;
  justify-content: center;
  align-items: center;
`

const ModalContent = styled.div`
  padding: 20px;
  background-color: #ffff;
  width: 520px;
  position: relative;
`

const ModalImage = styled.img`
  width: 520px;
  height: 320px;
  margin-bottom:10px;
  object-fit: cover;
`

const ModalFooter = styled.div`
  margin-top: 10px;
`

const CloseButton = styled.button`
  position: absolute;
  width: 30px;
  height: 30px;
  min-width: inherit;
  border-radius: 30px;
  right: -10px;
  top:-10px;
  background-color: black;
  border:1.5px solid #ffff;
  cursor: pointer;
`

export const Modal = ({ open, setModalOpen, children }) => {
  return (
    <CustomModal
      open={open}
      onClose={() => setModalOpen(false)}
    >
      <ModalContent>
        <CloseButton onClick={() => setModalOpen(false)}><Typography color="#ffff">X</Typography></CloseButton>
        <ModalFooter>
          {children}
        </ModalFooter>
      </ModalContent>
    </CustomModal>
  )
}
