import { Button, FormDatePicker, FormInputText, ProgressEstimation, SelectedVendor, Typography, Modal } from 'components'
import { PieChart } from 'components'
import { Formik } from 'formik'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { deleteDashboardItem, getDashboard, updateUser } from 'store/auth'
import styled from 'styled-components'
import { breakpoints, primary } from 'theme'
import { VENDORS_CATEGORIES } from 'utils'
import * as Yup from 'yup'

const colors = ['#274860', '#A03535', '#ADB600', '#377519', '#EA6D00', '#008b8b', '#a9a9a9', '#006400', '#bdb76b', '#8b008b', '#556b2f', '#ff8c00', '#9932cc', '#8b0000', '#e9967a', '#9400d3', '#ffd700', '#008000', '#4b0082', '#f0e68c', '#add8e6', '#e0ffff', '#90ee90', '#d3d3d3', '#ffb6c1', '#ffffe0', '#00ff00', '#ff00ff', '#800000', '#000080', '#808000', '#ffa500', '#800080', '#800080', '#ff0000', '#c0c0c0', '#ffff00',]

const validationSchema = Yup.object().shape({
  wedding_zip_code: Yup.string().label("Wedding Zip Code").required(),
  wedding_date: Yup.date().label("Wedding date").required(),
  num_of_guest: Yup.number().label("Number of guests").required(),
});

const Container = styled.div`
 width: 100%;
`

const StatsWidget = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 30px;
`

const Box = styled.div`
  width: 150px;
  text-align: center;
  ${({ showBorder }) => showBorder && `border-right: 1px dotted;`}
`
const BoxWrapper = styled.div`
  display: flex;
  flex-direction: row;
  padding: 15px;
  ${({ transparent }) => !transparent && `background-color: #E8F6FF;`}
`

const BoxWrapperContent = styled.div`
  display: flex;
  flex-direction: row;
  gap:10px;
`
const EditButton = styled(Button)`
  height: 100%;
`

const Content = styled.div`
  margin-top: 30px;
  display:flex;
  flex-direction: row;
  gap:30px;
  flex-wrap: wrap;
`

const ModalImage = styled.img`
  width: 520px;
  height: 320px;
  margin-bottom:10px;
  object-fit: cover;
`

const FormGroup = styled.div`
  display: flex;
  flex-direction: row;
  gap:10px;
`

const Form = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
`

const ModalForm = styled.form`
  display: flex;
  flex-direction:column;
  min-width: 460px;
  gap:20px;

  @media ${breakpoints.viewport_s_and_down} {
   min-width: inherit;
  }
`

const SpacedButton = styled(Button)`
  flex-direction: column;
  display: flex;
`

export const Dashboard = () => {
  const [estimations, setEstimations] = useState([])
  const [totalBudget, setTotalBudget] = useState(0)
  const [open, setOpen] = React.useState(false);
  const [editModalOpen, setEditModalOpen] = React.useState(false);
  const [selectedVendor, selectVendor] = useState({})
  const { user, vendors, deleteDashboardItemApi, deleteDashboardItemApiSuccess, updateUserApi } = useSelector(state => state.auth)
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getDashboard())
  }, [])

  useEffect(() => {
    let budget = 0
    const result = vendors.reduce(function (r, a) {
      const category = VENDORS_CATEGORIES.find(v => v.enum === a.category)?.name
      if (a.category === 3) {
        r[category] = r[category] || 0;
        r[category] = r[category] + (a.budget * user.num_of_guest);
      } else {
        r[category] = r[category] || 0;
        r[category] = r[category] + a.budget;
      }
      return r;
    }, Object.create(null));

    const entries = Object.keys(result).map((k, index) => ({ name: k, value: result[k], fill: colors[index] || "#8884d8" }))
    Object.keys(result).forEach((k) => { budget = budget + result[k] })
    setEstimations(entries)
    setTotalBudget(budget)

  }, [vendors])

  useEffect(() => {
    if (deleteDashboardItemApiSuccess && !deleteDashboardItemApi.loading) {
      setOpen(false)
    }
  }, [deleteDashboardItemApiSuccess, deleteDashboardItemApi.loading])

  const handleOpen = (vendor) => {
    selectVendor(vendor)
    setOpen(true)
  };
  const handleClose = () => setOpen(false);

  const deleteVendor = (id) => dispatch(deleteDashboardItem(id))

  return (
    <>
      <Container>
        <StatsWidget>
          <BoxWrapper transparent>
            <div>
              <Typography variant='h2' color={primary}>${totalBudget.toFixed(2)}</Typography>
              <Typography>Estimated Event Cost</Typography>
            </div>
          </BoxWrapper>
          <BoxWrapperContent>
            <BoxWrapper>
              <Box showBorder>
                <Typography variant='h3' color={primary}>{user.wedding_zip_code}</Typography>
                <Typography>Zip Code</Typography>
              </Box>
              <Box showBorder>
                <Typography variant='h3' color={primary}>{user.wedding_date}</Typography>
                <Typography>Date of Event</Typography>
              </Box>
              <Box>
                <Typography variant='h3' color={primary}>{user.num_of_guest}</Typography>
                <Typography># of Guests</Typography>
              </Box>
            </BoxWrapper>
            <EditButton variant='secondary' onClick={() => setEditModalOpen(true)}>Edit</EditButton>
          </BoxWrapperContent>
        </StatsWidget>
        {/* <ProgressEstimation estimations={estimations} /> */}
        {/* <div style={{ display: 'flex', width: '100%' }}> */}
        <PieChart data={estimations} />
        {/* </div> */}
        <Content>
          {
            vendors.map(vendor => <SelectedVendor vendor={vendor} openModal={handleOpen} />)
          }
        </Content>
      </Container>
      <Modal
        open={open}
        setModalOpen={handleClose}
      >
        <ModalImage src={selectedVendor.image} />
        <Typography variant='h5'>{selectedVendor.title}</Typography>
        <Typography variant='footNote'>{selectedVendor.description}</Typography>
        <Form>
          <div>
            <FormGroup>
              <Typography variant='footNote'>Name: </Typography>
              <Typography variant='footNote'>{selectedVendor.vendor_name}</Typography>
            </FormGroup>
            <FormGroup>
              <Typography variant='footNote'>Email: </Typography>
              <a href={`mailto:${selectedVendor.vendor_email}?subject=New Lead from Wedstimate.com&body=Hello! We found your wedding business on Wedstimate and are interested in potentially hiring your services for our wedding. Could you please send us information on your packages, pricing, and a potential time for a consultation? Thank You!`}>
                <Typography variant='footNote'>{selectedVendor.vendor_email}</Typography>
              </a>
            </FormGroup>
            <FormGroup>
              <Typography variant='footNote'>Website: </Typography>
              <a href={selectedVendor.website_url} target="_blank">
                <Typography variant='footNote'>{selectedVendor.website_url}</Typography>
              </a>
            </FormGroup>
          </div>
          <div>
            <Button variant='danger' pending={deleteDashboardItemApi.loading} onClick={() => deleteVendor(selectedVendor.id)}>
              Remove
            </Button>
          </div>
        </Form>
      </Modal>

      <Modal
        open={editModalOpen}
        setModalOpen={setEditModalOpen}
      >
        <Formik
          initialValues={{ wedding_date: user.wedding_date, wedding_zip_code: user.wedding_zip_code, num_of_guest: user.num_of_guest }}
          validationSchema={validationSchema}
          enableReinitialize={true}
          onSubmit={(values) => {
            const { wedding_date, ...rest } = values
            dispatch(updateUser({
              user: {
                ...rest,
                wedding_date: new Date(wedding_date).toISOString().split("T")[0]
              },
              callback: () => setEditModalOpen(false)
            }))
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            setFieldValue,
            setFieldTouched
          }) => (
            <ModalForm onSubmit={handleSubmit}>
              <FormInputText
                label="No of Guests"
                type="number"
                name="num_of_guest"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.num_of_guest}
                error={touched.num_of_guest && errors.num_of_guest}
              />
              <FormDatePicker
                label="Wedding Date"
                type="text"
                name="wedding_date"
                onChange={(e) => setFieldValue("wedding_date", e)}
                value={values.wedding_date}
                error={touched.wedding_date && errors.wedding_date}
              />
              <FormInputText
                label="Wedding Zip Code"
                type="text"
                name="wedding_zip_code"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.wedding_zip_code}
                error={touched.wedding_zip_code && errors.wedding_zip_code}
              />
              <SpacedButton type="submit" pending={updateUserApi.loading}>
                Submit
              </SpacedButton>
            </ModalForm>
          )}
        </Formik>
      </Modal>

    </>
  )
}
