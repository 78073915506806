import { createTheme } from "@mui/material/styles";
import { critical, primary, primaryDisabled, secondary, textDefault } from "./color";
import typography from "./typography";

export const theme = createTheme({
  palette: {
    primary: {
      main: primary,
    },
    secondary: {
      main: secondary
    },
    error: {
      main: critical,
    },
    action: {
      disabledBackground: primaryDisabled,
    },
  },
  typography: {
    fontFamily: typography.fontFamily,
  },
  components: {
    MuiMenuItem: {
      styleOverrides: {
        root: {
          color: textDefault,
          fontSize: 14
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontSize: 12
        }
      }
    }
  }
})