import { Typography, Button } from 'components'
import React from 'react'
import styled from 'styled-components'
import { primary, secondary, textDisabled } from 'theme'

const Box = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width:200px;
  padding: 20px 10px;
  border: 1px solid ${textDisabled};
  background-color: ${({ selected }) => selected ? primary : 'initial'};
`
const Image = styled.img`
  width:70px;
  height: 55px;
`

const ImageWrapper = styled.div`
  width: 100px;
  height: 100px;
  background-color: ${secondary};
  border-radius: 55px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 6px;
  padding: 5px;
`

const SpacedButton = styled(Button)`
  margin-top: 10px;
`

export const VendorType = ({ vendor, onClick, onSelected, showButton = true, selected = false }) => {
  return (
    <Box selected={selected} onClick={onSelected}>
      <ImageWrapper>
        <Image src={require(`assets/images/vendor_types/${vendor.image}.png`)} />
      </ImageWrapper>
      <Typography variant='strong' color={selected ? "#ffff" : primary}>{vendor.name}</Typography>
      <Typography variant='caption' color={selected ? "#ffff" : textDisabled}>{vendor.count} {vendor.name} in your zip</Typography>
      {showButton && <SpacedButton variant='secondary' onClick={onClick}>Search</SpacedButton>}
    </Box>
  )
}
