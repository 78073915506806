import { Typography } from 'components'
import React from 'react'
import styled from 'styled-components'
import { textDisabled } from 'theme'

const ImageContainer = styled.div`
  background-image: ${({ image }) => `url(${image})`};
  height: 560px;
  width: 280px;
  background-size: cover;
  background-position: center;
  position: relative;
`

const Content = styled.div`
  background-color: #ffff;
  position: absolute;
  bottom: 50px;
  padding: 20px;
  width: 200px;
`

const Link = styled.a`
  text-decoration: none;
`

export const Ceremony = ({ image, eventName, url }) => {
  return (
    <ImageContainer image={image}>
      <Content>
        <Link href={url} target="_blank">
          <Typography variant='h5'>{eventName}</Typography>
        </Link>
        {/* <Typography variant='caption' color={textDisabled}>163-1155, Kogawa</Typography> */}
      </Content>
    </ImageContainer>
  )
}

