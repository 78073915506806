import { Menu, MenuItem } from "@mui/material"
import { Typography } from "components"
import React from "react"
import { useDispatch } from "react-redux"
import { useNavigate } from "react-router"
import { logOut } from "store/auth"
import styled from "styled-components"
import { breakpoints, primary, secondary } from "theme"

const Token = styled.div`
  width: 45px;
  height: 45px;
  border-radius: 25px;
  background-color: ${secondary};
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
  cursor: pointer;
`
const Content = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

const NavMobileMenu = styled.div`
  display: none;
  @media ${breakpoints.viewport_s_and_down} {
    display: block;
  }
`

export const NameToken = ({ user }) => {

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const navigate = useNavigate();
  const dispatch = useDispatch()

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onLogout = () => {
    handleClose()
    localStorage.removeItem("@token")
    dispatch(logOut())
  };


  return (
    <Content>
      <Typography>{user.name}</Typography>
      <Token onClick={handleClick}>
        <Typography variant="h4" color={primary}>{user.name[0]}</Typography>
      </Token>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem onClick={() => navigate(`/${user.role}/dashboard`)}>Dashboard</MenuItem>
        <NavMobileMenu>
          <MenuItem onClick={() => navigate(`/${user.role}/build-wedding`)}>Categories</MenuItem>
          <MenuItem>Blog</MenuItem>
          <MenuItem onClick={() => navigate(`/contact`)}>Contact</MenuItem>
        </NavMobileMenu>
        <MenuItem onClick={onLogout}>Logout</MenuItem>
      </Menu>
    </Content>
  )
}