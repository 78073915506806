import React from 'react';
import styled from 'styled-components';
import { textDefault } from 'theme';
import FormLabel from '../form-label';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const Label = styled(FormLabel)`
  margin-bottom: 12px;
`


export const FormItemWrapper = ({
  label,
  color = textDefault,
  required,
  disabled,
  labelVariant = 'strong',
  children,
}) => (
  <Container>
    {label && <Label color={color} variant={labelVariant} required={required} disabled={disabled} name={label} />}
    {children}
  </Container>
);

export default FormItemWrapper;