export const VENDORS_CATEGORIES = [
  { enum: 1, name: 'Venues', image: 'venues', route: 'VenueDetails' },
  { enum: 2, name: 'Photographers', image: 'photographers', route: 'Photography', },
  { enum: 3, name: 'Caterers', image: 'caterers', route: 'Catering' },
  { enum: 4, name: 'Planners', image: 'planer', route: 'Planning' },
  { enum: 5, name: 'DJ\'s', image: 'dj', route: 'DjScreen' },
  { enum: 6, name: 'Florist', image: 'flowers', route: 'Flowers' },
  { enum: 7, name: 'Hair & Make-up', image: 'makeupartists', route: 'HairMakeUp', },
  { enum: 8, name: 'Event Rentals', image: 'eventrentals', route: 'EventRentals', },
  { enum: 9, name: 'Cakes', image: 'cakes', route: 'CakesAndDeserts' },
  { enum: 10, name: 'Dress & Attire', image: 'attire', route: 'DressAndAttire', },
  { enum: 11, name: 'Videographers', image: 'videographer', route: 'Videographer', },
  { enum: 12, name: 'Transportation', image: 'transportation', route: 'Transportation' },
  { enum: 13, name: 'Photo Booths', image: 'photobooths', route: 'PhotoBooth', },
  { enum: 14, name: 'Live Music', image: 'livemusic', route: 'LiveMusic' },
  { enum: 15, name: 'Invitations', image: 'invitations', route: 'Invitations', },
  // { enum: 16, name: "Coordinator", image: "invitations", route: 'VenueDetails'}
];

export const USER_STYLES = {
  1: {
    label: "Venue Style",
    items: [
      { enum: 1, name: 'Barn/Ranch' },
      { enum: 3, name: 'Garden' },
      { enum: 4, name: 'Church' },
      { enum: 6, name: 'Private Estate' },
      { enum: 7, name: 'Hotel' },
      { enum: 9, name: 'Rooftop/Loft' },
      { enum: 10, name: 'Waterfront' },
      { enum: 12, name: 'Historic' },
      { enum: 13, name: 'Museum' },
      { enum: 15, name: 'Mansion' },
      { enum: 16, name: 'Country Club' },
      { enum: 18, name: 'Nature' },
      { enum: 2, name: 'Beach' },
      { enum: 8, name: 'Vineyard' },
      { enum: 17, name: 'All Inclusive' },
      { enum: 11, name: 'Banquet Hall' },
      { enum: 5, name: 'Restaurant' },
      { enum: 14, name: 'Boat' },
    ]
  },
  5: {
    label: "Rank these genres in order from most to least favorite",
    subLabel: "You must rank each genre and you cannot use rankings twice. You must rank each from your most favorite to least favorite. Most favorite has 10 stars, least favorite has 1 star. All must be ranked.",
    control: "star",
    items: [
      { enum: 20, name: 'Top 40', control: "star" },
      { enum: 21, name: 'Electronic', control: 'star' },
      { enum: 22, name: 'Hip Hop', control: 'star' },
      { enum: 23, name: 'Indie Rock', control: 'star' },
      { enum: 24, name: 'International', control: 'star' },
      { enum: 25, name: 'Oldies', control: 'star' },
      { enum: 26, name: 'R&B/Neo Soul', control: 'star' },
      { enum: 29, name: 'Jazz', control: 'star' },
      { enum: 28, name: 'Rock', control: 'star' },
      { enum: 19, name: 'Country', control: 'star' },
    ],
  },
  3: {
    label: "Cuisine Type",
    items: [
      { enum: 30, name: 'Barbecue' },
      { enum: 39, name: 'Asian' },
      { enum: 31, name: 'German' },
      { enum: 40, name: 'French' },
      { enum: 32, name: 'Italian' },
      { enum: 41, name: 'Indian' },
      { enum: 33, name: 'Mediterranean' },
      { enum: 42, name: 'Middle Eastern' },
      { enum: 34, name: 'American' },
      { enum: 43, name: 'Seafood' },
      { enum: 35, name: 'Caribbean' },
      { enum: 44, name: 'Southern' },
      { enum: 36, name: 'Greek' },
      { enum: 45, name: 'Spanish' },
      { enum: 37, name: 'Latin' },
      { enum: 46, name: 'Halal' },
      { enum: 38, name: 'Mexican' },
    ],
  },
  2: {
    label: "Photography Style",
    items: [
      { enum: 47, name: 'Film noir' },
      { enum: 55, name: 'Classic' },
      { enum: 48, name: 'Natural' },
      { enum: 50, name: 'Vintage' },
      { enum: 49, name: 'Photojournalistic' },
      { enum: 57, name: 'Whimsical' },
      { enum: 56, name: 'Vintage' },
      { enum: 58, name: 'Romantic' },
      { enum: 51, name: 'Rustic' },
      { enum: 59, name: 'Botanical' },
      { enum: 52, name: 'Glamorous' },
      { enum: 60, name: 'Industrial' },
      { enum: 53, name: 'Modern' },
      { enum: 54, name: 'Bohemian' },
    ],
  },
  6: {
    label: "Style",
    items: [
      { enum: 61, name: 'Natural' },
      { enum: 65, name: 'Quality' },
      { enum: 68, name: 'Creative' },
      { enum: 62, name: 'Clean' },
      { enum: 66, name: 'Elegant' },
      { enum: 69, name: 'Modern' },
      { enum: 63, name: 'Unexpected' },
      { enum: 67, name: 'Romantic' },
      { enum: 70, name: 'Lush' },
      { enum: 64, name: 'Organic' },
      { enum: 170, name: 'Timeless' },
      { enum: 71, name: 'Custom' },
    ],
  },
  7: {
    label: "Hair & Make-Up Services",
    items: [
      { enum: 72, name: 'Pre-wedding cut' },
      { enum: 73, name: 'Pre-wedding color' },
      { enum: 74, name: 'Trial/Engagement hair + makeup' },
      { enum: 75, name: 'Bachelorette party glam' },
      { enum: 76, name: 'Rehearsal/event hair + makeupBridal hair' },
      { enum: 77, name: 'Individual lashes' },
      { enum: 78, name: 'Clip in extensions' },
      { enum: 79, name: 'Second look hair + makeup' },
      { enum: 80, name: 'Touch-up before ceremony or reception' },
      { enum: 81, name: 'All day beauty team ', subText: '(making sure you, your look and your dress look perfect all day)' },
    ],
  },
  9: {
    label: "Cake & Dessert Specialties",
    items: [
      { enum: 90, name: 'Custom' },
      { enum: 97, name: 'Donut' },
      { enum: 91, name: 'Tiered' },
      { enum: 99, name: 'Ice Cream' },
      { enum: 92, name: 'Fresh Baked' },
      { enum: 100, name: 'Fresh Fruit' },
      { enum: 93, name: 'Standard cakes' },
      { enum: 101, name: 'Gluten-Free' },
      { enum: 94, name: 'Cupcakes' },
      { enum: 102, name: 'Nut-Free' },
      { enum: 95, name: 'Individual desserts' },
      { enum: 103, name: 'Vegan' },
      { enum: 96, name: 'Cookies and Bars' },
      { enum: 104, name: 'Eggless' },
      { enum: 98, name: 'Scones' },
      { enum: 169, name: 'Other Pastries/Miscellaneous/Candy' },
    ],
  },
  10: {
    label: "Dress & Attire Services",
    items: [
      { enum: 105, name: 'Styling Consultations' },
      { enum: 107, name: 'Tuxedos/Menswear' },
      { enum: 108, name: 'Bridal Gowns' }, { enum: 110, name: 'Shoes' },
      { enum: 109, name: 'Bridesmaids gowns/dresses' },
      { enum: 111, name: 'Ties' },
      { enum: 106, name: 'Designer Brands' },
      { enum: 112, name: 'Accessories' },
    ],
  },
  11: {
    label: "Videography Styles",
    items: [
      { enum: 113, name: 'Film noir' },
      { enum: 118, name: 'Romantic' },
      { enum: 115, name: 'Glamorous' },
      { enum: 168, name: 'Lifestyle' },
      { enum: 116, name: 'Modern' },
      { enum: 166, name: 'Classic' },
      { enum: 117, name: 'Cinematic' },
      { enum: 114, name: 'Photojournalistic/Documentarian' },
    ],
  },
  13: {
    label: "Photo Booth Considerations",
    items: [
      { enum: 119, name: 'Setup / Break Down included' },
      { enum: 120, name: 'Professional Full Time Booth Attendant for entire event' },
      { enum: 121, name: 'Unlimited Double Pictures { enum: b/w or color)' },
      { enum: 122, name: 'Customized Prints' },
      { enum: 123, name: 'Customized Welcome Screen' },
      { enum: 124, name: 'Customized Booth Graphics highlighting the event on external LCD monitor' },
      { enum: 125, name: 'A Signature Memory Guest Book' },
      { enum: 126, name: 'CD of all images from event' },
      { enum: 127, name: 'Online hosting' },
      { enum: 128, name: 'Props' },
      { enum: 129, name: 'Unlimited photos' },
    ],
  },
  14: {
    label: "Live music considerations",
    items: [
      { enum: 130, name: 'Live Vocals' },
      { enum: 139, name: 'Full Ensembles' },
      { enum: 131, name: 'String ensembles' },
      { enum: 140, name: 'Harps' },
      { enum: 133, name: 'Brass ensembles' },
      { enum: 141, name: 'Folk' },
      { enum: 134, name: 'Rock ensembles' },
      { enum: 142, name: 'Jazz/Swing' },
      { enum: 143, name: 'Dixieland' },
      { enum: 135, name: 'Acoustic guitars ensembles' },
      { enum: 137, name: 'Cover Bands' }, { enum: 144, name: 'Popular' },
      { enum: 138, name: 'Soloists' }, { enum: 145, name: 'Gospel' },
      { enum: 132, name: 'Novel Instruments ', subText: '(Accordian, Bag Pipes, etc)' },
    ],
  },
  15: {
    label: "Invitations and Designs",
    items: [
      { enum: 146, name: 'Invitation Design' },
      { enum: 152, name: 'Custom samples' },
      { enum: 147, name: 'Calligraphy' },
      { enum: 153, name: 'Chalk' },
      { enum: 148, name: 'Embossing' },
      { enum: 154, name: 'On-site Artwork' },
      { enum: 149, name: 'Engraving' },
      { enum: 155, name: 'Seating charts' },
      { enum: 150, name: 'Letterpress' },
      { enum: 156, name: 'Guest Books' },
      { enum: 151, name: 'Printing' },
    ],
  },
  12: {
    label: "Transportation modes",
    items: [
      { enum: 162, name: 'Luxury Cars' }, { enum: 166, name: 'Trolly' },
      { enum: 163, name: 'Classic & Exotic Cars' },
      { enum: 164, name: 'Valet Parking' },
      { enum: 165, name: 'Horse & Carriage' },
      { enum: 161, name: 'Limousines & Sedan Services' },
    ],
  }
};

// consideration
export const USER_CONSIDERATIONS = {
  1: [
    { enum: 1, name: 'Indoor' },
    { enum: 2, name: 'Outdoor' },
    { enum: 3, name: 'Shaded Outdoor' },
  ],
  2: [
    { enum: 5, name: 'Boudoir Shoot' },
    { enum: 7, name: 'Engagement Shoot' },
    { enum: 9, name: 'Bridal Portraits' },
    { enum: 6, name: 'Portrait Shoot' },
    { enum: 8, name: 'Day After Session' },
  ],
  3: [
    { enum: 10, name: 'Gluten-free' },
    { enum: 15, name: 'Vegetarian' },
    { enum: 11, name: 'Lactose-free' },
    { enum: 16, name: 'Kosher' },
    { enum: 12, name: 'Nut-Free' },
    { enum: 47, name: 'Sugar-Free' },
    { enum: 13, name: 'Organic' },
    { enum: 14, name: 'Vegan' },
  ],
  4: [
    { enum: 48, name: 'Elopements Planning' },
    { enum: 49, name: 'Virtual Planning' },
    { enum: 50, name: 'Proposal Planning' },
    { enum: 51, name: 'A La Carte Planning' },
  ],
  6: [
    { enum: 17, name: 'Ceremony Arrangements' },
    { enum: 18, name: 'Reception Centerpieces' },
    { enum: 19, name: 'Bouquets' },
    { enum: 20, name: 'Corsages/Boutonnieres' },
  ],
  7: [
    { enum: 85, name: 'Mother of the bride/groom hair' },
    { enum: 86, name: 'Mother of the bride/groom makeup' },
    { enum: 87, name: 'Bridesmaid hair' },
    { enum: 88, name: 'Bridesmaid makeup' },
    { enum: 89, name: 'Flower girl hair + lip gloss' },
  ],
  8: [
    { enum: 21, name: 'Tents', single: false },
    { enum: 27, name: 'Dancefloors/Staging', single: false },
    { enum: 22, name: 'Bar', single: false },
    { enum: 28, name: 'Linens', single: false },
    { enum: 23, name: 'China and Chargers', single: false },
    { enum: 29, name: 'Heating', single: false },
    { enum: 24, name: 'Flatware', single: false },
    { enum: 30, name: 'Lounge Furniture', single: false },
    { enum: 25, name: 'Glassware', single: false },
    { enum: 31, name: 'Seating', single: false },
    { enum: 26, name: 'Lighting', single: false },
    { enum: 32, name: 'Tables', single: false },
    { enum: 33, name: 'Wedding Décor', single: true },
    { enum: 34, name: 'Outdoor Equipment', single: true },
    { enum: 35, name: 'Vintage Furniture & Accessories', single: true },
    { enum: 36, name: 'Modular Building Blocks', single: true },
    { enum: 37, name: 'Projection Screens', single: true },
  ],
  11: [
    { enum: 38, name: 'Additional Hours/# of Hours' },
    { enum: 39, name: 'Live Streaming Services' },
    { enum: 40, name: 'Consultation' },
    { enum: 41, name: 'Creative Camera offering Glider, Slider, Gimbal & *Drone Coverage' },
    { enum: 42, name: 'Cinematographers with High-Quality HD Cameras' },
    // { enum: 43, name: 'Creative Camera offering Glider, Slider, Gimbal & *Drone Coverage' },
    { enum: 44, name: 'Enhanced Audio System to ensure the capture of high-quality audio' },
    { enum: 45, name: 'Highlight Feature Film approx. 20 minutes in length' },
    { enum: 46, name: 'Digital HD Quality Delivery' },
  ],
};

// services
export const USER_SERVICES = {
  1: [
    { enum: 1, name: 'Bar Service' },
    { enum: 43, name: 'Event Rentals' },
    { enum: 5, name: 'Pet Friendly' },
    { enum: 2, name: 'Clean-up' },
    { enum: 40, name: 'Service Staff' },
    { enum: 3, name: 'WIFI' },
    { enum: 41, name: 'Desserts' },
    { enum: 42, name: 'Rental & Equipment' },
    { enum: 4, name: 'Day of Coordination' },
    { enum: 44, name: 'Allow Outside Vendors' },
  ],
  2: [

    { enum: 211, name: 'Additional Hours' },
    { enum: 212, name: 'Wedding Albums' },
    { enum: 213, name: 'Digital Files' },
    { enum: 214, name: 'Social Media Sharing' },
    { enum: 215, name: 'Printed Proof' },
    { enum: 216, name: 'Destination/Travel' },
    { enum: 217, name: 'Drone' },
    { enum: 218, name: 'Engagement Shoot' },
    { enum: 220, name: 'Wedding Albums' },
    { enum: 221, name: 'Printed Enlargements' },
    { enum: 222, name: 'Second Shooter' },
  ],
  3: [
    { enum: 6, name: 'Breakfast/Brunch' },
    { enum: '', name: 'Cocktail Reception' },
    { enum: 7, name: 'Hor d’Oeuvres' },
    { enum: 11, name: 'Stations' },
    { enum: 8, name: 'Buffet' },
    { enum: 12, name: 'Custom Menu' },
    { enum: 9, name: 'Plated' },
    { enum: 13, name: 'Tastings' },
    { enum: 45, name: 'Cake Cutting' },
    { enum: 14, name: 'Bar Services' },
    { enum: 10, name: 'Servers' },
    { enum: '', name: '' },
  ],
  4: [
    { enum: 15, name: 'Day of Coordination' },
    { enum: 16, name: 'Full Wedding Planning' },
    { enum: 17, name: 'Month of Coordination' },
    { enum: 18, name: 'Partial Planning' },
  ],
  5: [
    { enum: 171, name: 'Multiple Set-Ups' },
    { enum: 172, name: 'Microphones' },
    { enum: 173, name: 'MC Services' },
    { enum: 174, name: 'Reception Only' },
    { enum: 175, name: 'Ceremony/Cocktail/Reception' },
  ],
  6: [
    { enum: 21, name: 'Full Event Design' },
    { enum: 22, name: 'Day-of-Event Set-up' },
    { enum: 23, name: 'Custom Design' },
    { enum: 24, name: 'Floral Preservation' },
    { enum: 25, name: 'Consultations' },
    { enum: 26, name: 'Samples' },
    { enum: 27, name: 'Processing & Storage' },
    { enum: 28, name: 'Delivery & Set-up' },
    { enum: 29, name: 'Clean-up' },
  ],
  8: [
    { enum: 30, name: 'Commitment-free estimates' },
    { enum: 31, name: 'Delivery and Pickup' },
    { enum: 32, name: 'Setup and Breakdown of furniture and Larger equipment' },
    { enum: 33, name: 'Free site inspections' },
    { enum: 34, name: 'CAD event layout design' },
    { enum: 35, name: 'Permits' },
  ],
  12: [
    { enum: 157, name: '7 Passenger SUVs' },
    { enum: 159, name: '18 Passenger Limo Buses' },
    { enum: 158, name: '15 Passenger Limos' },
    { enum: 160, name: '26 passenger Buses' },
  ],
};

// Pricing
export const PRICING = [
  { enum: 101, name: 'Bargain' },
  { enum: 102, name: 'Value' },
  { enum: 103, name: 'Average' },
  { enum: 104, name: 'Luxury' },
];

// Businesses
export const BUSINESSES = [
  { enum: 105, name: 'LGBTQ \nFriendly' },
  { enum: 106, name: 'Minority \nOwned' },
  { enum: 107, name: 'Woman \nOwned' },
];

// Amenities
export const VENUE_AMENITIES = [
  { enum: 201, name: 'Ceremony Area' },
  { enum: 202, name: 'Handicap Accessible' },
  { enum: 203, name: 'Indoor Event Space' },
  { enum: 204, name: 'Liability Insurance' },
  { enum: 205, name: 'Dressing Room' },
];

//Those list type includes enum of each vendor categories to show in the preference page
//those not includes will be not showing in the menu
export const stylesScreens = [1, 6, 7, 9, 10, 11, 12, 13, 14, 15];
export const considerationScreens = [1, 6, 7, 8, 11];
export const servicesScreens = [1, 6, 8, 12];
