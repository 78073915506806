import { Header, Typography } from 'components'
import { SecondaryHeader } from 'components'
import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Navigate, useOutlet } from 'react-router'
import { toast } from 'react-toastify'
import styled from 'styled-components'
import { containerWidth, primary, secondary } from 'theme'

const Container = styled.div`
  background-color: #ffff;
  min-height: calc(100% - 275px);
`

const Content = styled.div`
  display: flex;
  margin: 0 auto;
  max-width: ${containerWidth}px;
  background-color: #ffff;
  padding-top: 30px;
  padding-bottom: 50px;
`

const Footer = styled.div`
  background-color: ${primary};
  padding: 50px 30px;
`

const Link = styled.a`
  color: ${secondary};
`

export const PublicMainLayout = () => {
  const outlet = useOutlet();

  return (
    <>
      <Header />
      <SecondaryHeader />
      <Container>
        <Content>
          {outlet}
        </Content>
      </Container>
      <Footer>
        <Typography color="#ffff">Copyright © 2021 Wedstimate, LLC. All rights reserved.</Typography>
        <Typography color="#ffff"><Link href="tel:+1-415-656-5535">415.656.5535</Link> | <Link href='/privacy-policy/'>Privacy Policy</Link></Typography>
      </Footer>
    </>
  )
}
