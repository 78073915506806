import { Deal, Modal, Typography } from 'components'
import React, { useEffect, useState } from 'react'
import { primary } from 'theme'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import { getDeals } from 'store/auth'
import { addItemToDashboard } from 'store/couple'

const Content = styled.div`
  margin-top: 30px;
  display:flex;
  flex-direction: row;
  gap:20px;
  flex-wrap: wrap;
`

const ModalImage = styled.img`
  width: 520px;
  height: 320px;
  margin-bottom:10px;
  object-fit: cover;
`

const FormGroup = styled.div`
  display: flex;
  flex-direction: row;
  gap:10px;
`

const Form = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
`

export const Deals = () => {

  const { deals } = useSelector(state => state.auth)
  const dispatch = useDispatch()
  const [open, setOpen] = useState(false);
  const [selectedDeal, selectDeal] = useState({})


  useEffect(() => {
    dispatch(getDeals())
  }, [])

  const addDeal = (id) => dispatch(addItemToDashboard({ deal: id }))

  const handleOpen = (vendor) => {
    selectDeal(vendor)
    setOpen(true)
  };

  return (
    <div>
      <Typography variant='h3' color={primary}>Find your deals</Typography>
      <Typography>Select from the varieties of packages</Typography>
      <Content>
        {deals.map(deal => <Deal deal={deal} addDeal={addDeal} onClick={handleOpen} />)}
      </Content>

      <Modal
        open={open}
        setModalOpen={setOpen}
      >
        <ModalImage src={selectedDeal.image} />
        <Typography variant='h5'>{selectedDeal.title}</Typography>
        <Typography variant='footNote'>{selectedDeal.description}</Typography>
        <Typography variant='footNote'>{selectedDeal.budget}</Typography>
      </Modal>
    </div>
  )
}
