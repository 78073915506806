import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { CircularProgress } from '@mui/material';

import { Typography } from 'components';
import { VendorDeal } from 'components/deal/VendorDeal';
import { getVendorDeals } from 'store/vendor';

const Container = styled.div`
  width: 100%;
`;

const CircularProgressContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 300px;
`;

const Content = styled.div`
  margin-top: 30px;
  display: flex;
  flex-direction: row;
  gap: 30px;
  flex-wrap: wrap;
`;

export const Deals = () => {
  const { vendorDeals, getVendorDealsApi } = useSelector(
    (state) => state.vendor,
  );
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getVendorDeals());
  }, []);


  return (
    <Container>
      <Typography variant="caption">FIND</Typography>
      <Typography variant="h3">Your Deals</Typography>

      {getVendorDealsApi.loading && vendorDeals.length === 0 ? (
        <CircularProgressContainer>
          <CircularProgress />
        </CircularProgressContainer>
      ) : (
        <Content>
          {vendorDeals.map((deal) => (
            <VendorDeal key={deal.id} deal={deal} addDeal={() => {}} />
          ))}
        </Content>
      )}
    </Container>
  );
};
