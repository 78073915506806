import React, { Suspense, useContext, useEffect } from 'react';
import { ThemeProvider } from 'styled-components';
import { ThemeProvider as MuiThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import { theme } from 'theme';
import { Loader } from 'components';
import { httpClient, Routes, setAuthToken } from 'utils';
import { Provider, useDispatch } from 'react-redux';
import { getStore } from 'store';
import { globalOptions, GlobalOptionsContext } from 'utils/globalOptions';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { getDashboard } from 'store/auth';
import ReactGA from 'react-ga';

ReactGA.initialize(globalOptions.tracking_id);
ReactGA.pageview(window.location.pathname + window.location.search);


const App = () => {

  const global = useContext(GlobalOptionsContext)
  const store = getStore(global)

  httpClient.interceptors.response.use((response) => response, (error) => {
    if (error.response && error.response.status === 403 && error.config && !error.config.__isRetryRequest) {
      error.config.__isRetryRequest = true;
      return new Promise((resolve, reject) => {
        localStorage.removeItem("@token")
        const config = error.config;
        config.headers.Authorization = '';
        resolve(httpClient(error.config));
      })
        .catch((err) => {
          Promise.reject(err);
        });
    }
    return Promise.reject(error);
  })


  return (
    <Suspense fallback={Loader}>
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <MuiThemeProvider theme={theme}>
            <StyledEngineProvider injectFirst>
              <ToastContainer />
              <Routes />
            </StyledEngineProvider>
          </MuiThemeProvider>
        </ThemeProvider>
      </Provider>
    </Suspense>
  );
}

export default App;
