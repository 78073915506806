import React from 'react'
import { CircularProgress } from '@mui/material';
import styled from 'styled-components';

const CircularProgressContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 300px;
`;

export const Loader = () => {
  return (
    <CircularProgressContainer>
      <CircularProgress />
    </CircularProgressContainer>
  );
}
