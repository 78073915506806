import * as React from 'react';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import styled, { css } from 'styled-components';
import { breakpoints, containerWidth, primary, secondary } from 'theme';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Typography, Button } from 'components';

const AppBar = styled.div`
  top: 0;
  left: 0;
  right: 0;
  min-height: 55px;
  box-sizing: border-box;
  z-index: 1201;
  background-color: ${secondary};
`;

const FlexToolbar = styled(Toolbar)`
  display: flex;
  align-items: center;
  box-sizing: border-box;
  margin: auto;
  max-width: ${containerWidth}px;
  min-height: 55px;
  justify-content: space-between;
  padding: 0;

  @media ${breakpoints.viewport_l_and_down} {
    max-width: 100%;
    padding: 0 40px;
  }

  @media ${breakpoints.viewport_s_and_down} {
    flex-direction: column;
    align-items: flex-start;
    padding-bottom: 20px;
  }
`;

const Menu = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: -30px;

  @media ${breakpoints.viewport_s_and_down} {
    flex-direction: column;
  }
`;

const MenuItem = styled(Typography)`
  min-height: 40px;
  padding-bottom: 10px;
  display: flex;
  align-items: flex-end;
  border-bottom: 3px solid ${secondary};

  ${({ active }) =>
    active &&
    css`
      color: ${primary};
      font-weight: 600;
      border-bottom: 3px solid ${primary};
    `}
`;

const MenuItemContent = styled.div`
  cursor: pointer;
  padding: 0 30px;
  display: flex;
  align-items: center;
`;

const EditButton = styled(Button)`
  text-transform: capitalize;
  background-color: transparent;

  :hover,
  :focus,
  :active {
    background-color: transparent;
  }
`;

const links = {
  vendor: [
    {
      label: 'Dashboard',
      key: 'dashboard',
      link: '/vendor/dashboard',
      visible: true,
    },
    { label: 'Leads', key: 'leads', link: '/vendor/leads', visible: true },
    { label: 'Deals', key: 'deals', link: '/vendor/deals', visible: true },
    {
      label: 'Create Deal',
      key: 'deals',
      link: '/vendor/create-deal',
      visible: false,
    },
  ],
  couple: [
    {
      label: 'Dashboard',
      key: 'dashboard',
      link: '/couple/dashboard',
      visible: true,
    },
    {
      label: 'Build Your Wedding',
      key: 'wedding',
      link: '/couple/build-wedding',
      visible: true,
    },
    { label: 'Deals', key: 'deals', link: '/couple/deals', visible: true },
    {
      label: 'Packages',
      key: 'packages',
      link: '/couple/packages',
      visible: true,
    },
  ],
};

export const SecondaryHeader = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const { user } = useSelector((state) => state.auth);

  const onNavigationLink = (nav, link) => {
    if (user) {
      const linkObj = links[user.role]?.find((item) => item.link === link);
      return nav.includes(linkObj?.key);
    }
  };

  return (
    <Box>
      {user.role === 'vendor' && !user.category ? (
        <></>
      ) : (
        <AppBar>
          <FlexToolbar>
            <Menu>
              {links[user.role].map(({ label, key, link, visible }) => {
                if (visible) {
                  return (
                    <MenuItemContent key={label} onClick={() => navigate(link)}>
                      <MenuItem
                        active={onNavigationLink(key, location.pathname)}>
                        {label}
                      </MenuItem>
                    </MenuItemContent>
                  );
                }

                return null;
              })}
            </Menu>

            {user.role === 'vendor' && !location.pathname.includes("/preferences") && (
              <EditButton onClick={() => navigate(`/vendor/preferences/${user.category}`)} variant="secondary">
                Edit Preferences
              </EditButton>
            )}
          </FlexToolbar>
        </AppBar>
      )}
    </Box>
  );
};
