import { Typography } from '../typography'
import React from 'react'
import styled from 'styled-components'
import { Button } from '../button'
import { primary, textDisabled } from 'theme'

const Container = styled.div`
  width: 230px;
  background-color: #ffff;
  box-shadow: 0 1px 16px 0 rgba(0, 0, 0, 0.15);
`
const Image = styled.img`
  height: 170px;
  width: 230px;
  object-fit: cover;
`
const Content = styled.div`
  padding: 16px;
  gap: 8px;
  display: flex;
  flex-direction: column;
`
const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 12px;
`
const NoteText = styled(Typography)`
  font-size: 9px;
  color: ${textDisabled};
  line-height: 10px;
`
const PriceContent = styled.div`
  margin-top: 6px;
`

const ClickableText = styled(Typography)`
  cursor:pointer;
  text-decoration: underline;
`

export const Deal = ({ deal, addDeal, onClick }) => {
  return (
    <Container key={`deal_${deal.id}`}>
      <Image src={deal.image} />
      <Content>
        <ClickableText onClick={() => onClick(deal)} variant='strong' color={primary}>{deal.vendor_name}</ClickableText>
        <Typography variant='caption'>{deal.title?.slice(0, 60)}</Typography>
        <PriceContent>
          <Typography variant='h5' color={primary}>${deal.budget}*</Typography>
          <NoteText>*Prices may vary based on time, date, and circumstances.</NoteText>
        </PriceContent>
        <ButtonWrapper>
          <Button variant='secondary' onClick={() => addDeal(deal.id)}>
            add to dashboard
          </Button>
        </ButtonWrapper>
      </Content>
    </Container>
  )
}
