export const jsBreakpoints = {
  XXLAndUp: {
    minWidth: 1441,
  },
  XLAndUp: {
    minWidth: 1200,
  },
  LAndUp: {
    minWidth: 992,
  },
  MAndUp: {
    minWidth: 768,
  },
  SAndUp: {
    minWidth: 576,
  },
  XSAndUp: {
    minWidth: 360,
  },
  XLAndDown: {
    maxWidth: 1599.98,
  },
  LAndDown: {
    maxWidth: 1199.98,
  },
  MAndDown: {
    maxWidth: 991.98,
  },
  SAndDown: {
    maxWidth: 767.98,
  },
  XSAndDown: {
    maxWidth: 575.98,
  },
  LOnly: {
    minWidth: 992,
    maxWidth: 1199.98,
  },
  MOnly: {
    minWidth: 768,
    maxWidth: 991.98,
  },
  SOnly: {
    minWidth: 576,
    maxWidth: 767.98,
  },
};