import { Button, Typography, VendorType } from 'components'
import React, { useEffect, useState } from 'react'
import { primary, containerWidth, secondary, breakpoints } from 'theme'
import styled from 'styled-components'
import { VENDORS_CATEGORIES } from 'utils'
import { useDispatch, useSelector } from 'react-redux'
import { getVendorTypes } from 'store/couple'
import { useNavigate } from 'react-router'

const Content = styled.div`
  display:flex;
  flex-direction: row;
  gap:30px;
  flex-wrap: wrap;
  margin-top: 120px;

  @media ${breakpoints.viewport_xs_and_down} {
    margin-top: 140px;
  }
`

const Banner = styled.div`
  position: absolute;
  left: 0;
  width: 100%;
  background-color: ${secondary};
  padding: 20px 0;
`

const BannerContent = styled.div`
  max-width: ${containerWidth}px;
  margin: auto;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  display: flex;

  @media ${breakpoints.viewport_l_and_down} {
    max-width: 100%;
    padding:0 40px
  }
`

export const VendorTypes = () => {
  const { vendorTypes } = useSelector(state => state.couple)
  const [selected, setSelected] = useState(-1)
  const dispatch = useDispatch();
  const navigation = useNavigate()

  useEffect(() => {
    dispatch(getVendorTypes())
  }, [])

  return (
    <div>
      <Banner>
        <BannerContent>
          <div>
            <Typography variant='h3' color={primary}>What type of vendor are you?</Typography>
            <Typography>Select area of expertise</Typography>
          </div>
          <Button
            disabled={selected === -1}
            onClick={() => navigation(`/vendor/payment/${selected}`)}
          >Continue</Button>
        </BannerContent>
      </Banner>
      <Content>
        <div>
          <Typography variant='h4' style={{ marginBottom: 10 }}>Our Service</Typography>
          <Typography>
            Wedstimate connects your wedding business with the couples that are
            the best match for you and your service. We curate leads for you by
            sending newly engaged couples that are looking for your type of
            service, in the price range you list, with your style and sensibilities, to
            your profile. We make it easy for them to find you and then we connect
            you.
          </Typography>
        </div>
        {
          VENDORS_CATEGORIES.map(vendor => <VendorType
            key={vendor.enum}
            vendor={{ ...vendor, count: vendorTypes[vendor.enum] }}
            onSelected={() => setSelected(vendor.enum)}
            showButton={false}
            selected={selected === vendor.enum}
          />)
        }
      </Content>
    </div>
  )
}
