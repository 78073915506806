import { Button, Typography } from 'components';
import React, { useEffect, useState } from 'react';
import {
  primary,
  containerWidth,
  secondary,
  breakpoints,
  textDisabled,
} from 'theme';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import { getPlans } from 'store/vendor';
import { Radio, RadioGroup } from '@mui/material';
import * as dropin from 'braintree-web-drop-in';

const Content = styled.div`
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  gap: 30px;
  flex-wrap: wrap;
  margin-top: 140px;
  min-width: ${containerWidth / 2}px;

  @media ${breakpoints.viewport_xs_and_down} {
    margin-top: 160px;
  }
`;

const Banner = styled.div`
  position: absolute;
  left: 0;
  width: 100%;
  background-color: ${secondary};
  padding: 20px 0;
`;

const BannerContent = styled.div`
  max-width: ${containerWidth}px;
  margin: auto;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  display: flex;

  @media ${breakpoints.viewport_l_and_down} {
    max-width: 100%;
    padding: 0 40px;
  }
`;

const Option = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  margin-bottom: 30px;
  align-items: flex-start;
`;

export const Payment = () => {
  const [selected, setSelected] = useState('');
  const [showCheckout, setShowCheckout] = useState(true);
  const { plans, token } = useSelector(state => state.vendor);
  const { user } = useSelector(state => state.auth);
  const dispatch = useDispatch();
  const navigation = useNavigate();
  const params = useParams();
  const [braintreeInstance, setBraintreeInstance] = useState(undefined);

  useEffect(() => {
    dispatch(getPlans());
  }, []);

  useEffect(() => {
    if (selected && showCheckout) {
      const initializeBraintree = () =>
        dropin.create(
          {
            authorization: token,
            container: '#braintree-drop-in-div',
            paypal: {
              flow: 'vault',
            },
            card: {
              cardholderName: {
                required: true,
              },
            },
          },
          function(error, instance) {
            if (error) console.error(error);
            else setBraintreeInstance(instance);
          },
        );

      if (braintreeInstance) {
        braintreeInstance.teardown().then(() => {
          initializeBraintree();
        });
      } else {
        initializeBraintree();
      }
    }
  }, [showCheckout, selected, token]);

  const buy = async () => {
    if (braintreeInstance) {
      braintreeInstance.requestPaymentMethod((error, payload) => {
        if (error) {
          console.error(error);
        } else {
          const paymentMethodNonce = payload.nonce;
          navigation(`/vendor/preferences/${params.id}`, {
            state: {
              payment: {
                nonce: paymentMethodNonce,
                plan_id: selected,
                user_id: user.id,
                upgradation: false,
              },
            },
          });
        }
      });
    }
  };

  return (
    <div>
      <Banner>
        <BannerContent>
          <div>
            <Typography variant="h3" color={primary}>
              Choose your plan
            </Typography>
            <Typography />
          </div>
          {/* <Button disabled={selected === -1} onClick={buy}>
            Continue
          </Button> */}
        </BannerContent>
      </Banner>

      <Content>
        <RadioGroup defaultValue="" name="radio-buttons-group" value={selected}>
          {plans.map(plan => (
            <Option>
              <Radio
                onChange={() => {
                  setSelected(plan.id);
                  localStorage.setItem('ccr-plan', JSON.stringify(plan.id));
                }}
                value={plan.id}
              />
              <div>
                <Typography variant="h4" color={primary}>
                  {plan.name} ${plan.price}
                </Typography>
                <Typography variant="strong" color={textDisabled}>
                  {plan.description}
                </Typography>
              </div>
            </Option>
          ))}
        </RadioGroup>

        {/* {selected && (
          <Typography
            style={{
              alignSelf: 'center',
              color: `${primary}`,
              cursor: 'pointer',
            }}
            variant="h4"
            onClick={() => setShowCheckout(true)}>
            Join Wedstimate. Start getting leads today!
          </Typography>
        )} */}

        {selected && showCheckout && (
          <>
            <div id="braintree-drop-in-div" />
          </>
        )}
      </Content>

      <Banner>
        <BannerContent>
          <div>
            <Typography variant="h3" color={primary}>
              {/* Choose your plan */}
            </Typography>
            <Typography />
          </div>
          <Button disabled={selected === -1} onClick={buy}>
            Continue
          </Button>
        </BannerContent>
      </Banner>
    </div>
  );
};
