import React from 'react';
import styled from 'styled-components';
import FormItemWrapper from '../form-item-wrapper';
import { TextField } from '@mui/material';
import { critical, textDefault } from 'theme';
import { Typography } from '../typography';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';


const StyledTextField = styled(TextField)`
  width: 100%;

  > div.MuiOutlinedInput-multiline {
    padding: ${({ theme }) => `${theme.spacing(0.5)} 0`};
  }
  > div.MuiInputBase-root {
    border-radius:0;
  }

  .MuiInputBase-input {
    padding: 12px 14px;
    font-size: 14px;
  }
`;

export const FormDatePicker = ({ label, type = 'text', error, required, disabled, value, onChange, ...restProps }) => (
  <LocalizationProvider dateAdapter={AdapterDayjs}>
    <FormItemWrapper color={textDefault} required={required} disabled={disabled} label={label}>
      <DatePicker
        label=""
        value={(value && new Date(value)) || null}
        inputFormat="YYYY-MM-DD"
        TextFieldProps={{ variant: 'outlined' }}
        onChange={onChange}
        renderInput={(params) => <StyledTextField {...params} />}
      />
      {!!error && <Typography color={critical}>{error}</Typography>}
    </FormItemWrapper>
  </LocalizationProvider>
);